import {api} from '../../helpers';

export function fetchTasks(flowId) {
  return api
    .get(`/users/fraud-detection/${flowId}/tasks`)
    .then(res => res.data);
}

export function addTask(flowId, newTask) {
  return api
    .post(`/users/fraud-detection/${flowId}/tasks`, {...newTask})
    .then(res => res.data);
}
export function updateTask(flowId, {id, ...task}) {
  return api
    .put(`/users/fraud-detection/${flowId}/tasks/${id}`, {...task})
    .then(res => res.data);
}

export function saveProcess(flowId, flowProcess) {
  const start = flowProcess[0];
  const end = flowProcess[2];
  const FLOW = [start, ...flowProcess[1], end];
  const threshold = flowProcess[2]?.threshold;

  const finalProcess = {
    process: FLOW.map(process => process?._id).filter(process => !!process),
    ...(threshold && {threshold}),
  };

  return api
    .put(`/users/fraud-detection/${flowId}/process`, {...finalProcess})
    .then(res => res.data);
}
export function deleteTask(flowId, id) {
  return api
    .delete(`/users/fraud-detection/${flowId}/tasks/${id}`)
    .then(res => res.data);
}
