import React from 'react';
import DatePicker from 'react-datepicker';

function CustomDateRadio({
  selectedStart,
  selectedEnd,
  onChangeStart,
  onChangeEnd,
  startDate,
  endDate,
  maxDate,
  minDate,
}) {
  return (
    <div className="flex items-center gap-4 px-11 -mt-1">
      <div className="flex flex-col items-start lookup-filter">
        <span className="text-xs text-body font-medium mb-[5px]">FROM</span>
        <DatePicker
          selected={selectedStart}
          onChange={onChangeStart}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          showYearDropdown
          maxDate={maxDate}
        />
      </div>
      <div className="flex flex-col items-start">
        <span className="text-xs text-body font-medium mb-[5px]">TO</span>
        <DatePicker
          selected={selectedEnd}
          onChange={onChangeEnd}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          showYearDropdown
          maxDate={maxDate}
        />
      </div>
    </div>
  );
}

export default CustomDateRadio;
