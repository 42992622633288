import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {
  DashboardLayout,
  // PrimaryButton,
  ReusableLink,
  // SecondaryButton,
} from '../../components';
import {
  addressIc,
  // affordabilityIc,
  easyCreditCheckIc,
  failed_t,
  ipDeviceCheckIc,
  kycInfoIc,
} from '../../assets/images/images';
import {Constants} from '../../helpers';
import AssessmentGaugeChart from './AssessmentGaugeChart';
import {useQuery} from 'react-query';
import {
  fetchAccountAnalysis,
  fetchChecksByEntity,
} from '../../requests/queries/creditChecks';
import moment from 'moment';
import {formatNumberToCurrency} from '../../helpers/formatNumberToCurrency';

export default function Layout({
  children,
  behavioralAnalysisSideContent = false,
}) {
  const {slug} = useParams();
  const {state} = useLocation();

  ////////////////////////////////  FICO CHECK  /////////////////////////////////
  const {data, isLoading} = useQuery(
    ['fico_check-info', {customer_id: slug, entity_type: 'fico_check'}],
    () =>
      fetchChecksByEntity({
        customer_id: slug,
        entity_type: 'fico_check',
      }),
  );
  const FICO_DATA = !isLoading && data?.entity.score?.ficoScore;

  ///////////////////////// ACCOUNT ANALYSIS  /////////////////////////////////
  const {data: acct_analysis, isLoading: acctLoading} = useQuery(
    ['kyc-info', {account_id: slug, app_id: state?.app_id}],
    () => fetchAccountAnalysis({account_id: slug, app_id: state?.app_id}),
  );

  const ANALYSIS = (!acctLoading && data && acct_analysis?.entity) || {};
  const accountBreakdown = ANALYSIS?.accountBreakdown || {};

  const LINKS = [
    {
      path: Constants.PAGES_URL.EASY_CREDIT_CREDIT_SUMMARY.replace(
        ':slug',
        slug,
      ),
      src: ipDeviceCheckIc,
      text: 'Credit Summary',
    },
    {
      path: Constants.PAGES_URL.EASY_CREDIT_BEHAVIOURAL_ANALYSIS.replace(
        ':slug',
        slug,
      ),
      src: addressIc,
      text: 'Behavioral Analysis',
    },
    // {
    //   path: Constants.PAGES_URL.EASY_CREDIT_AFFORDABILITY.replace(
    //     ':slug',
    //     slug,
    //   ),
    //   src: affordabilityIc,
    //   text: 'Affordability',
    // },
    {
      path: Constants.PAGES_URL.EASY_CREDIT_KYC_INFORMATION.replace(
        ':slug',

        slug,
      ),
      src: kycInfoIc,
      text: 'KYC Information',
    },
    // {
    //   path: Constants.PAGES_URL.EASY_CREDIT_AML_SCREENING.replace(
    //     ':slug',
    //     slug,
    //   ),
    //   src: amlCheckIc,
    //   text: 'AML/PEP Screening',
    // },
  ];
  return (
    <DashboardLayout
      bg="bg-white xl:bg-white80"
      topPadding="pt-0"
      xlLeftMargin="xl:ml-12"
      fullScreen
      overFlow=""
      breadCrumbs={
        <div className="flex items-center">
          <img src={easyCreditCheckIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">EasyCreditCheck</p>
        </div>
      }
    >
      <div className="flex-wrap items-start min-h-full xl:flex xl:flex-nowrap basis-full">
        <div className="basis-[17.14%] flex flex-col gap-6 pt-16 sm:pt-6 xl:pt-[35px] xl:sticky xl:top-[35px]">
          <h2 className="hidden text-base font-medium sm:block text-grey sm:text-lg whitespace-nowrap">
            Credit Assessment
          </h2>
          <div className="flex items-center justify-between sm:hidden">
            <h2 className="text-base font-medium sm:hidden text-grey sm:text-xl whitespace-nowrap">
              Credit Assessment
            </h2>
          </div>
          <div className="border-b border-grey60 xl:border-none">
            <div className="flex gap-2 xl:p-2 xl:flex-col">
              {LINKS.map(({path, src, text}) => (
                <ReusableLink
                  key={path}
                  path={path}
                  src={src}
                  text={text}
                  state={{
                    ...state,
                    ...(text === 'Behavioral Analysis' && {
                      analysisData: ANALYSIS,
                    }),
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="xl:ml-[37px] xl:basis-[45.67%] mt-6 xl:mt-0 px-4 grow xl:grow-0 xl:p-8 pt-4 xl:pt-[36px] bg-white80 xl:bg-white">
          {children}
        </div>
        <div className="xl:basis-[36.04%] xl:px-6 py-10 xl:sticky xl:top-[35px] bg-white xl:bg-white80">
          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between">
              <div className="px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit text-success20 bg-[#E77E03]">
                {FICO_DATA?.rating || '-'}
              </div>
              {/*<PrimaryButton*/}
              {/*  buttonText="Download Report"*/}
              {/*  fontSize="text-xs"*/}
              {/*  xPadding="px-2"*/}
              {/*  yPadding="pt-[9px] pb-[7px]"*/}
              {/*  className="hidden sm:flex ml-auto"*/}
              {/*/>*/}
            </div>

            {behavioralAnalysisSideContent ? (
              <>
                <p className="text-xs text-grey font-medium uppercase">
                  cashflow analysis
                </p>

                <div className="flex items-center">
                  <p className="font-bold text-grey80 text-sm mr-3 ">
                    Statement Period:
                  </p>
                  <p className="text-sm text-grey40">{`${
                    accountBreakdown?.periodInStatement || '-'
                  }, ${accountBreakdown?.yearInStatement || ''}`}</p>
                </div>

                <div className="w-full p-4 rounded bg-white80 xl:bg-white flex flex-col gap-2">
                  <div className="px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit text-[#BB371A] bg-[#BB371A12]">
                    total turnover debit
                  </div>
                  <p className="text-xs text-body">
                    The total amount processed out of the account
                  </p>
                  <p className="text-grey text-xl font-bold">
                    {formatNumberToCurrency(
                      accountBreakdown?.TotalDebitMade || 0,
                    )}
                  </p>
                  <div className="flex items-center justify-between">
                    <span className="text-body text-small">
                      Average Weekly Debits:
                    </span>
                    <span className="text-body text-small font-bold">
                      {formatNumberToCurrency(
                        accountBreakdown?.averageWeeklyDebits || 0,
                      )}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-body text-small">
                      Average Monthly Debits:
                    </span>
                    <span className="text-body text-small font-bold">
                      {formatNumberToCurrency(
                        accountBreakdown?.averageMonthlyDebits || 0,
                      )}
                    </span>
                  </div>
                </div>

                <div className="w-full p-4 rounded bg-white80 xl:bg-white flex flex-col gap-2">
                  <div className="px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit text-success bg-success20">
                    total turnover credit
                  </div>
                  <p className="text-xs text-body">
                    The total amount processed into the account
                  </p>
                  <p className="text-grey text-xl font-bold">
                    {formatNumberToCurrency(
                      accountBreakdown?.TotalCreditEntry || 0,
                    )}
                  </p>
                  <div className="flex items-center justify-between">
                    <span className="text-body text-small">
                      Average Weekly Credits:
                    </span>
                    <span className="text-body text-small font-bold">
                      {formatNumberToCurrency(
                        accountBreakdown?.averageWeeklyCredits || 0,
                      )}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-body text-small">
                      Average Monthly Credits:
                    </span>
                    <span className="text-body text-small font-bold">
                      {formatNumberToCurrency(
                        accountBreakdown?.averageMonthlyCredits || 0,
                      )}
                    </span>
                  </div>
                </div>
                <div className="w-full p-4 rounded bg-white80 xl:bg-white flex flex-col gap-2">
                  <div className="px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit text-[#5E3EEB] bg-[#7459D91A]">
                    closing balance
                  </div>

                  <p className="text-grey text-xl font-bold">
                    {formatNumberToCurrency(
                      accountBreakdown?.closingBalance || 0,
                    )}
                  </p>
                  <div className="flex items-center justify-between">
                    <span className="text-body text-small">
                      Average Weekly Balance:
                    </span>
                    <span className="text-body text-small font-bold">
                      {formatNumberToCurrency(
                        accountBreakdown?.averageWeeklyBalance || 0,
                      )}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-body text-small">
                      Average Monthly Balance:
                    </span>
                    <span className="text-body text-small font-bold">
                      {formatNumberToCurrency(
                        accountBreakdown?.averageMonthlyBalance || 0,
                      )}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mt-4">
                  <p className="mb-3 text-xs font-medium uppercase text-grey">
                    credit assessment score
                  </p>
                  <AssessmentGaugeChart score={FICO_DATA?.score || 100} />
                </div>
                <div className="w-full p-4 rounded bg-white80 xl:bg-white mt-4">
                  <div className="flex items-center mb-2">
                    <img src={failed_t} alt="" />
                    <p className="ml-2 text-xs font-medium uppercase text-grey">
                      reason
                    </p>
                  </div>
                  <p className="mt-2 text-sm text-body">
                    {FICO_DATA?.reasons || '-'}
                  </p>
                </div>

                <div className="w-full p-4 rounded bg-white80 xl:bg-white">
                  <p className="mt-2 text-xs font-medium uppercase text-grey">
                    DATE checked
                  </p>
                  <p className="mt-2 font-medium text-grey">
                    {moment(
                      (!isLoading && data?.entity?.searchedDate) || new Date(),
                    ).format('Do MMM YYYY hh:mm')}
                  </p>
                </div>
                {/*<div className="w-full p-4 rounded bg-white80 xl:bg-white">*/}
                {/*  <p className="mt-2 font-medium text-grey">Credit History</p>*/}
                {/*  <p className="mt-2 text-xs text-body">*/}
                {/*    Click here to see information on the credit breakdown*/}
                {/*  </p>*/}
                {/*  <SecondaryButton*/}
                {/*    className="w-full flex items-center justify-center gap-2 mt-2 h-12"*/}
                {/*    fontSize="text-sm"*/}
                {/*    xPadding="px-2"*/}
                {/*    yPadding="pt-[9px] pb-[7px]"*/}
                {/*    buttonText="View more"*/}
                {/*  />*/}
                {/*</div>*/}
              </>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
