import React, {useContext, useEffect, useMemo, useState} from 'react';
import * as Actions from '../../../../store/actions';
import {
  EmptyState,
  Loader,
  PageLimit,
  Pagination,
  TableLayout,
} from '../../../../components';
import {
  dropdownBlue,
  dropdownGrey,
  profileIc,
} from '../../../../assets/images/images';
import Layout from '../Layout';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
  formatNumberToCurrency,
  formatUsd,
} from '../../../../helpers/formatNumberToCurrency';
import moment from 'moment';
import {PageContext} from '../../../../helpers/context';

function FlowsEvents({
  getEvents,
  flows: {events, loading},
  auth: {userDetails},
}) {
  const [currentPage, setCurrentPage] = useState('1');
  const [limit, setLimit] = useState(10);

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  const {slug} = useParams();

  useEffect(() => {
    getEvents({id: slug, page: currentPage, limit});
  }, [getEvents, slug, currentPage, limit]);

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const EVENTS = useMemo(() => events?.data?.data, [events]) || [];

  return (
    <Layout pageTitle="Events" pageIcon={profileIc}>
      {loading ? (
        <Loader height={65} />
      ) : !loading && EVENTS?.length === 0 ? (
        <EmptyState body="No event(s) found" noBtn height={65} />
      ) : (
        <section>
          <div className="flex items-center justify-between py-7 bg-white -ml-12 pl-12 -mr-4 xl:mr-[-76px] pr-7">
            <h2 className="hidden text-lg font-medium text-grey sm:block">
              Events
            </h2>
          </div>
          <TableLayout
            negativeMargins
            negativeRightMargin="-mr-4 xl:-mr-[76px]"
          >
            <thead className="text-xs font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>Transaction id</span>
                    <img src={dropdownBlue} alt="" width={16} height={16} />
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>AMOUNT</span>
                    <img src={dropdownGrey} alt="" width={16} height={16} />
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>GATEWAY</span>
                    <img src={dropdownGrey} alt="" width={16} height={16} />
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>USER</span>
                    <img src={dropdownGrey} alt="" width={16} height={16} />
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>date created</span>
                    <img src={dropdownGrey} alt="" width={16} height={16} />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm bg-white">
              {EVENTS &&
                EVENTS.map((event, i) => (
                  <tr className="border-b border-grey60" key={i}>
                    <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                      {event?.transaction_id || '-'}
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {userDetails &&
                      userDetails.company &&
                      userDetails.company.currency === 'USD'
                        ? formatUsd(event?.amount || 0)
                        : formatNumberToCurrency(event?.amount || 0)}
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {event?.gateway || '-'}
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {event?.names || '-'}
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {moment(event?.created_at).format('Do MMM YYYY hh:mm') ||
                        '-'}
                    </td>
                  </tr>
                ))}
            </tbody>
          </TableLayout>

          <div className="flex items-center justify-between flex-wrap gap-4 sm:gap-0 mt-8">
            <div className="flex items-center text-body text-tiny">
              <PageLimit
                onLimitChange={setLimit}
                total={events?.data && events?.data?.total_of_items}
                length={EVENTS.length}
                limit={limit}
              />
            </div>

            <div className="mb-8 sm:mb-0">
              <Pagination
                total={
                  events?.data &&
                  Math.ceil(events?.data?.total_of_items / limit)
                }
                current={+currentPage}
                onPageChange={activePage => {
                  localStorage.setItem('page', String(activePage));
                  pageClick(activePage);
                }}
              />
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
}

export default connect(state => state, Actions)(FlowsEvents);
