import React, {useCallback, useMemo, useState} from 'react';
import Select from 'react-select';
import {
  customStyles,
  SearchIndicator,
  PrimaryButton,
} from '../../../../../components';
import {useBankCodes} from '../../../../../hooks/useBankCodes';
import {useSelector} from 'react-redux';
import {useMutation} from 'react-query';
import {initiateBAASTransfer} from '../../../../../requests/queries/baas';
import {toast} from 'react-toastify';
import {useRetrieveUserBankName} from '../../hooks/useRetrieveUserBankName';

const ACCOUNT_NUMBER_MAX = 10;

export const BankDetailsForm = ({userBalance = 0, setWithdrawalSteps}) => {
  const {banks} = useBankCodes();
  const [userAccount, setUserAccount] = useState({});
  const [selectedBank, setSelectedBank] = useState({});
  const {userDetails, user} = useSelector(state => state?.auth);

  const initiateWithdrawalMutation = useMutation(initiateBAASTransfer, {
    onSuccess: data => {
      sessionStorage.setItem(
        'withdrawal_id',
        String(data?.entity?.withrawal_id),
      );
      setSelectedBank({});
      setUserAccount({});
      setWithdrawalSteps('pin');
    },
  });

  const {userBankDetails, isBankDetailsLoading} = useRetrieveUserBankName({
    accountNumber: userAccount?.account_number,
    bankCode: selectedBank?.value,
  });

  const handleSubmit = useCallback(async () => {
    try {
      if (userAccount?.account_number?.length < ACCOUNT_NUMBER_MAX) {
        return toast.error('Account number MUST be 10 digits.');
      }

      const data = {
        company_id: userDetails?.company?.id,
        bank_code: selectedBank?.value,
        email: user?.email,
        ...userAccount,
      };

      if (Object.values(data || {}).every(e => e)) {
        sessionStorage.setItem('w_amount', String(userAccount?.amount));

        await initiateWithdrawalMutation.mutateAsync(data);
      }
    } catch (error) {
      setSelectedBank({});
      setUserAccount({});
      toast.error(error.response?.data?.error || 'Failed to update PIN');
    }
  }, [
    initiateWithdrawalMutation,
    selectedBank?.value,
    user?.email,
    userAccount,
    userDetails?.company?.id,
  ]);

  const handleInput = useCallback(e => {
    const {name, value} = e.target;
    setUserAccount(prev => ({...prev, [name]: value}));
  }, []);

  const isBalanceError = useMemo(
    () => +userBalance < +userAccount?.amount,
    [userAccount?.amount, userBalance],
  );
  const isNubanError = useMemo(
    () => userAccount?.account_number?.length > ACCOUNT_NUMBER_MAX,
    [userAccount?.account_number?.length],
  );
  const isAccountDetailsValid = useMemo(
    () =>
      !isNubanError &&
      !isBalanceError &&
      userAccount?.account_number &&
      userAccount?.amount &&
      !isBankDetailsLoading &&
      userBankDetails?.entity?.account_name &&
      selectedBank?.value,
    [
      isBalanceError,
      isBankDetailsLoading,
      isNubanError,
      selectedBank?.value,
      userAccount?.account_number,
      userAccount?.amount,
      userBankDetails?.entity?.account_name,
    ],
  );

  return (
    <div className="mt-6">
      <label htmlFor="amount">
        <span className="text-sm text-body font-medium">Amount</span>
        <input
          type="number"
          inputMode="numeric"
          name="amount"
          className={`mt-2 placeholder:text-grey-200 placeholder:font-normal ${
            isBalanceError
              ? 'outline outline-1 outline-danger focus:outline-status-404'
              : ''
          }`}
          id="amount"
          placeholder="Input amount to withdraw"
          value={userAccount?.amount}
          onChange={handleInput}
        />
      </label>
      {isBalanceError && (
        <p className="text-xs text-status-404 text-left mt-1">
          Amount MUST be less than or equal to your current balance.
        </p>
      )}

      <label htmlFor="account_number">
        <span className="text-sm text-body font-medium mt-6">
          Account number
        </span>
        <input
          type="number"
          inputMode="numeric"
          name="account_number"
          className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
          id="account_number"
          placeholder="Input Account number"
          value={userAccount?.account_number}
          onChange={handleInput}
        />
      </label>
      {isNubanError && (
        <p className="text-xs text-status-404 text-left mt-1">
          Account number MUST be 10 digits.
        </p>
      )}
      <p className="font-medium text-sm text-dojahBlue mt-2 text-start">
        {isBankDetailsLoading ? (
          <div className="spinner "></div>
        ) : (
          (userBankDetails && userBankDetails?.entity?.account_name) || ''
        )}
      </p>

      <span
        htmlFor="bank_name"
        className="relative w-full inline-flex  mt-4 text-body text-sm font-medium"
      >
        Bank name{' '}
        <span className="ml-1 text-grey-200 italic">
          {' '}
          (Search with bank name)
        </span>
      </span>

      <div className="relative">
        <Select
          placeholder={'Select your bank'}
          components={{DropdownIndicator: SearchIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          options={banks}
          value={selectedBank}
          onChange={data => setSelectedBank(data)}
          menuPlacement="auto"
          className="text-left"
        />
      </div>
      <div className="flex items-center mt-6">
        <PrimaryButton
          buttonText="Submit"
          className="w-full rounded-lg"
          onClick={handleSubmit}
          loading={initiateWithdrawalMutation?.isLoading}
          disabled={!isAccountDetailsValid}
        />
      </div>
    </div>
  );
};
