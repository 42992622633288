const generateRandomDate = (start, end) =>
  new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

const generateAccountTransaction = () => ({
  transactionId: `T${Math.floor(Math.random() * 10000)}`,
  amount: Math.random() * 1000,
  gateway: Math.random() < 0.5 ? 'Paystack' : 'Flutterwave',
  dateCreated: generateRandomDate(new Date(2023, 0, 1), new Date(2023, 12, 31)),
});

const generateAccountData = numTransactions => {
  const userNames = [
    'Chukwuma Okonkwo',
    'Ngozi Eze',
    'Alice Johnson',
    'Bob Smith',
    'Charlie Davies',
    'David Adekunle',
    'Eve Peters',
    'Frank Okafor',
    'Grace Onyema',
    'Hannah Okeke',
    'Isaac Uche',
    'Jack Ekpo',
  ];

  const accountData = {
    registration_time: generateRandomDate(
      new Date(2023, 0, 1),
      new Date(2023, 12, 31),
    ),
    email: `${userNames[Math.floor(Math.random() * 6)]
      .replace(/ /g, '-')
      .toLowerCase()}@${Math.random() < 0.5 ? 'gmail' : 'yahoo'}.com`,
    name: userNames[Math.floor(Math.random() * 2)],
    mobile: `+234-${Math.floor(Math.random() * 10000000000)}`,
    transactions: [],
  };

  for (let i = 0; i < numTransactions; i++) {
    accountData.transactions.push(generateAccountTransaction());
  }

  return accountData;
};

const accountDataArray = [];

for (let i = 0; i < 10; i++) {
  const numTransactions = 10;
  accountDataArray.push(generateAccountData(numTransactions));
}

export default accountDataArray;
