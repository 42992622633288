import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const DateFilter = ({
  initialStartDate,
  initialEndDate,
  onCustomDateRangeChange,
}) => {
  const today = moment();
  const sevenDaysAgo = moment().subtract(7, 'days');

  const [startDate, setStartDate] = useState(
    initialStartDate
      ? moment(initialStartDate).toDate()
      : sevenDaysAgo.toDate(),
  );
  const [endDate, setEndDate] = useState(
    initialEndDate ? moment(initialEndDate).toDate() : today.toDate(),
  );

  const handleStartDateChange = date => {
    setStartDate(date);
    onCustomDateRangeChange(date, endDate);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
    onCustomDateRangeChange(startDate, date);
  };

  return (
    <div className="flex items-center gap-4 px-6 -mt-1">
      <div className="flex flex-col items-start lookup-filter">
        <label className="uppercase text-xs text-body font-medium mb-[5px]">
          From
        </label>
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
        />
      </div>
      <div className="flex flex-col items-start lookup-filter">
        <label className="uppercase text-xs text-body font-medium mb-[5px]">
          To
        </label>
        <DatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          maxDate={endDate}
        />
      </div>
    </div>
  );
};

export default DateFilter;
