import React, {useMemo, useState} from 'react';
import {
  EmptyState,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
} from '../../../components';
import moment from 'moment';
import {getStatusColor} from '../../../helpers';
import {useTransactions} from './hooks/useReconQueries';
import {corruptedFile} from '../../../assets/images/images';
import ManualTransactionModal from './Projects/ManualTransactionModal';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';
import {ReconFilters} from './components/ReconFilters';
import {initialFilters} from './constants';
import {useParams} from 'react-router-dom';

function ReconciliationExpectedPayments({
  selectedAccount,
  transactionIds,
  handleSelectTransactions,
  internalTransactionIds,
  accountSelectionData,
  resetExternalTransaction,
  toggleExternalSelectData,
  resetExternalSelectedData,
  statusFilters,
}) {
  const {slug} = useParams();
  const [openReconFilter, setOpenReconFilter] = useState(false);
  const [filters, setFilters] = useState(initialFilters);

  const [createManualTransactions, setCreateManualTransactions] =
    useState(false);
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });
  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };
  const {
    tableData,
    isLoading: transactionLoading,
    isFetching,
  } = useTransactions({
    entity_id: 'account_id',
    id: selectedAccount?.value || '',
    tabType: 'internal',
    transaction_recon: 'Unreconciled',
    ...query,
    date_from: filters?.start,
    date_to: filters?.end,
    transaction_type: filters?.type?.toString(),
  });

  const isEmpty = useMemo(
    () =>
      !transactionLoading &&
      (tableData?.rows?.length === 0 || accountSelectionData.length === 0),
    [accountSelectionData.length, tableData?.rows?.length, transactionLoading],
  );

  return (
    <>
      <div className="bg-white border relative border-[#ececec] rounded-xl h-[750px] flex flex-col">
        <ReconFilters
          filters={filters}
          setFilters={setFilters}
          limit={query?.limit}
          openFilter={openReconFilter}
          setOpenFilter={setOpenReconFilter}
          setQuery={setQuery}
          query={query}
          initialFilters={initialFilters}
          slug={slug}
          noAccount
          otherFilterOptions={statusFilters}
          customClasses={{
            modalPosition: 'items-center justify-end ',
            extraModalClasses:
              'overflow-y-auto h-fit max-h-[95%] sm:max-h-full max-w-full sm:max-w-[352px] rounded-b-none sm:rounded-b-lg absolute -top-[90px]  right-[165px] sm:relative lookup-modal',
          }}
        />
        <>
          <div className="flex items-center justify-between py-5 px-4">
            <div className="flex items-center gap-4">
              <p className="font-inter text-[#080917] font-medium text-tiny">
                Expected payments
              </p>
              {selectedAccount?.value && (
                <FilterButton
                  openFilter={openReconFilter}
                  setOpenFilter={setOpenReconFilter}
                  className="h-[30px]"
                />
              )}
            </div>
            <PrimaryButton
              buttonText="Create Expected Payment"
              className="rounded-lg h-[30px] flex items-center justify-center"
              onClick={() =>
                setCreateManualTransactions(!createManualTransactions)
              }
              disabled={!selectedAccount?.value}
            />
          </div>

          <ManualTransactionModal
            show={createManualTransactions}
            onClose={() => setCreateManualTransactions(false)}
            noUpload
            selectedAccount={selectedAccount}
          />

          {transactionLoading ? (
            <Loader height={35} />
          ) : isEmpty ? (
            <EmptyState
              body="Ops! sorry, No Expected Payment found"
              src={corruptedFile}
              imageHeight={48}
              imageWidth={48}
              noBtn
              height={34}
            />
          ) : (
            <div style={{opacity: isFetching ? 0.3 : 1}}>
              <TableLayout className="mt-4 mb-20 w-full font-inter h-full overflow-auto">
                <thead className="text-small font-medium uppercase">
                  <tr className="bg-white80 border border-transparent border-y-[#ececec]">
                    <th></th>
                    <th className="p-3 pl-4 font-medium text-left text-xs text-grey whitespace-nowrap w-[27%]">
                      transaction date
                    </th>
                    <th className="p-3 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap w-[19%]">
                      ref id
                    </th>
                    <th className="p-3 pl-5 font-medium text-left text-xs text-grey whitespace-nowrap w-[22%]">
                      amount
                    </th>
                    <th className="p-3 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap w-[14%]">
                      type
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm text-body bg-white">
                  {!transactionLoading &&
                    tableData &&
                    tableData?.rows?.map(transaction => (
                      <tr
                        key={transaction?.id}
                        className={`text-left border-b-[0.5px] cursor-pointer border-[#ECECEC] ${
                          transactionIds?.includes(transaction?.id)
                            ? 'bg-white80 font-bold'
                            : ''
                        }`}
                        onClick={() => {
                          if (
                            internalTransactionIds.length > 1 &&
                            transactionIds.length === 1
                          ) {
                            resetExternalTransaction([]);
                            resetExternalSelectedData([]);
                            return;
                          }
                          handleSelectTransactions(transaction?.id);
                          toggleExternalSelectData(transaction);
                        }}
                      >
                        <td className="p-4 pl-0 truncate max-w-[30px] w-[2%]">
                          <input
                            checked={transactionIds?.includes(transaction?.id)}
                            type="checkbox"
                            className="w-[15px] h-[15px] rounded-sm ml-4 focus-visible:outline-1 focus-visible:outline-brandBlue"
                          />
                        </td>
                        <td className="p-4 pl-4 w-[27%] truncate max-w-[130px]">
                          {moment(transaction?.transaction_time).format(
                            'Do MMM YYYY h:m a',
                          ) || '-'}
                        </td>
                        <td className="p-4 pl-0 truncate max-w-[90px] w-[19%]">
                          {transaction?.transaction_ref?.toUpperCase() || '-'}
                        </td>
                        <td className="p-4 pl-5 truncate max-w-[100px] w-[22%]">
                          {shortenLargeNumbers(
                            transaction?.transaction_amount,
                            transaction?.account?.currency,
                            {startAt: 'M'},
                          )}
                        </td>
                        <td className="p-4 pl-0 whitespace-nowrap w-[14%]">
                          <span
                            className={`uppercase w-fit ${getStatusColor(
                              transaction?.transaction_type,
                            )} h-5 flex items-center justify-center  font-medium text-xs rounded-full px-2 py-1`}
                          >
                            {transaction?.transaction_type || '-'}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </TableLayout>
            </div>
          )}

          {!transactionLoading && tableData?.rows?.length > 0 && (
            <div className="flex mt-auto bg-white h-[60px]  rounded-b-[10px] items-center justify-between absolute bottom-0 w-full flex-wrap gap-4 px-3 ">
              <PageLimit
                onLimitChange={limit => {
                  setQuery(prevState => ({
                    ...prevState,
                    limit,
                    page: 1,
                  }));
                }}
                limit={query?.limit}
                total={tableData?.page}
                length={tableData?.rows?.length}
              />

              <Pagination
                total={tableData?.pages}
                current={Number(tableData?.page)}
                onPageChange={activePage => {
                  pageClick(activePage);
                  const searchParams = new URLSearchParams(
                    document.location.search,
                  );
                  searchParams.set('page', activePage);
                  // const newSearchParams = searchParams.toString();
                  // navigate(`?${newSearchParams}`);
                }}
              />
            </div>
          )}
        </>
      </div>
    </>
  );
}

export default ReconciliationExpectedPayments;
