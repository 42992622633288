/* eslint-disable react/jsx-curly-spacing */
import React from 'react';
import Chart from 'react-apexcharts';

function DashboardPieChartLeft({
  total = 0,
  allowedPercentage = 0,
  blockedPercentage = 0,
  loading = true,
}) {
  const options = {
    colors:
      loading || (allowedPercentage === 0 && blockedPercentage === 0)
        ? ['#bbbbbb']
        : ['#141738', '#3F7CDB'],
    tooltip: {
      fillSeriesColor: false,
    },
    dataLabels: {
      enabled: false,
    },
    labels: ['Allowed', 'Blocked'],
    legend: false,
    stroke: {
      width: 0,
    },
  };

  const series =
    allowedPercentage === 0 && blockedPercentage === 0
      ? [1]
      : [+allowedPercentage, +blockedPercentage];
  return (
    <div
      style={{maxWidth: '100%', width: '234px'}}
      className="relative flex justify-center items-center"
    >
      <Chart
        options={options}
        series={series}
        type="donut"
        height={264}
        width="100%"
      />
      <div
        className={`absolute flex flex-col items-center w-fit`}
        style={{right: 'auto', top: 'auto'}}
      >
        <h1
          className={`font-semibold m-0 p-0 text-grey ${
            total?.toString()?.length > 5 ? 'text-[20px]' : 'text-xxl'
          }`}
        >
          {loading ? 0 : total}
        </h1>
        <span className="text-xs font-medium text-center uppercase text-grey">
          TOTAL NO. OF <br /> Resolved Cases
        </span>
      </div>
    </div>
  );
}

function DashboardPieChartRight({
  flagged = 0,
  flaggedPercentage = 0,
  nonFlaggedPercentage = 0,
  loading = true,
}) {
  const options = {
    colors:
      loading || (flaggedPercentage === 0 && nonFlaggedPercentage) === 0
        ? ['#bbbbbb']
        : ['#A13E3F', '#F3787A'],
    dataLabels: {
      enabled: false,
    },
    labels: ['System Flagged Events', 'Process Flagged Events'],
    legend: false,
    stroke: {
      width: 0,
    },
    tooltip: {
      fillSeriesColor: false,
    },
  };

  const series =
    flaggedPercentage === 0 && nonFlaggedPercentage === 0
      ? [1]
      : [+flaggedPercentage, +nonFlaggedPercentage];

  return (
    <div
      style={{maxWidth: '100%', width: '234px'}}
      className="relative flex justify-center items-center"
    >
      <Chart
        options={options}
        series={series}
        type="donut"
        height={264}
        width="100%"
      />
      <div
        className="absolute flex flex-col items-center w-fit"
        style={{right: 'auto', top: 'auto'}}
      >
        <h1
          className={`font-semibold m-0 p-0 text-grey ${
            flagged?.toString()?.length > 5 ? 'text-[20px]' : 'text-xxl'
          }`}
        >
          {loading ? 0 : flagged.toLocaleString()}
        </h1>
        <span className="text-xs text-center font-medium uppercase text-grey">
          TOTAL NO. OF <br />
          Events
        </span>
      </div>
    </div>
  );
}

export {DashboardPieChartLeft, DashboardPieChartRight};
