import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {Loader} from '../../../../components';
import * as Actions from '../../../../store/actions';
import Layout from './Layout';
import {isObjectEmpty} from '../../../../helpers/isObjectEmpty';

function capitalizeCamelCaseWithSpaces(camelCaseStr) {
  return camelCaseStr
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .replace(/^\w/, c => c.toUpperCase());
}

function Row({title, tag, message}) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <p className="uppercase text-sm font-medium">{title}</p>
        <div
          className={`flex uppercase pt-[5px] pb-[3px] px-2 text-xs font-medium rounded-full w-fit items-start mb-3 ${
            tag === 'false'
              ? 'text-success20 bg-danger'
              : 'text-success20 bg-success'
          } `}
        >
          {tag}
        </div>
      </div>
      <div className="bg-white80 px-[14px] py-2 rounded-lg">
        <p className="text-xs text-success font-medium">{message}</p>
      </div>
    </div>
  );
}

function BehaviouralAnalysis({flows: {cases_meta, loading}}) {
  const data = cases_meta?.behavioral;
  const ANALYSIS_DATA = useMemo(
    () =>
      Object.entries(data).map(([name, value]) => ({
        title: capitalizeCamelCaseWithSpaces(name),
        tag: value?.state?.toString(),
        message: value?.description,
      })),
    [data],
  );
  return (
    <Layout>
      <div className="xl:ml-4 xl:basis-[43.91%] mt-6 xl:mt-0 px-4 grow xl:grow-0 xl:p-8 pt-4 xl:pt-[36px] bg-white80 xl:bg-white">
        {loading ? (
          <Loader height={40} />
        ) : !isObjectEmpty(data) ? (
          <>
            <div className="flex justify-between">
              <h2 className="font-medium">Behavioural analysis</h2>
            </div>

            <div className="mt-8 flex flex-col gap-6">
              {ANALYSIS_DATA.map((item, i) => (
                <Row key={i} {...item} />
              ))}
            </div>
          </>
        ) : null}
      </div>
    </Layout>
  );
}

export default connect(state => state, Actions)(BehaviouralAnalysis);
