import React from 'react';
import {useQuery} from 'react-query';
import {signUpBg} from '../assets/images/images';

const fetcher = url => fetch(url).then(res => res.json());

const url = `${process.env.REACT_APP_BASE_URL}/support/information-banner`;

export default function SignupCarousel() {
  const {data: bannerData, isLoading: isLoadingBannerData} = useQuery(
    ['banner-info'],
    () => fetcher(`${url}?auth=true`),
    {
      retry: false,
    },
  );

  const latestBanner =
    (!isLoadingBannerData &&
      bannerData?.data &&
      bannerData?.data[bannerData?.data?.length - 1]) ||
    {};
  return (
    <div className="min-h-screen w-[55.6%] bg-secondaryBlue hidden md:flex flex-col items-center">
      <div className="relative mt-12 w-full mx-auto">
        {isLoadingBannerData ? (
          <div className="h-[200px] w-full max-w-[426px] animate-pulse bg-body rounded mx-auto" />
        ) : (
          <img
            src={
              latestBanner?.['Background-Image'] !== ''
                ? latestBanner?.['Background-Image']
                : signUpBg
            }
            alt=""
            className="mix-blend-multiply object-contain mx-auto max-h-[200px]"
            width={426}
            height={514}
          />
        )}
      </div>

      {isLoadingBannerData ? (
        <div className="mt-6 h-16 w-full max-w-sm animate-pulse bg-body rounded" />
      ) : (
        <h1 className="mt-6 text-xxxl text-white text-center font-bold max-w-[488px] p-2">
          {latestBanner?.Title}
        </h1>
      )}

      {isLoadingBannerData ? (
        <div className="flex flex-col items-center gap-2 mt-4 max-w-xl w-full h-12">
          <div className="mt-2 h-3 w-4/5 animate-pulse bg-body rounded" />
          <div className="mt-2 h-3 w-full animate-pulse bg-body rounded" />
        </div>
      ) : (
        <p className="px-2 text-base text-center text-white80 mt-4 max-w-[600px]">
          {latestBanner?.Text}
        </p>
      )}

      <a
        href={latestBanner?.['CTA-Link']}
        target="_blank"
        rel="noreferrer"
        className="mt-6 pt-[18px] pb-[14px] px-14 text-[#3F7CDB] text-sm font-medium bg-white rounded"
      >
        {latestBanner?.CTA}
      </a>
    </div>
  );
}
