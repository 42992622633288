import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Checkbox,
  customStyles,
  DropdownIndicator,
  Modal,
  PrimaryButton,
  // PrimaryButton,
} from '../../../../components';
import {closeModal, uploadCsv} from '../../../../assets/images/images';
import {toast} from 'react-toastify';
import useDragAndDrop from '../../../../hooks/useDragAndDrop';

import {useMutation, useQueryClient} from 'react-query';
import {uploadExternalTransaction} from '../../../../requests/queries/reconciliations';
// import {getDownloadUrl} from '../../../../helpers/batchTemplateDownloadUrl';
import Select from 'react-select';
import {useAccounts} from '../hooks/useAccounts';
import {GoBack} from '../components/GoBack';
import {useParams} from 'react-router-dom';
import DocumentMatching from './DocumentMatching';

function ExternalTransactionModal({
  show,
  onClose,
  fileType,
  goBack,
  selectedAccount,
}) {
  const {slug} = useParams();
  const {accountSelectionData} = useAccounts({slug});
  const [account, setAccount] = useState(null);
  const [file, setRawFileData] = useState(null);
  const [matchScreen, setMatchScreen] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [documentPassword, setDocumentPassword] = useState('');

  const [keys, setKeys] = useState(null);
  const queryClient = useQueryClient();
  const {dragOver, setDragOver, onDragOver, onDragLeave} = useDragAndDrop();

  const {mutate, isLoading} = useMutation(
    'add-account-transaction',
    uploadExternalTransaction,
  );

  const pdfType = useMemo(
    () => fileType?.toLowerCase()?.includes('pdf'),
    [fileType],
  );

  useEffect(() => {
    setAccount(
      accountSelectionData?.length > 0 ? accountSelectionData[0] : null,
    );
  }, [accountSelectionData]);

  const handleFile = useCallback(file => {
    if (file) {
      setRawFileData(file);
      // !pdfType && setMatchScreen(!matchScreen);
    }
  }, []);
  const onDrop = e => {
    e.preventDefault();
    setDragOver(false);
    const selectedFile = e?.dataTransfer?.files[0];
    const uploadType = pdfType ? ['pdf'] : ['xlsx', 'csv', 'xls'];
    const fileExt = selectedFile.name.split('.');
    if (!uploadType.includes(fileExt[fileExt.length - 1])) {
      return toast.error(`Please provide a valid file type - (${fileType})`);
    }
    handleFile(selectedFile);
  };

  const handleSubmit = useCallback(
    async documentKeys => {
      if (!file && !account?.value) {
        console.error('Missing file or account value');
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('account_id', account?.value || selectedAccount?.value);
      formData.append(
        'currency',
        account?.currency || selectedAccount?.currency,
      );
      documentPassword?.length > 0 &&
        formData.append('password', documentPassword);
      !pdfType && formData.append('keys', JSON.stringify(documentKeys));

      try {
        await mutate(formData, {
          onSuccess: () => {
            toast.success('File uploaded successfully');
            queryClient.invalidateQueries();
            onClose(false);
            setMatchScreen(false);
            setKeys(null);
            setRawFileData(null);
            setDocumentPassword('');
            setIsPassword(false);
          },
          onError: error => {
            toast.error(error.response.data?.error);
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [
      account?.currency,
      account?.value,
      file,
      mutate,
      onClose,
      documentPassword,
      pdfType,
      queryClient,
      selectedAccount?.currency,
      selectedAccount?.value,
    ],
  );

  const isMatchingPage = file && matchScreen;

  const handleClose = useCallback(() => {
    onClose(false);
    setMatchScreen(false);
    setKeys(null);
    setRawFileData(null);
    setDocumentPassword('');
    setIsPassword(false);
  }, [onClose]);

  return (
    <Modal
      show={show}
      onClose={handleClose}
      extraModalClasses={`${
        isMatchingPage ? 'max-w-[580px]' : 'max-w-[480px]'
      } overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal`}
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center justify-between w-full">
          <GoBack
            goBack={() => {
              if (matchScreen) {
                return setMatchScreen(!matchScreen);
              }
              goBack();
              handleClose();
            }}
          />

          <button onClick={handleClose} className="hidden sm:block">
            <img src={closeModal} alt="" />
          </button>
        </div>
        {isMatchingPage ? (
          <DocumentMatching
            file={file}
            setKeys={setKeys}
            isDisabled={!file && !keys && (!account || !selectedAccount?.value)}
            handleSubmit={handleSubmit}
            loading={isLoading}
            documentPassword={documentPassword}
          />
        ) : (
          <div className="relative">
            <h3 className="font-medium text-left mt-5 text-base text-grey">
              Upload External Transaction{' '}
              <span className="opacity-40 italic">via - </span> {fileType}
            </h3>

            <label
              htmlFor="ID_type"
              className="relative mt-6 text-body font-medium"
            >
              <span className="relative text-sm text-body  font-medium required">
                Select account
              </span>
            </label>
            <Select
              defaultValue={selectedAccount || account}
              placeholder="Select an account"
              classNamePrefix="react-select"
              components={{DropdownIndicator}}
              styles={customStyles}
              className="text-start mt-2 add_project_select"
              menuPlacement="auto"
              options={accountSelectionData}
              onChange={setAccount}
              isDisabled={!!selectedAccount}
            />
            <div
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              onDrop={onDrop}
              style={{
                border: `${dragOver ? '3px dashed #33333322' : ''}`,
              }}
              className="py-[22px] mt-10 flex flex-col items-center gap-[2px] text-center border border-brandBlue border-dashed rounded"
            >
              <div className="flex-col items-center hidden sm:flex">
                {!file && <img src={uploadCsv} alt="" width={48} height={48} />}
                <p className="text-body">
                  {dragOver ? (
                    'Please drop file here'
                  ) : (
                    <span style={{color: file ? '#00000032' : ''}}>
                      Drag and drop a{' '}
                      <span className="font-extrabold">{fileType}</span> file
                      here
                    </span>
                  )}
                </p>
              </div>
              {file ? (
                <div className="text-body text-sm flex my-2 gap-[3px] items-baseline">
                  <input
                    type="file"
                    id="file"
                    onChange={e => {
                      handleFile(e.target.files[0]);
                      handleClose();
                    }}
                    accept={fileType === 'PDF' ? '.pdf' : '.xlsx, .csv, .xls'}
                  />
                  <label htmlFor="file">Change file</label>
                </div>
              ) : (
                <div className="text-body text-sm flex gap-[3px] items-baseline">
                  <span className="hidden sm:block">or</span>
                  <input
                    type="file"
                    id="file"
                    onChange={e => {
                      handleFile(e.target.files[0]);
                    }}
                    accept={fileType === 'PDF' ? '.pdf' : '.xlsx, .csv, .xls'}
                  />
                  <label htmlFor="file">click here to select</label>
                  from your computer
                </div>
              )}
              <p className="text-body text-sm flex gap-[3px] items-baseline">
                {file ? (
                  <>
                    File:{' '}
                    <span className="font-bold text-black ">{file.name}</span>
                  </>
                ) : (
                  ''
                )}
              </p>
            </div>

            {file && (
              <div className="mt-3 flex items-center mb-3">
                <Checkbox
                  label={`Is there password for this document?`}
                  className="py-[10px]"
                  labelStyle="text-sm text-body leading-[18px]"
                  noLeftPadding
                  isChecked={isPassword}
                  onChange={e => {
                    setIsPassword(e.target.checked);
                    setDocumentPassword('');
                  }}
                />
                <span className="text-grey40 text-sm pl-1"> (Optional)</span>
              </div>
            )}
            {isPassword && (
              <>
                <label htmlFor="password " className="mb-2">
                  Enter Document Password
                </label>
                <input
                  type="password"
                  value={documentPassword}
                  onChange={e => setDocumentPassword(e.target.value)}
                />
              </>
            )}

            {!pdfType && file && (
              <div className="flex items-center mt-10">
                <PrimaryButton
                  onClick={() => setMatchScreen(!matchScreen)}
                  buttonText="Next"
                  className="w-full rounded-lg"
                  disabled={!file}
                />
              </div>
            )}
            {pdfType && (
              <div className="flex items-center mt-10">
                <PrimaryButton
                  onClick={handleSubmit}
                  buttonText="Submit"
                  className="w-full rounded-lg"
                  loading={isLoading}
                  disabled={
                    !file && !keys && (!account || !selectedAccount?.value)
                  }
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ExternalTransactionModal;
