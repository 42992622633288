import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import posthog from 'posthog-js';
import {PostHogProvider} from 'posthog-js/react';

import Main from './Main';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {QueryClientProvider} from 'react-query';
import {ToastContainer} from 'react-toastify';
import store from './store';
import {saveState} from './helpers';
import {CheckboxContextProvider} from './helpers/context/CheckboxContext';

import './tailwind.css';
import 'react-phone-input-2/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from './components/Loader';
import FilterContextProvider from './helpers/context/FilterContext';
import {TourProvider} from './helpers/context/TourContext';
import EasyOnboardContextProvider from './helpers/context/EasyOnboardContext';
import {LogoProvider} from './helpers/context/CompanyLogoContext';
import {ColorProvider} from './helpers/context/CompanyColorContext';
import {initializeServices} from './appToolsInit';
import {PageProvider} from './helpers/context/PageContext';
import CreditCheckContextProvider from './helpers/context/CreditCheckContext';

const {queryClient} = initializeServices();
store.subscribe(() => {
  saveState(store.getState());
});

const App = () => {
  // hacky way to check if the current page is an authenticated page
  // extra logic is needed to check if the current page is a dynamic page like /reset-password/:token
  const authenticatedPaths = [
    '/signin',
    '/complete/sign-up',
    '/signup/add-company',
    '/join-company',
    '/create-password',
    '/create-password/:token',
    '/forgot-password',
    '/reset-password/:token',
  ];

  const isAuthenticatedPage = authenticatedPaths.some(path => {
    if (path.includes(':')) {
      const pathTokens = path.split('/');
      const currentPathTokens = window.location.pathname.split('/');

      if (pathTokens.length !== currentPathTokens.length) {
        return false;
      }

      for (let i = 0; i < pathTokens.length; i++) {
        if (
          pathTokens[i] !== currentPathTokens[i] &&
          !pathTokens[i].startsWith(':')
        ) {
          return false;
        }
      }

      return true;
    } else {
      return path === window.location.pathname;
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <PostHogProvider client={posthog}>
        <Provider store={store}>
          <PageProvider>
            {isAuthenticatedPage ? (
              <CheckboxContextProvider>
                <FilterContextProvider>
                  <EasyOnboardContextProvider>
                    <CreditCheckContextProvider>
                      <Suspense fallback={<Loader />}>
                        <Router>
                          <Main />
                        </Router>
                      </Suspense>
                    </CreditCheckContextProvider>
                  </EasyOnboardContextProvider>
                </FilterContextProvider>
              </CheckboxContextProvider>
            ) : (
              <ColorProvider>
                <LogoProvider>
                  <TourProvider>
                    <CheckboxContextProvider>
                      <FilterContextProvider>
                        <EasyOnboardContextProvider>
                          <CreditCheckContextProvider>
                            <Suspense fallback={<Loader />}>
                              <Router>
                                <Main />
                              </Router>
                            </Suspense>
                          </CreditCheckContextProvider>
                        </EasyOnboardContextProvider>
                      </FilterContextProvider>
                    </CheckboxContextProvider>
                  </TourProvider>
                </LogoProvider>
              </ColorProvider>
            )}
            <ToastContainer autoClose={5000} />
          </PageProvider>
        </Provider>
      </PostHogProvider>
    </QueryClientProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
