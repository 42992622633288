import React, {useState} from 'react';
import Select from 'react-select';
import {
  Modal,
  PrimaryButton,
  DropdownIndicator,
  customStyles,
} from '../../../components';
import {closeModal} from '../../../assets/images/images';
import {isObjectValueEmpty} from '../../../helpers/isObjectEmpty';

export const USE_CASES = [
  {value: 'bank transaction', label: 'BANK TRANSACTION'},
  {value: 'card transaction', label: 'CARD TRANSACTION'},
];

export default function CreateFlowModal({
  apps,
  open,
  setOpen,
  setData,
  flowData,
  onCreate,
  loading,
}) {
  const [selectedApps, setSelectedApps] = useState(null);
  const [selectedUseCase, setSelectedUseCase] = useState(null);

  const checkFLow = {...flowData};
  delete checkFLow.description;
  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-base font-medium text-grey">Create a flow</h3>
          <button onClick={() => setOpen(false)}>
            <img src={closeModal} alt="" width={24} height={24} />
          </button>
        </header>
        <div className="mt-6 text-left">
          <label htmlFor="flow_name" className="mt-6">
            <span className="text-sm ">Flow name</span>
            <input
              onChange={e => setData({...flowData, name: e.target.value})}
              type="text"
              id="name"
              className="mt-2"
              required
              value={flowData?.name}
            />
          </label>

          <label htmlFor="flow_app" className="mt-6 mb-2 relative">
            Select app
          </label>
          <Select
            defaultValue={selectedApps}
            onChange={data => {
              setSelectedApps(data);
              setData({...flowData, app_id: data.value});
            }}
            options={apps}
            placeholder="Select an app"
            className="basic-multi-select"
            classNamePrefix="react-select"
            components={{DropdownIndicator}}
            styles={customStyles}
          />

          <label htmlFor="flow_use_cases" className="mt-6 mb-2 relative">
            Use case
          </label>
          <Select
            defaultValue={selectedUseCase}
            onChange={data => {
              setSelectedUseCase(data);
              setData({...flowData, use_case: data.value});
            }}
            options={USE_CASES}
            placeholder="Select a use case"
            className="basic-multi-select"
            classNamePrefix="react-select"
            components={{DropdownIndicator}}
            styles={customStyles}
          />

          <label htmlFor="flow_description" className="mt-6 relative">
            <p>
              Description
              {/*<span className="text-grey30">(optional)</span>*/}
            </p>
            <textarea
              id="description"
              className="mt-2 min-h-[100px] h-full"
              onChange={e =>
                setData({...flowData, description: e.target.value})
              }
              value={flowData?.description}
            />
          </label>

          <div className="flex items-center mt-6">
            <PrimaryButton
              onClick={() => {
                flowData.description === '' && delete flowData.description;
                onCreate(flowData);
                setSelectedUseCase({});
                setSelectedApps({});
              }}
              buttonText="Continue"
              className="w-full"
              loading={loading}
              disabled={!isObjectValueEmpty(checkFLow) || loading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
