import React, {useMemo, useState} from 'react';
import {
  checked,
  reconciliationAuthBg,
  reconciliationLogo,
  unchecked,
} from '../../../../assets/images/images';
import {PrimaryButton} from '../../../../components';
import {passwordCharacterCheck, passwordRegex} from '../../../../helpers';

function Password({
  headerText = '',
  subHeaderText = '',
  setInputs,
  inputs = {password: '', confirmPassword: ''},
  buttonText,
  handleSubmit,
  loading,
  buttonType,
}) {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const isPasswordCorrect = useMemo(
    () =>
      inputs?.password.match(passwordRegex) &&
      inputs?.password === inputs.confirmPassword,
    [inputs.confirmPassword, inputs?.password],
  );

  return (
    <div
      className="w-full h-full flex justify-center items-center bg-cover bg-center bg-no-repeat min-h-screen"
      style={{backgroundImage: `url(${reconciliationAuthBg})`}}
    >
      <div className="py-[70px] px-5 lg:px-0">
        <div className="bg-white border m-auto border-batchBlue20 py-12 px-8 rounded-lg w-full md:w-[586px]">
          <div className="flex flex-col items-center gap-2 w-full">
            <img
              src={reconciliationLogo}
              alt="Dojah logo"
              className="w-[110px] h-[21px]"
            />
            <p className="text-sm text-body">
              Gain insight into your business&apos;s financial health with
              Renconcilo
            </p>
          </div>

          <div className="mt-8">
            <h2 className="text-deepBlue font-medium text-lg mb-[6px]">
              {headerText}
            </h2>
            <p className="text-sm text-body">{subHeaderText}</p>

            <form onSubmit={handleSubmit} className="mt-6">
              <label htmlFor="password" className="relative mt-4">
                Password
                <input
                  type={passwordShown ? 'text' : 'password'}
                  name="password"
                  id="password"
                  className="mt-2"
                  value={inputs?.password}
                  onChange={e =>
                    setInputs({...inputs, password: e.target.value})
                  }
                />
                <span
                  role="button"
                  onClick={togglePassword}
                  className="absolute text-xs font-medium uppercase cursor-pointer text-grey40 right-3 bottom-4"
                >
                  {passwordShown ? 'hide password' : 'show password'}
                </span>
              </label>

              <p className="mt-4 text-black text-xs">Password requirement</p>
              <div className="mt-2 flex flex-wrap gap-2">
                {passwordCharacterCheck.map(({name, check}) => (
                  <div
                    key={name}
                    className="w-fit flex items-center gap-2 bg-white py-2 px-4 rounded-2xl"
                  >
                    <img
                      src={check(inputs?.password || '') ? checked : unchecked}
                      alt=""
                      width={16}
                      height={16}
                    />
                    <span className="uppercase text-xs text-body font-medium mt-[3px]">
                      {name}
                    </span>
                  </div>
                ))}
              </div>

              <label htmlFor="confirm-password" className="relative mt-4">
                Confirm Password
                <input
                  type={confirmPasswordShown ? 'text' : 'password'}
                  name="confirm-password"
                  id="confirm-password"
                  className="mt-2"
                  value={inputs?.confirmPassword}
                  onChange={e =>
                    setInputs({...inputs, confirmPassword: e.target.value})
                  }
                />
                <span
                  role="button"
                  onClick={toggleConfirmPassword}
                  className="absolute text-xs font-medium uppercase cursor-pointer text-grey40 right-3 bottom-4"
                >
                  {confirmPasswordShown ? 'hide password' : 'show password'}
                </span>
              </label>

              <PrimaryButton
                buttonText={loading ? 'Please wait...' : buttonText}
                className="w-full mt-6"
                disabled={!isPasswordCorrect}
                onClick={handleSubmit}
                loading={loading}
                loadColor="var(--color-primary)"
              />
            </form>

            <p className="mt-6 text-sm text-body">
              By clicking the {buttonType} button, you are creating a Dojah
              account, and you agree to Dojah’s{' '}
              <span className="text-brandBlue">
                <a
                  href="https://dojah.io/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
              </span>{' '}
              and{' '}
              <span className="text-brandBlue">
                <a
                  href="https://dojah.io/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Password;
