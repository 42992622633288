import posthog from 'posthog-js';
import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';
import {QueryCache, QueryClient} from 'react-query';
import {toast} from 'react-toastify';

export const initializeServices = () => {
  const isReconcilo = localStorage.subdomain?.includes('reconcilo');

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => {
        if (
          error.response &&
          error.response.status === 401 &&
          (error.response.data.error.name === 'JsonWebTokenError' ||
            error.response.data.error.message === 'jwt malformed')
        ) {
          localStorage.clear();
          window.location = '/signin';
          window.location.reload();
        }
        if (error.response && error.response.status === 500) {
          toast.error('Ops! Internal Server Error');
          return;
        }
        const meta = query?.meta ?? {};
        if ('preventGlobalError' in meta && meta.preventGlobalError) {
          console.log(error);
          return;
        }
        Sentry.captureException(error);
        toast.error(error.response?.data?.error);
      },
    }),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000 * 60 * 5,
      },
    },
  });

  if (typeof window !== 'undefined') {
    // Initialize the Sentry agent
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      tracesSampleRate: 1.0,
      environment: process.env.REACT_APP_APP_ENV_MODE,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        new Sentry.BrowserTracing({tracingOrigins: ['*']}),
        new Sentry.Replay(),
      ],
    });
    // Initialize the PostHog agent
    if (!isReconcilo) {
      posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        loaded: posthogInstance => {
          posthogInstance.debug();
        },
      });
    }

    // Initialize the Mixpanel
    if (isReconcilo) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
        debug: true,
        track_pageview: true,
        persistence: 'localStorage',
      });
    }
  }

  return {queryClient};
};
