import React from 'react';
import {Modal, SecondaryButton, TertiaryButton} from '../../../../components';

export default function DeleteFlowModal({open, setOpen, onClick, loading}) {
  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Are you sure?"
    >
      <div className="mt-4">
        <p className="text-start text-sm text-body font-medium">
          By deleting this Flow, you are deleting every process, settings and
          all events tied to this flow{' '}
        </p>
      </div>

      <div className="mt-4 flex items-center gap-4">
        <SecondaryButton onClick={() => setOpen(false)} buttonText="Cancel" />
        <TertiaryButton
          onClick={onClick}
          buttonText="Delete flow"
          className="grow"
          loading={loading}
        />
      </div>
    </Modal>
  );
}
