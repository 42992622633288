import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {Modal} from '../../../components';
import {closeModal} from '../../../assets/images/images';
import {BankDetailsForm} from './components/WithdrawalScreens/BankDetailsForm';
import {Pin} from './components/WithdrawalScreens/Pin';
import {Otp} from './components/WithdrawalScreens/Otp';
import {Successful} from './components/WithdrawalScreens/Successful';

const initialScreen = 'initiate';

function BaasWithdrawModal({show, onClose, userBalance}) {
  const [withdrawalSteps, setWithdrawalSteps] = useState(initialScreen);

  const handleClose = useCallback(() => {
    onClose(false);
    setWithdrawalSteps('initiate');
  }, [onClose]);

  const displayHeader = useMemo(
    () => ({
      initiate: 'Initiate Transfer',
      pin: 'Input your transfer PIN',
      otp: 'Input your OTP',
      complete: 'Successful Transfer',
    }),
    [],
  );

  useEffect(() => {
    if (withdrawalSteps === 'complete') {
      const timer = setTimeout(() => onClose(false), 5000);
      return () => {
        clearTimeout(timer);
        setWithdrawalSteps('initiate');
        sessionStorage.removeItem('w_amount');
      };
    }
  }, [withdrawalSteps, handleClose, onClose]);

  const renderStepComponent = () => {
    switch (withdrawalSteps) {
      case 'initiate':
        return (
          <BankDetailsForm
            userBalance={userBalance}
            setWithdrawalSteps={setWithdrawalSteps}
          />
        );
      case 'pin':
        return <Pin setWithdrawalSteps={setWithdrawalSteps} />;
      case 'otp':
        return <Otp setWithdrawalSteps={setWithdrawalSteps} />;
      case 'complete':
        return <Successful setWithdrawalSteps={setWithdrawalSteps} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      show={show}
      onClose={handleClose}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center justify-between w-full">
          <h3 className="font-medium text-base text-grey">
            {displayHeader[withdrawalSteps]}
          </h3>
          <button onClick={handleClose} className="hidden sm:block">
            <img src={closeModal} alt="Close" />
          </button>
        </div>
        {renderStepComponent()}
      </div>
    </Modal>
  );
}

export default BaasWithdrawModal;
