import {useEffect, useMemo} from 'react';
import {useQuery, useQueries} from 'react-query';
import {
  fetchAnalysisChart,
  fetchAnalysisOverview,
  fetchAnalysisReconciliationOverview,
  fetchExternalAnalysisOverview,
  fetchExternalTransactions,
  fetchTransactions,
} from '../../../../requests/queries/reconciliations';

const useOverview = ({entity_id, id, ...rest}) => {
  const params = {
    [entity_id]: id,
    ...rest,
  };

  const {data: overviewData, isLoading: overviewLoading} = useQuery(
    ['overview', {...params}],
    () => fetchAnalysisOverview({params}),
    {enabled: !!id},
  );

  function calculateInflow(transactions, transferTypes) {
    return transactions
      .filter(t => transferTypes.includes(t.transaction_type))
      .reduce(
        (acc, t) => {
          acc.amount += t.amount;
          acc.fee_amount += t.fee_amount || 0;
          acc.count += parseInt(t.count, 10);
          return acc;
        },
        {amount: 0, fee_amount: 0, count: 0},
      );
  }

  const overviewAnalysis = useMemo(
    () => (!overviewLoading && overviewData?.entity) || [],
    [overviewLoading, overviewData],
  );

  const inFlow = useMemo(
    () =>
      !overviewLoading &&
      calculateInflow(overviewAnalysis, ['credit', 'deposit']),
    [overviewAnalysis, overviewLoading],
  );

  const outFlow = useMemo(
    () =>
      !overviewLoading &&
      calculateInflow(overviewAnalysis, ['debit', 'withdraw', 'withdrawal']),

    [overviewAnalysis, overviewLoading],
  );
  return {overviewAnalysis, inFlow, outFlow, overviewLoading};
};

const useExternalOverview = ({entity_id, id, tabType, ...rest}) => {
  const params = {
    [entity_id]: id,
    ...rest,
  };
  const {data: externalOverviewData, isLoading: externalOverviewLoading} =
    useQuery(
      ['external-overview', {...params}],
      () => fetchExternalAnalysisOverview({params}),
      {enabled: !!id && tabType === 'external'},
    );

  function calculateInflow(transactions, transferTypes) {
    return transactions
      .filter(t => transferTypes.includes(t.transaction_type))
      .reduce(
        (acc, t) => {
          acc.amount += t.amount;
          acc.fee_amount += t.fee_amount || 0;
          acc.count += parseInt(t.count, 10);
          return acc;
        },
        {amount: 0, fee_amount: 0, count: 0},
      );
  }

  const externalOverviewAnalysis = useMemo(
    () => (!externalOverviewLoading && externalOverviewData?.entity) || [],
    [externalOverviewLoading, externalOverviewData],
  );

  const externalInFlow = useMemo(
    () =>
      !externalOverviewLoading &&
      calculateInflow(externalOverviewAnalysis, ['credit', 'deposit']),
    [externalOverviewAnalysis, externalOverviewLoading],
  );

  const externalOutFlow = useMemo(
    () =>
      !externalOverviewLoading &&
      calculateInflow(externalOverviewAnalysis, [
        'debit',
        'withdraw',
        'withdrawal',
      ]),

    [externalOverviewAnalysis, externalOverviewLoading],
  );
  return {
    externalOverviewAnalysis,
    externalInFlow,
    externalOutFlow,
    externalOverviewLoading,
  };
};
const useAnalysisChart = ({entity_id, id, ...rest}) => {
  const params = {
    [entity_id]: id,
    ...rest,
  };
  const {data: chartData, isLoading: chartLoading} = useQuery(
    ['analysis-chart', {...params}],
    () => fetchAnalysisChart({params}),
    {enabled: !!id},
  );

  const analysisChart = useMemo(
    () => (!chartLoading && chartData?.entity) || [],
    [chartData, chartLoading],
  );
  return {analysisChart, chartLoading};
};
const useOverviewRecon = ({entity_id, id}) => {
  const params = {
    [entity_id]: id,
    period: 'month',
  };
  const {data: reconData, isLoading: reconLoading} = useQuery(
    ['recon-overview', {...params}],
    () => fetchAnalysisReconciliationOverview({params}),
    {enabled: !!id},
  );

  const analysisRecon = useMemo(
    () => (!reconLoading && reconData?.entity) || [],
    [reconData, reconLoading],
  );
  return {analysisRecon, reconLoading};
};

const useTransactions = ({
  id,
  entity_id,
  tabType,
  page,
  limit,
  transaction_recon,
  setTransactionsCount,
  ...rest
}) => {
  const params = {
    [entity_id]: id,
    ...(transaction_recon ? {transaction_recon} : {}),
    page,
    limit,
    ...rest,
  };

  const queries = useQueries([
    {
      queryKey: ['company-transactions', {...params}],
      queryFn: () => fetchTransactions({params}),
      enabled: !!id,
    },
    {
      queryKey: ['external-company-transactions', {...params}],
      queryFn: () => fetchExternalTransactions({params}),
      enabled: !!id,
    },
  ]);

  const [internalQuery, externalQuery] = queries;

  const isLoading = internalQuery.isLoading || externalQuery.isLoading;
  const isFetching = internalQuery.isFetching || externalQuery.isFetching;
  const tableData =
    tabType === 'internal' ? internalQuery.data : externalQuery.data;

  useEffect(() => {
    setTransactionsCount &&
      setTransactionsCount({
        internalCount: internalQuery.data?.count || 0,
        externalCount: externalQuery.data?.count || 0,
      });
  }, [
    externalQuery.data?.count,
    internalQuery.data?.count,
    setTransactionsCount,
  ]);

  return {
    tableData,
    isLoading,
    isFetching,
    count: {
      internalCount: internalQuery.data?.count || 0,
      externalCount: externalQuery.data?.count || 0,
    },
  };
};

export {
  useOverview,
  useTransactions,
  useAnalysisChart,
  useOverviewRecon,
  useExternalOverview,
};
