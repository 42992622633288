import {formatNumberToCurrency} from './formatNumberToCurrency';

export function shortenLargeNumbers(number, currency, options = {}) {
  const {startAt = 'K', decimals = 1} = options;

  const suffixes = ['', 'K', 'M', 'B', 'T', 'Q'];
  const startIndex = Math.max(0, suffixes.indexOf(startAt.toUpperCase()));
  const threshold = 1000 ** startIndex;

  if (Math.abs(number) < threshold) {
    return formatValue(number, currency, decimals);
  }

  const exponent = Math.floor(Math.log10(Math.abs(number)) / 3);
  const value = (number / 1000 ** exponent).toFixed(decimals);
  const formattedValue = formatValue(value, currency, decimals);

  return `${formattedValue}${suffixes[exponent] || ''}`;
}

function formatValue(value, currency, decimals) {
  if (typeof currency === 'string') {
    return formatNumberToCurrency(value, currency);
  }
  if (currency === true) {
    return formatNumberToCurrency(value);
  }
  return Number(value).toFixed(decimals);
}
