export const formatNumberToCurrency = (number, currency, rate) => {
  const convertStringToNumber =
    typeof number === 'string' ? Number(number) : number;

  const getCountryLanguageByCurrencySymbol = () => {
    switch (currency) {
      case 'NGN':
      case 'USD':
      case 'NPR':
      case 'GBP':
      case 'AUD':
      case 'CAD':
      case 'NZD':
      case 'INR':
      case 'ZAR':
      case 'GHS':
      case 'KES':
      case 'UGX':
      case 'TZS':
        return 'en';
      case 'AOA':
      case 'BRL':
      case 'CVE':
      case 'MZN':
        return 'pt';
      case 'EUR':
        return 'en';
      case 'JPY':
        return 'ja';
      case 'CNY':
        return 'zh';
      case 'KRW':
        return 'ko';
      case 'RUB':
        return 'ru';
      case 'AED':
        return 'ar';
      case 'SAR':
        return 'ar';
      case 'TRY':
        return 'tr';
      default:
        return 'en';
    }
  };

  if (currency && currency !== 'NGN') {
    const convertedNumber = rate
      ? convertStringToNumber / rate
      : convertStringToNumber;
    return new Intl.NumberFormat(
      `${getCountryLanguageByCurrencySymbol()}-${currency?.substr(0, 2)}`,
      {
        currency,
        style: 'currency',
      },
    ).format(parseFloat(convertedNumber?.toFixed(1)) || 0);
  }
  return new Intl.NumberFormat('en-NG', {
    currency: 'NGN',
    style: 'currency',
    minimumFractionDigits: 0,
  }).format(parseFloat(convertStringToNumber?.toFixed(1)) || 0);
};

export const formatUsd = number =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number || 0);

export const formatNgn = number => {
  const convertStringToNumber =
    typeof number === 'string' ? Number(number) : number;

  return new Intl.NumberFormat('en-NG', {
    currency: 'NGN',
    style: 'currency',
  }).format(convertStringToNumber || 0);
};
