import React, {useContext, useEffect, useState} from 'react';
import {
  EmptyState,
  Filter,
  FilterButton,
  Loader,
  // PageLimit,
  // Pagination,
  SearchInput,
  // TableLayout,
} from '../../../../components';
import {
  // dropdownBlue,
  // dropdownGrey,
  profileIc,
} from '../../../../assets/images/images';
import Layout from '../Layout';
import {useNavigate} from 'react-router-dom';
// import moment from 'moment';
// import {Constants} from '../../../../helpers';
import accountDataArray from './generateDummyData';
import {PageContext} from '../../../../helpers/context';

function Accounts() {
  const [loading] = useState(false);
  // const [currentPage, setCurrentPage] = useState('1');
  // const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  // const {slug} = useParams();
  const [openFilter, setOpenFilter] = useState(false);

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  // const pageClick = selected => {
  //   setCurrentPage(selected);
  // };

  const ACCOUNTS = accountDataArray;

  const handleResetFilter = () => {
    navigate('');
    // setCurrentPage(1);

    setOpenFilter(true);
  };
  const handleFilter = () => {
    navigate('');
    // setCurrentPage(1);

    setOpenFilter(true);
  };

  return (
    <Layout pageTitle="Accounts" pageIcon={profileIc}>
      {loading ? (
        <Loader height={65} />
      ) : !loading && ACCOUNTS?.length === 0 ? (
        <EmptyState body="No account(s) found" noBtn height={65} />
      ) : (
        <section>
          <Filter
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            handleFilter={handleFilter}
            resetUrl={handleResetFilter}
            filterOptions={[]}
          />
          <div className="flex items-center justify-between py-7 bg-white -ml-12 pl-12 -mr-4 xl:mr-[-76px] pr-7">
            <h2 className="hidden text-lg font-medium text-grey sm:block">
              Accounts
            </h2>

            <div className="flex items-center gap-4">
              <SearchInput onChange={() => []} className="outline-white80" />

              <FilterButton
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
              />
            </div>
          </div>
          <EmptyState body="No account(s) found" noBtn height={65} />
          {/*<TableLayout*/}
          {/*  negativeMargins*/}
          {/*  negativeRightMargin="-mr-4 xl:-mr-[76px]"*/}
          {/*>*/}
          {/*  <thead className="text-xs font-semibold uppercase">*/}
          {/*    <tr className="bg-white80">*/}
          {/*      <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">*/}
          {/*        <div className="flex items-end gap-1">*/}
          {/*          <span>Name</span>*/}
          {/*          <img src={dropdownBlue} alt="" width={16} height={16} />*/}
          {/*        </div>*/}
          {/*      </th>*/}
          {/*      <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">*/}
          {/*        <div className="flex items-end gap-1">*/}
          {/*          <span>Email</span>*/}
          {/*          <img src={dropdownGrey} alt="" width={16} height={16} />*/}
          {/*        </div>*/}
          {/*      </th>*/}
          {/*      <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">*/}
          {/*        <div className="flex items-end gap-1">*/}
          {/*          <span>Phone</span>*/}
          {/*          <img src={dropdownGrey} alt="" width={16} height={16} />*/}
          {/*        </div>*/}
          {/*      </th>*/}
          {/*      <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">*/}
          {/*        <div className="flex items-end gap-1">*/}
          {/*          <span>Registration time</span>*/}
          {/*          <img src={dropdownGrey} alt="" width={16} height={16} />*/}
          {/*        </div>*/}
          {/*      </th>*/}
          {/*    </tr>*/}
          {/*  </thead>*/}
          {/*  <tbody className="text-sm bg-white cursor-pointer">*/}
          {/*    {ACCOUNTS &&*/}
          {/*      ACCOUNTS.map((account, i) => (*/}
          {/*        <tr*/}
          {/*          className="border-b border-grey60"*/}
          {/*          key={i}*/}
          {/*          onClick={() =>*/}
          {/*            navigate(*/}
          {/*              `${Constants.PAGES_URL.FLOWS_ACCOUNT_TRANSACTIONS.replace(*/}
          {/*                '/:slug',*/}
          {/*                '',*/}
          {/*              )}/${slug}`,*/}
          {/*              {*/}
          {/*                state: {*/}
          {/*                  ...account,*/}
          {/*                  name: account?.email*/}
          {/*                    .split('-')*/}
          {/*                    .join(' ')*/}
          {/*                    // .replace(/@.* /, ''),*/}
          {/*                },*/}
          {/*              },*/}
          {/*            )*/}
          {/*          }*/}
          {/*        >*/}
          {/*          <td className="p-4 capitalize pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">*/}
          {/*            {account?.email.split('-').join(' ').replace(/@.* /, '') ||*/}
          {/*              '-'}*/}
          {/*          </td>*/}
          {/*          <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">*/}
          {/*            {account?.email || '-'}*/}
          {/*          </td>*/}
          {/*          <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">*/}
          {/*            {account?.mobile || '-'}*/}
          {/*          </td>*/}
          {/*          <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">*/}
          {/*            {moment(account?.registration_time).format(*/}
          {/*              'Do MMM YYYY hh:mm',*/}
          {/*            ) || '-'}*/}
          {/*          </td>*/}
          {/*        </tr>*/}
          {/*      ))}*/}
          {/*  </tbody>*/}
          {/*</TableLayout>*/}

          {/*<div className="flex items-center justify-between flex-wrap gap-4 sm:gap-0 mt-8">*/}
          {/*  <div className="flex items-center text-body text-tiny">*/}
          {/*    <PageLimit*/}
          {/*      onLimitChange={setLimit}*/}
          {/*      total={ACCOUNTS.length}*/}
          {/*      length={ACCOUNTS.length}*/}
          {/*      limit={limit}*/}
          {/*    />*/}
          {/*  </div>*/}

          {/*  <div className="mb-8 sm:mb-0">*/}
          {/*    <Pagination*/}
          {/*      total={Math.ceil(ACCOUNTS.length / limit)}*/}
          {/*      current={+currentPage}*/}
          {/*      onPageChange={activePage => {*/}
          {/*        localStorage.setItem('page', String(activePage));*/}
          {/*        pageClick(activePage);*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </section>
      )}
    </Layout>
  );
}

export default Accounts;
