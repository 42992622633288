import React, {useCallback, useContext, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {dropdownBlue, dropdownGrey} from '../../../../assets/images/images';
import {
  EmptyState,
  FilterButton,
  PageLimit,
  // SearchInput,
  TableLayout,
} from '../../../../components';
import Layout from '../Layout';
import CasesFilter from './CasesFilter';
import * as Actions from '../../../../store/actions';
import Pagination from '../../../../components/Pagination';
import Loader from '../../../../components/Loader';
import moment from 'moment';
import {PageContext} from '../../../../helpers/context';

function Tabs({activeTab, setActiveTab}) {
  return (
    <div className="flex items-center gap-1 relative pr-12">
      <div className="absolute w-full h-[1px] bg-[#E0E0E0] bottom-0" />
      <button
        className={`px-[10px] pt-[12px] pb-[10px] rounded-t border border-b-0 ${
          activeTab === 'pending'
            ? 'bg-brandBlue50 text-sm text-white font-semibold border-brandBlue relative'
            : 'bg-[#F2F2F2] text-sm text-grey border-grey60'
        }`}
        onClick={() => setActiveTab('pending')}
      >
        Pending cases
      </button>
      <button
        className={`px-[10px] pt-[12px] pb-[10px] rounded-t border border-b-0 ${
          activeTab === 'resolved'
            ? 'bg-brandBlue50 text-sm text-white font-semibold border-brandBlue relative'
            : 'bg-[#F2F2F2] text-sm text-grey border-grey60'
        }`}
        onClick={() => setActiveTab('resolved')}
      >
        Resolved cases
      </button>
    </div>
  );
}

function FlowsCases({getCases, flows: {cases, loading}}) {
  const page = localStorage.getItem('casesPageNumber');
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('pending');
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(page || '1');
  const [limit, setLimit] = useState(10);
  const {slug} = useParams();

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  useEffect(() => {
    getCases({id: slug, page: currentPage, limit, type: activeTab});
  }, [currentPage, limit, slug, getCases, activeTab]);

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const handleFilters = useCallback(() => {
    getCases({id: slug, page: currentPage, limit, type: activeTab, ...filters});
  }, [getCases, filters, activeTab, currentPage, limit, slug]);

  const handleResetFilters = useCallback(() => {
    getCases({id: slug, page: currentPage, limit, type: activeTab});
  }, [getCases, activeTab, currentPage, limit, slug]);

  const getCasesByStatus =
    useCallback(
      status => (status.toLowerCase() === 'pending' ? cases?.data : []),
      [cases],
    ) || [];

  return (
    <Layout
      action={
        <CasesFilter
          status={activeTab}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          onCheck={setFilters}
          handleFilter={handleFilters}
          resetFilters={handleResetFilters}
          appFilters={filters}
        />
      }
    >
      <div className="py-7 bg-white -ml-12 pl-12 -mr-4 xl:mr-[-76px] pr-7">
        <div className="mt-7">
          <p className="text-sm text-grey font-medium">Cases</p>
        </div>
        <div className="mt-6 flex items-center justify-between">
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
          <div className="flex items-center gap-4">
            {/*<SearchInput />*/}
            <FilterButton
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <Loader height={65} />
      ) : !loading && cases?.data?.length === 0 ? (
        <EmptyState body="No case(s) found" noBtn height={65} />
      ) : (
        <TableLayout negativeMargins negativeRightMargin="-mr-4 xl:-mr-[76px]">
          <thead className="text-xs font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>Use case</span>
                  <img src={dropdownBlue} alt="" width={16} height={16} />
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>case id</span>
                  <img src={dropdownGrey} alt="" width={16} height={16} />
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>status</span>
                  <img src={dropdownGrey} alt="" width={16} height={16} />
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>date created</span>
                  <img src={dropdownGrey} alt="" width={16} height={16} />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-sm bg-white">
            {cases &&
              cases?.data?.map((_case, i) => (
                <tr
                  className="border-b border-grey60 cursor-pointer"
                  key={i}
                  onClick={() =>
                    navigate(`/flows/cases/${slug}/details/${_case.id}`, {
                      state: {
                        status: _case.status,
                        createdAt: moment(_case?.created_at).format(
                          'Do MMM YYYY h:m a',
                        ),
                      },
                    })
                  }
                >
                  <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                    {_case.use_case || '-'}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {`${_case.id.toUpperCase()}` || '-'}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    <div
                      className={`uppercase py-1 px-2 text-xs font-medium ${
                        _case?.status?.toLowerCase() === 'pending'
                          ? 'text-white bg-brandBlue50'
                          : _case?.status?.toLowerCase() === 'allowed'
                          ? 'text-status-200 bg-statusBg-200'
                          : 'text-status-500 bg-statusBg-500'
                      } rounded-full flex items-center w-fit`}
                    >
                      <span className="-mb-[2px]">{_case.status || '-'}</span>
                    </div>
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {moment(_case?.created_at).format('Do MMM YYYY h:m a')}
                  </td>
                </tr>
              ))}
          </tbody>
        </TableLayout>
      )}

      {!loading && getCasesByStatus(activeTab)?.length !== 0 && (
        <div className="flex items-center justify-between flex-wrap gap-4 sm:gap-0 mt-8">
          <div className="flex items-center text-body text-tiny">
            <PageLimit
              onLimitChange={setLimit}
              total={getCasesByStatus(activeTab) && cases?.data?.total_of_items}
              length={getCasesByStatus(activeTab)?.length}
              limit={limit}
            />
          </div>

          <div className="mb-8 sm:mb-0">
            <Pagination
              total={
                cases?.data && Math.ceil(cases?.data?.total_of_items / limit)
              }
              current={+currentPage}
              onPageChange={activePage => {
                localStorage.setItem('casesPageNumber', String(activePage));
                pageClick(activePage);
              }}
            />
          </div>
        </div>
      )}
    </Layout>
  );
}

export default connect(state => state, Actions)(FlowsCases);
