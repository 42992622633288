import React from 'react';
import {successfulModal} from '../../../../../assets/images/images';
import {shortenLargeNumbers} from '../../../../../helpers/shortenLargeNumbers';

export const Successful = () => {
  const amount = sessionStorage.w_amount;
  return (
    <div className="mt-6">
      <h2 className="text-grey font-medium text-base text-center">
        Withdrawal Successful
      </h2>
      <p className="text-body text-sm max-w-[242px] m-auto my-2">
        Your withdrawal of {shortenLargeNumbers(amount, 'NGN', {startAt: 'M'})}{' '}
        has been completed successfully
      </p>
      <div className="w-full flex justify-center">
        <img src={successfulModal} alt="success" />
      </div>
    </div>
  );
};
