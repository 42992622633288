import React from 'react';
import {useParams} from 'react-router-dom';
import moment from 'moment/moment';
import {
  EmptyState,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
} from '../../../../components';
import {corruptedFile} from '../../../../assets/images/images';
import {getStatusColor} from '../../../../helpers';
import {useTransactions} from '../hooks/useReconQueries';
import {getReconColor} from '../../../../helpers/getStatusColor';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';

export const AccountTransactionsTable = ({
  openUploadTransactionsModal,
  tabType = 'internal',
  setQuery,
  query,
  setTransactionsCount,
}) => {
  const {slug} = useParams();

  const {tableData, isLoading} = useTransactions({
    entity_id: 'account_id',
    id: slug,
    tabType,
    setTransactionsCount,
    ...query,
  });

  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };

  return (
    <div>
      {isLoading ? (
        <Loader height={35} />
      ) : !isLoading && tableData?.rows?.length === 0 ? (
        <EmptyState
          noBtn
          src={corruptedFile}
          className="!h-[calc(50vh-200px)]"
          maxWidth="max-w-[289px]"
          body={`No ${tabType} transaction(s) was found for this account.`}
          customBtn={
            <PrimaryButton
              buttonText="Add Transactions"
              className="py-[14px] px-[47px] rounded-lg mt-6"
              onClick={openUploadTransactionsModal}
            />
          }
        />
      ) : (
        <TableLayout className="w-full mt-6 font-inter">
          <thead className="text-small font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">date created</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">ref id</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">amount</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">type</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">status</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">recon status</span>
              </th>
            </tr>
          </thead>
          <tbody className="text-sm text-body bg-white">
            {!isLoading &&
              tableData &&
              tableData?.rows?.map(transaction => (
                <tr
                  key={transaction?.id}
                  className="text-left border-b cursor-pointer border-grey60"
                >
                  <td className="p-4 pl-12 whitespace-nowrap">
                    {moment(transaction?.transaction_time).format(
                      'Do MMM YYYY h:m a',
                    ) || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {transaction?.transaction_ref || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {shortenLargeNumbers(
                      transaction?.transaction_amount,
                      transaction?.account?.currency,
                      {startAt: 'M'},
                    )}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    <span
                      className={`uppercase w-fit ${getStatusColor(
                        transaction?.transaction_type,
                      )} h-5 flex items-center justify-center  font-medium text-xs rounded-full px-2 py-1`}
                    >
                      {transaction?.transaction_type || '-'}
                    </span>
                  </td>

                  <td className="p-4 pl-0 whitespace-nowrap">
                    <span className="uppercase w-fit rounded-full px-2 py-1 h-5 flex items-center justify-center text-white bg-success text-xs">
                      {transaction?.transaction_status || '-'}
                    </span>
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    <span
                      className={`${getReconColor(
                        transaction?.transaction_recon,
                      )} uppercase w-fit rounded-full px-2 py-1 h-5 flex items-center justify-center text-white text-xs`}
                    >
                      {' '}
                      {transaction?.transaction_recon || '-'}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </TableLayout>
      )}

      {!isLoading && tableData?.rows?.length > 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-12 sm:gap-0 pl-12 pr-6">
          <PageLimit
            onLimitChange={limit => {
              setQuery(prevState => ({
                ...prevState,
                limit,
                page: 1,
              }));
            }}
            limit={query?.limit}
            total={tableData?.page}
            length={tableData?.rows?.length}
          />

          <Pagination
            total={tableData?.pages}
            current={Number(tableData?.page)}
            onPageChange={activePage => {
              pageClick(activePage);
              const searchParams = new URLSearchParams(
                document.location.search,
              );
              searchParams.set('page', activePage);
            }}
          />
        </div>
      )}
    </div>
  );
};
