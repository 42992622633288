/* eslint-disable  react-hooks/exhaustive-deps */
import React, {useEffect, useState, useMemo, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import {
  PrimaryButton,
  SettingsToggle,
  TertiaryButton,
  MultiValueRemove,
  Loader,
} from '../../../../components';
import {copyWhite, lock, profileIc} from '../../../../assets/images/images';
import Layout from '../Layout';
import * as Actions from '../../../../store/actions';
import {USE_CASES} from '../CreateFlowModal';
import {toast} from 'react-toastify';
import DeleteFlowModal from './DeleteFlowModal';
import {isObjectEmpty} from '../../../../helpers/isObjectEmpty';
import {PageContext} from '../../../../helpers/context';

function FlowsSettings({
  deleteFlow,
  updateFlow,
  getFlowsThreshold,
  flows: {flow, loading},
  auth: {userDetails},
}) {
  const [openDeleteFlowModal, setOpenDeleteFlowModal] = useState(false);
  const [checkUpdate, sectCheck] = useState({
    receive_webhook: flow?.flow?.emails && flow?.flow?.emails.length > 0,
  });
  // const [thresholdLimit, setThresholdLimit] = useState(false);
  // const [threshold, setThreshold] = useState(
  //   (flowThreshold && flowThreshold?.data?.threshold_value) || '0',
  // );
  const [updateData, setUpdate] = useState({
    name: flow?.flow?.name || '-',
    description: flow?.flow?.description || '-',
    webhook_url: flow?.flow?.webhook_url || '-',
    emails: flow?.flow?.emails || [],
    receive_webhook: flow?.flow?.emails && flow?.flow?.emails.length > 0,
  });
  const [loadingFlow, setLoadingFlow] = useState(false);

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  useEffect(() => {
    setUpdate({
      name: flow?.flow?.name || '-',
      description: flow?.flow?.description || '-',
      webhook_url: flow?.flow?.webhook_url || '-',
      emails: flow?.flow?.emails || [],
      receive_webhook: flow?.flow?.emails && flow?.flow?.emails.length > 0,
    });
    sectCheck({
      receive_webhook: flow?.flow?.emails && flow?.flow?.emails.length > 0,
    });
  }, [flow]);

  useEffect(() => {
    setLoadingFlow(true);
    setTimeout(() => {
      setLoadingFlow(false);
    }, 5000);
  }, []);

  const [inputValue, setInputValue] = useState('');
  const [noEmail, setNoEmail] = useState(false);

  const {slug} = useParams();

  useEffect(() => {
    getFlowsThreshold({flowId: slug, METHOD: 'GET'});
  }, [getFlowsThreshold]);

  // useEffect(() => {
  //   setThreshold(flowThreshold?.data?.threshold_value);
  // }, [flowThreshold?.data?.threshold_value]);

  const existingUseCase = useMemo(
    () => USE_CASES.find(_case => _case.value === flow?.flow?.use_case),
    [flow],
  );

  const copyText = text => {
    navigator.clipboard.writeText(text);
    toast.success('Link copied');
  };

  const ingestBaseUrl = !loading && userDetails?.company?.ingest_url;

  const realTimeUrl = useMemo(
    () =>
      `${
        flow?.flow?.live_url
          ? flow?.flow?.live_url?.startsWith('https://')
            ? flow?.flow?.live_url
            : `https://${flow?.flow?.live_url}`
          : '...'
      }` || '-',
    [],
  );

  // const backFillUrl = useMemo(
  //   () =>
  //     `${
  //       flow?.flow?.backfill_url
  //         ? flow?.flow?.backfill_url?.startsWith('https://')
  //           ? flow?.flow?.backfill_url
  //           : `https://${flow?.flow?.backfill_url}`
  //         : '...'
  //     }` || '-',
  //   [],
  // );

  const handleChange = e => {
    e.preventDefault();
    const {name, value} = e.target;
    setUpdate({...updateData, [name]: value});
    sectCheck({...checkUpdate, [name]: value});
  };

  // const thresholdCheck = useMemo(
  //   () => +flowThreshold?.data?.threshold_value === +threshold,
  //   [threshold, flowThreshold?.data?.threshold_value],
  // );

  const handleUpdate = () => {
    if (!isObjectEmpty(checkUpdate)) {
      const updatedData = {...updateData};
      const responseProperties = flow?.flow;

      delete updatedData.receive_webhook;

      if (
        !updatedData.emails ||
        (Array.isArray(updatedData.emails) && updatedData.emails.length === 0)
      ) {
        delete updatedData.emails;
      }

      if (updateData.receive_webhook === false) {
        updatedData.emails = [];
      }

      if (updatedData.description === '') {
        updatedData.description = '-';
      }

      if (updatedData.webhook_url === '') {
        updatedData.webhook_url = '-';
      }

      for (const key in updatedData) {
        if (
          (updatedData[key] === '-' && responseProperties[key] === undefined) ||
          updatedData[key] === responseProperties[key]
        ) {
          delete updatedData[key];
        }
      }

      updateFlow(flow?.flow?._id, updatedData);
    }
  };

  const validateEmail = email => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleKeyDown = event => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        // eslint-disable-next-line no-case-declarations
        const value = [...updateData.emails];

        if (!validateEmail(inputValue)) {
          return toast.error('Invalid email address');
        }
        if (value.find(v => v.label === inputValue)) {
          return toast.error('Email already added');
        }
        if (value.length === 5) {
          return toast.error('Maximum of 5 emails allowed');
        }

        setUpdate({
          ...updateData,
          emails: [...updateData.emails, inputValue],
        });
        setNoEmail(false);
        setInputValue('');
        event.preventDefault();
    }
  };

  const handleToggleChange = data => {
    setUpdate(prevUpdateData => {
      const newUpdateData = {
        ...prevUpdateData,
        receive_webhook: data,
      };

      if (!data && prevUpdateData.receive_webhook) {
        newUpdateData.emails = prevUpdateData.emails;
      } else if (!data) {
        newUpdateData.emails = [];
      }

      sectCheck({...checkUpdate, receive_webhook: data});

      return newUpdateData;
    });
  };

  return (
    <Layout pageTitle="Settings" pageIcon={profileIc}>
      <DeleteFlowModal
        open={openDeleteFlowModal}
        setOpen={setOpenDeleteFlowModal}
        onClick={() => deleteFlow(slug, setOpenDeleteFlowModal)}
        loading={loading}
      />
      <section className="mt-8 md:mt-10">
        {loadingFlow ? (
          <Loader height={50} />
        ) : (
          <form className="flex flex-col w-full gap-6 md:flex-row md:items-start">
            <div className="md:w-1/2">
              <h3 className="text-xs font-medium uppercase text-body">
                EDIT FLOW
              </h3>

              <div className="p-4 mt-4 bg-white rounded md:mt-6 md:p-6">
                <label htmlFor="flow_name">
                  Flow name
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={updateData?.name}
                    className="mt-2"
                    onChange={handleChange}
                  />
                </label>
                <label htmlFor="flow_use_cases" className="mt-6 relative">
                  Use cases
                  <input
                    type="text"
                    id="use_case"
                    name="use_case"
                    className="mt-2"
                    defaultValue={existingUseCase?.label}
                    disabled
                  />
                  <img
                    src={lock}
                    alt=""
                    width={16}
                    height={16}
                    className="absolute bottom-4 right-4"
                    title="You can't edit this field"
                  />
                </label>
                <label htmlFor="flow_description" className="mt-6 relative">
                  <p>
                    Description <span className="text-grey30">(optional)</span>
                  </p>
                  <textarea
                    name="description"
                    className="mt-2 min-h-[100px] h-full"
                    value={updateData?.description}
                    onChange={handleChange}
                  />
                </label>
              </div>

              <div className="mt-10">
                <h3 className="text-xs font-medium uppercase text-body">
                  CONFIGURATIONS
                </h3>

                <div className="p-4 mt-4 bg-white rounded md:mt-6 md:p-6">
                  {/*<label htmlFor="realtime_url" className="relative">*/}
                  {/*  AI strictness level*/}
                  {/*  <input*/}
                  {/*    type="number"*/}
                  {/*    value={threshold}*/}
                  {/*    id="realtime_url"*/}
                  {/*    name="realtime_url"*/}
                  {/*    placeholder={threshold || '-'}*/}
                  {/*    className="mb-5 mt-2"*/}
                  {/*    onChange={e => {*/}
                  {/*      setThreshold(e.target.value);*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*  <button*/}
                  {/*    disabled={loading || thresholdCheck}*/}
                  {/*    onClick={async e => {*/}
                  {/*      e.preventDefault();*/}
                  {/*      if (!thresholdCheck) {*/}
                  {/*        if (+threshold > 100 || +threshold < 0) {*/}
                  {/*          return toast.error(*/}
                  {/*            'Threshold must be less than or equal to 100 ',*/}
                  {/*          );*/}
                  {/*        }*/}
                  {/*        setThresholdLimit(true);*/}

                  {/*        await getFlowsThreshold({*/}
                  {/*          flowId: slug,*/}
                  {/*          METHOD: 'PUT',*/}
                  {/*          data: {*/}
                  {/*            threshold: +threshold,*/}
                  {/*          },*/}
                  {/*        });*/}
                  {/*        setThresholdLimit(false);*/}
                  {/*      }*/}
                  {/*    }}*/}
                  {/*    className={`absolute flex items-center gap-2 p-2 ${*/}
                  {/*      thresholdCheck || loading ? 'border' : ''*/}
                  {/*    } rounded bottom-[30px] right-4 bg-brandBlue`}*/}
                  {/*    type="button"*/}
                  {/*  >*/}
                  {/*    <span*/}
                  {/*      className={`text-xs font-medium ${*/}
                  {/*        thresholdCheck || loading*/}
                  {/*          ? 'text-grey10'*/}
                  {/*          : 'text-white'*/}
                  {/*      }`}*/}
                  {/*    >*/}
                  {/*      {thresholdLimit ? 'Setting...' : 'Set threshold'}*/}
                  {/*    </span>*/}
                  {/*  </button>*/}
                  {/*</label>*/}
                  <label htmlFor="realtime_url" className="relative">
                    Realtime URL
                    <input
                      type="text"
                      id="realtime_url"
                      name="realtime_url"
                      placeholder={
                        loading
                          ? '-'
                          : ingestBaseUrl
                          ? realTimeUrl?.replace(
                              'https://ingest.dojah.io',
                              ingestBaseUrl,
                            )
                          : realTimeUrl
                      }
                      className="mt-2"
                      disabled
                    />
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault();
                        copyText(
                          ingestBaseUrl
                            ? realTimeUrl?.replace(
                                'https://ingest.dojah.io',
                                ingestBaseUrl,
                              )
                            : realTimeUrl,
                        );
                      }}
                      className="absolute flex items-center gap-2 p-2 rounded bottom-[9px] right-4 bg-brandBlue"
                    >
                      <img src={copyWhite} alt="" width={14} height={14} />
                      <span className="text-xs font-medium text-white">
                        Copy
                      </span>
                    </button>
                  </label>
                  {/*<label htmlFor="backfill_url" className="relative mt-6">*/}
                  {/*  Backfill URL*/}
                  {/*  <input*/}
                  {/*    type="text"*/}
                  {/*    id="backfill_url"*/}
                  {/*    name="backfill_url"*/}
                  {/*    placeholder={*/}
                  {/*      !thresholdLimit && !loading && ingestBaseUrl*/}
                  {/*        ? backFillUrl?.replace(*/}
                  {/*            'https://ingest.dojah.io',*/}
                  {/*            ingestBaseUrl,*/}
                  {/*          )*/}
                  {/*        : backFillUrl || '-'*/}
                  {/*    }*/}
                  {/*    className="mt-2"*/}
                  {/*    disabled*/}
                  {/*  />*/}
                  {/*  <button*/}
                  {/*    type="button"*/}
                  {/*    onClick={e => {*/}
                  {/*      e.preventDefault();*/}
                  {/*      copyText(*/}
                  {/*        ingestBaseUrl*/}
                  {/*          ? backFillUrl?.replace(*/}
                  {/*              'https://ingest.dojah.io',*/}
                  {/*              ingestBaseUrl,*/}
                  {/*            )*/}
                  {/*          : backFillUrl,*/}
                  {/*      );*/}
                  {/*    }}*/}
                  {/*    className="absolute flex items-center gap-2 p-2 rounded bottom-[9px] right-4 bg-brandBlue"*/}
                  {/*  >*/}
                  {/*    <img src={copyWhite} alt="" width={14} height={14} />*/}
                  {/*    <span className="text-xs font-medium text-white">*/}
                  {/*      Copy*/}
                  {/*    </span>*/}
                  {/*  </button>*/}
                  {/*</label>*/}
                </div>
              </div>
            </div>

            <div className="md:w-1/2">
              <h3 className="text-xs font-medium uppercase text-body">
                WEBHOOK INFO
              </h3>

              <div className="p-6 mt-6 bg-white rounded">
                <label htmlFor="webhook_url">
                  WEBHOOK URL
                  <input
                    type="text"
                    id="webhook_url"
                    name="webhook_url"
                    value={updateData?.webhook_url}
                    className="mt-2"
                    onChange={handleChange}
                  />
                </label>
              </div>

              <div className="mt-10">
                <h3 className="text-xs font-medium uppercase text-body">
                  EMAIL Notifications
                </h3>

                <div className="p-6 mt-6 bg-white rounded receive_webhook">
                  <label htmlFor="receive_webhook">
                    Opt to receive email notifications whenever a new case
                    emerges
                    <div className="mt-4 flex items-center gap-2 p-4 rounded bg-white80 w-full">
                      <SettingsToggle
                        title="receive_webhook"
                        isChecked={updateData?.receive_webhook}
                        onCheck={handleToggleChange}
                      />
                      <p className="text-grey text-sm -mb-1">Email</p>
                    </div>
                  </label>

                  {updateData?.receive_webhook ? (
                    <>
                      <label htmlFor="emails" className="mt-4 mb-1 relative">
                        Add email address
                      </label>
                      <CreatableSelect
                        components={{
                          DropdownIndicator: null,
                          MultiValueRemove,
                        }}
                        inputValue={inputValue}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={newValue => {
                          if (newValue) {
                            setUpdate({
                              ...updateData,
                              emails: newValue.map(v => v.label),
                            });
                            sectCheck({
                              ...checkUpdate,
                              emails: newValue.map(v => v.label),
                            });
                          }
                        }}
                        onInputChange={newValue => {
                          setInputValue(newValue);
                        }}
                        onKeyDown={handleKeyDown}
                        placeholder="Type emails here and press enter or tab to add"
                        value={
                          updateData?.emails.map(v => ({
                            label: v,
                            value: v,
                          })) || []
                        }
                        className={`creatable-multi-select rounded ${
                          noEmail ? 'border border-danger ' : ''
                        }`}
                        classNamePrefix="react-select"
                        multiValueLabel
                      />
                    </>
                  ) : null}
                </div>
              </div>

              <div className="flex items-center gap-4 mt-8">
                <PrimaryButton
                  loading={loading}
                  onClick={handleUpdate}
                  buttonText="Save changes"
                />

                <TertiaryButton
                  buttonText="Delete flow"
                  onClick={() => setOpenDeleteFlowModal(true)}
                />
              </div>
            </div>
          </form>
        )}
      </section>
    </Layout>
  );
}

export default connect(state => state, Actions)(FlowsSettings);
