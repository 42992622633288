import React, {useState, useCallback, useMemo, useEffect} from 'react';
import Select from 'react-select';
import Papa from 'papaparse';
import Fuse from 'fuse.js';
import * as XLSX from 'xlsx';
import {
  customStyles,
  CustomValueContainer,
  DropdownIndicator,
  PrimaryButton,
} from '../../../../components';
import {
  defaultFileUploadHeaders,
  keyMappings,
  requiredFileUploadHeaders,
} from '../constants';
import {corruptedFile} from '../../../../assets/images/images';

const formatLabel = label => {
  switch (label) {
    case 'transaction_fee':
      return 'Transaction Fee / Charge';
    case 'transaction_date':
      return 'Transaction Date Time';
    default:
      return label?.replaceAll('_', ' ');
  }
};

const formatKeyArr = key => keyMappings[key] || key?.split('_') || [];

const DocumentMatching = ({
  file,
  setKeys,
  handleSubmit,
  isDisabled,
  loading,
}) => {
  const [headers, setHeaders] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});

  useEffect(() => {
    const parseFile = async () => {
      const fileExt = file?.name.split('.').pop().toLowerCase();
      let fileHeaders = [];

      if (fileExt === 'csv') {
        const results = await new Promise(resolve =>
          Papa.parse(file, {complete: resolve, header: false}),
        );
        fileHeaders = results.data[0];
      } else if (fileExt === 'xlsx' || fileExt === 'xls') {
        const buffer = await file.arrayBuffer();
        const workbook = XLSX.read(buffer, {type: 'array'});
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        fileHeaders = XLSX.utils.sheet_to_json(worksheet, {header: 1})[0];
      }

      setHeaders(
        fileHeaders.filter(Boolean).map(value => ({
          label: formatLabel(value),
          value,
        })),
      );
    };

    if (file) parseFile();
  }, [file]);

  const fuse = useMemo(
    () =>
      new Fuse(headers, {
        keys: [{name: 'label', getFn: head => head.label}],
        includeScore: true,
        threshold: 0.1,
        // findAllMatches: true,
      }),
    [headers],
  );

  useEffect(() => {
    if (headers.length === 0) {
      setSelectedValues({});
      return;
    }
    const newMatchedHeaders = defaultFileUploadHeaders.reduce(
      (acc, defaultHeader) => {
        const searchArr = formatKeyArr(defaultHeader).map(v => ({
          label: `${v}`,
        }));
        const result = fuse.search({$or: searchArr})[0]?.item;
        if (result) acc[defaultHeader] = result;
        return acc;
      },
      {},
    );

    setSelectedValues(newMatchedHeaders);
  }, [headers, fuse]);

  const handleSelectChange = useCallback((selectedOption, header) => {
    setSelectedValues(prev => ({
      ...prev,
      [header]: selectedOption,
    }));
  }, []);

  const availableHeaders = useMemo(
    () =>
      headers.filter(
        header =>
          !Object.values(selectedValues).some(
            selected => selected?.value === header.value,
          ),
      ),
    [headers, selectedValues],
  );
  const isRequiredFilled = requiredFileUploadHeaders.every(
    r => selectedValues[r],
  );

  if (headers.length === 0) {
    return (
      <div className="my-10 flex flex-col items-center">
        <img src={corruptedFile} alt="" />
        <h3 className="text-[16px] font-normal mt-3">Invalid file header</h3>
        <p className="text-[12px] text-grey-500">
          No CSV/XLSX file header(s) found. <br /> Please upload another file
        </p>
      </div>
    );
  }

  return (
    <>
      <h3 className="font-medium text-left mt-5 text-base text-grey">
        Document Matching
      </h3>
      <div className="grid py-5 grid-cols-1 md:grid-cols-2 w-full gap-3 items-center mt-3">
        <div className="border border-grey-100 p-1 rounded-lg">
          <p className="text-sm text-left m-3">Match information</p>
          <div className="bg-white80 p-2 py-4 rounded-lg">
            {defaultFileUploadHeaders.map(header => (
              <p
                key={header}
                className={`py-[9px] capitalize mb-3 text-left rounded cursor-not-allowed text-grey-500 text-sm pl-2 border border-grey-100 ${
                  requiredFileUploadHeaders.includes(header) ? 'required' : ''
                }`}
              >
                {formatLabel(header)}
              </p>
            ))}
          </div>
        </div>
        <div className="border border-grey-100 p-1 rounded-lg">
          <p className="text-sm text-left m-3">Uploaded information</p>
          <div className="bg-white80 p-2 py-4 rounded-lg">
            {defaultFileUploadHeaders.map(header => (
              <Select
                key={header}
                placeholder="Select a header"
                classNamePrefix="react-select"
                components={{
                  DropdownIndicator,
                  ValueContainer: CustomValueContainer,
                }}
                styles={customStyles}
                className="text-start mb-3 add_project_select bg-white"
                menuPlacement="auto"
                menuPosition="absolute"
                options={availableHeaders}
                value={selectedValues[header]}
                onChange={selectedOption =>
                  handleSelectChange(selectedOption, header)
                }
                isClearable={true}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="w-full">
        <PrimaryButton
          buttonText="Complete Upload"
          className="w-full mt-6"
          loading={loading}
          onClick={() => {
            const keys = Object.fromEntries(
              defaultFileUploadHeaders.map(header => [
                header,
                selectedValues[header]?.value || null,
              ]),
            );
            setKeys(keys);
            handleSubmit(keys);
          }}
          disabled={
            !Object.values(selectedValues).some(Boolean) ||
            !isRequiredFilled ||
            isDisabled
          }
        />
      </div>
    </>
  );
};

export default DocumentMatching;
