import React, {useEffect, useState} from 'react';
import {
  Modal,
  customStyles,
  DropdownIndicator,
  PrimaryButton,
  Checkbox,
} from '../../../../components';
import {closeModal, uploadCsv} from '../../../../assets/images/images';
import Select from 'react-select';
import {toast} from 'react-toastify';
import useDragAndDrop from '../../../../hooks/useDragAndDrop';

import {accountTransactionType} from '../Recon_Constants';
import {useMutation, useQueryClient} from 'react-query';
import {
  addTransaction,
  uploadTransaction,
} from '../../../../requests/queries/reconciliations';
import {useAccounts} from '../hooks/useAccounts';
import {GoBack} from '../components/GoBack';
import {useParams} from 'react-router-dom';
import DocumentMatching from './DocumentMatching';

const initialInputs = {
  amount: '',
  ref: '',
  time: '',
};
function ManualTransactionModal({
  show,
  onClose,
  goBack,
  noUpload,
  selectedAccount,
}) {
  const {slug} = useParams();
  const {accountSelectionData} = useAccounts({slug});
  const [file, setFileData] = useState(null);
  const [keys, setKeys] = useState(null);
  const [inputs, setInputs] = useState(initialInputs);
  const [account, setAccount] = useState(null);
  const [matchScreen, setMatchScreen] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [documentPassword, setDocumentPassword] = useState('');

  const [transactionType, setTransactionType] = useState(
    accountTransactionType[0],
  );

  const queryClient = useQueryClient();
  const {dragOver, setDragOver, onDragOver, onDragLeave} = useDragAndDrop();

  useEffect(() => {
    setAccount(
      accountSelectionData?.length > 0
        ? accountSelectionData[0]
        : selectedAccount,
    );
    return () => setMatchScreen(false);
  }, [accountSelectionData, selectedAccount]);
  useEffect(() => setMatchScreen(false), [setMatchScreen]);

  const {mutate, isLoading} = useMutation(
    'add-account-transaction',
    addTransaction,
  );

  const {mutate: uploadData, isLoading: uploadLoading} = useMutation(
    'upload-account-transaction',
    uploadTransaction,
  );

  const handleFile = file => {
    if (file) {
      setFileData(file);
    }
  };

  const onDrop = e => {
    e.preventDefault();
    setDragOver(false);
    const selectedFile = e?.dataTransfer?.files[0];
    const uploadType = ['xlsx', 'csv', 'xls', 'pdf'];
    const fileExt = selectedFile.name.split('.');
    if (!uploadType.includes(fileExt[fileExt.length - 1])) {
      return toast.error(
        `Please provide a valid file type - (xlsx, csv, xls, pdf)`,
      );
    }
    handleFile(selectedFile);
  };

  const handleInputs = e => {
    const {name, value} = e.target;
    setInputs(prev => ({...prev, [name]: value}));
  };

  const handleSubmit = async documentKeys => {
    if (file && account?.value) {
      if (!account?.value) {
        return toast.error('Please select an account.');
      }
      const trimmedFileName = file.name.trim().replace(/\s+/g, '_');
      const trimmedFile = new File([file], trimmedFileName, {
        type: file.type,
      });

      const formData = new FormData();
      formData.append('file', trimmedFile);
      formData.append('account_id', account?.value || selectedAccount?.value);
      formData.append(
        'currency',
        account?.currency || selectedAccount?.currency,
      );

      formData.append('keys', JSON.stringify(documentKeys));
      try {
        await uploadData(formData, {
          onSuccess: () => {
            toast.success('File uploaded successfully');
            queryClient.invalidateQueries();
            onClose(false);
            setMatchScreen(!matchScreen);
            setKeys(null);
            setFileData(null);
          },
          onError: error => {
            toast.error(error.response.data?.error);
          },
        });
      } catch (e) {
        console.log(e);
      }
      return;
    }

    try {
      const truthyData = Object.fromEntries(
        Object.entries(inputs).filter(([, value]) => value),
      );
      const data = {
        account_id: account ? account?.value : selectedAccount?.value,
        type: account ? account?.type : selectedAccount?.type,
        event: {
          transaction: {
            ...truthyData,
            type: transactionType?.value,
          },
        },
      };

      if (data) {
        mutate(data, {
          onSuccess: () => {
            toast.success('Transaction added successfully');
            queryClient.invalidateQueries();
            onClose(false);
            setInputs(initialInputs);
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const isMatchingPage = file && matchScreen;

  return (
    <Modal
      show={show}
      onClose={() => {
        onClose(false);
        setMatchScreen(false);
        setFileData(null);
      }}
      extraModalClasses={`${
        isMatchingPage ? 'max-w-[580px]' : 'max-w-[480px]'
      } overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal`}
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center justify-between w-full">
          {goBack && (
            <GoBack
              goBack={() => {
                if (matchScreen) {
                  return setMatchScreen(!matchScreen);
                }
                goBack();
                onClose(false);
              }}
            />
          )}

          <button
            onClick={() => {
              onClose(false);
              setFileData(null);
            }}
            className="hidden sm:block ml-auto"
          >
            <img src={closeModal} alt="" />
          </button>
        </div>

        {isMatchingPage ? (
          <DocumentMatching
            file={file}
            setKeys={setKeys}
            isDisabled={!file && !keys && (!account || !selectedAccount?.value)}
            handleSubmit={handleSubmit}
            loading={uploadLoading}
            documentPassword={documentPassword}
          />
        ) : (
          <div className="relative">
            <h3 className="font-medium text-left mt-5 text-base text-grey">
              Create Manual Transaction
            </h3>

            <label
              htmlFor="ID_type"
              className="relative mt-6 text-body font-medium "
            >
              <span className="required">Select account</span>
            </label>
            <Select
              defaultValue={selectedAccount || account}
              placeholder="Select an account"
              classNamePrefix="react-select"
              components={{DropdownIndicator}}
              styles={customStyles}
              className="text-start mt-2 add_project_select"
              menuPlacement="auto"
              options={accountSelectionData}
              onChange={setAccount}
              isDisabled={!!selectedAccount}
            />
            <label htmlFor="amount" className="mt-6">
              <span className="text-sm text-body font-medium required">
                Amount
              </span>
              <input
                type="number"
                name="amount"
                className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
                id="amount"
                placeholder="Enter amount"
                value={inputs?.amount}
                onChange={handleInputs}
              />
            </label>

            {!selectedAccount && (
              <label htmlFor="reference_id" className="mt-6">
                <span className="text-sm text-body font-medium required">
                  Reference ID
                </span>
                <input
                  type="text"
                  name="ref"
                  className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
                  id="ref"
                  placeholder="Input reference ID"
                  value={inputs?.ref}
                  onChange={handleInputs}
                />
              </label>
            )}

            <label htmlFor="date" className="mt-6">
              <span className="text-sm text-body font-medium required">
                Date
              </span>
              <input
                type="date"
                name="time"
                className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
                id="time"
                placeholder="Date"
                value={inputs?.time}
                onChange={handleInputs}
              />
            </label>

            <label
              htmlFor="ID_type"
              className="relative mt-6 text-body font-medium"
            >
              Type
            </label>
            <Select
              defaultValue={transactionType}
              placeholder="Select an ID type"
              classNamePrefix="react-select"
              components={{DropdownIndicator}}
              styles={customStyles}
              className="text-start mt-2 add_project_select"
              menuPlacement="auto"
              options={accountTransactionType}
              onChange={setTransactionType}
            />

            {!noUpload && (
              <div className="flex items-center justify-center w-full mt-10 mb-6">
                <h1 className="text-grey font-medium text-lg">Or</h1>
              </div>
            )}
            {!noUpload && (
              <>
                <div
                  onDragOver={onDragOver}
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}
                  style={{
                    border: `${dragOver ? '3px dashed #33333322' : ''}`,
                  }}
                  className="py-[22px] flex flex-col items-center gap-[2px] text-center border border-brandBlue border-dashed rounded"
                >
                  <div className="flex-col items-center hidden sm:flex">
                    {!file && (
                      <img src={uploadCsv} alt="" width={48} height={48} />
                    )}
                    <p className="text-body">
                      {dragOver ? (
                        'Please drop file here'
                      ) : (
                        <span style={{color: file ? '#00000032' : ''}}>
                          Drag and drop a{' '}
                          <span className="text-grey40 italic">
                            (.csv, xsl or .xslx)
                          </span>{' '}
                          file here
                        </span>
                      )}
                    </p>
                  </div>
                  {file ? (
                    <div className="text-body text-sm flex my-2 gap-[3px] items-baseline">
                      <input
                        type="file"
                        id="file"
                        onChange={e => {
                          handleFile(e.target.files[0]);
                        }}
                        accept=".xlsx, .csv, .xls"
                      />
                      <label htmlFor="file">Change file</label>
                    </div>
                  ) : (
                    <div className="text-body text-sm flex gap-[3px] items-baseline">
                      <span className="hidden sm:block">or</span>
                      <input
                        type="file"
                        id="file"
                        onChange={e => {
                          handleFile(e.target.files[0]);
                        }}
                        accept=".xlsx, .csv, .xls"
                      />
                      <label htmlFor="file">click here to select</label>
                      from your computer
                    </div>
                  )}
                  <p className="text-body text-sm flex gap-[3px] items-baseline">
                    {file ? (
                      <>
                        File:{' '}
                        <span className="font-bold text-black ">
                          {file.name}
                        </span>
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                </div>
              </>
            )}

            {file && (
              <div className="mt-3 flex items-center mb-3">
                <Checkbox
                  label={`Is there password for this document?`}
                  className="py-[10px]"
                  labelStyle="text-sm text-body leading-[18px]"
                  noLeftPadding
                  isChecked={isPassword}
                  onChange={e => {
                    setIsPassword(e.target.checked);
                    setDocumentPassword('');
                  }}
                />
                <span className="text-grey40 text-sm pl-1"> (Optional)</span>
              </div>
            )}
            {isPassword && file && (
              <>
                <label htmlFor="password " className="mb-2">
                  Enter Document Password
                </label>
                <input
                  type="password"
                  value={documentPassword}
                  onChange={e => setDocumentPassword(e.target.value)}
                />
              </>
            )}

            <div className="flex items-center mt-10">
              {file && !matchScreen ? (
                <PrimaryButton
                  onClick={() => setMatchScreen(!matchScreen)}
                  buttonText="Next"
                  className="w-full rounded-lg"
                  disabled={!file}
                />
              ) : (
                <PrimaryButton
                  onClick={handleSubmit}
                  buttonText="Submit"
                  className="w-full rounded-lg"
                  loading={isLoading || uploadLoading}
                  disabled={
                    selectedAccount
                      ? !file && (!inputs.amount || !inputs.time)
                      : (Object.values(inputs || {}).some(e => !e) ||
                          !transactionType?.value) &&
                        (!file || !account?.value)
                  }
                />
              )}
            </div>
            <div className="flex items-center mt-6"></div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ManualTransactionModal;
