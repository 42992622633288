import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import moment from 'moment';
import {
  dropdownBlue,
  dropdownGrey,
  edit,
  eye,
  fraudFlowBreadcrumb,
} from '../../../assets/images/images';
import {
  DashboardLayout,
  EmptyState,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
} from '../../../components';
import {getAppName, parseUrlParams} from '../../../helpers';
import CreateFlowModal from './CreateFlowModal';
import * as Actions from '../../../store/actions';
import {isObjectValueEmpty} from '../../../helpers/isObjectEmpty';
import {PageContext} from '../../../helpers/context';

const initialData = {
  app_id: '',
  name: '',
  use_case: '',
  description: '',
};

function FraudDetectionFlows({
  getFlows,
  getAllApps,
  createFlow,
  apps: {allApps},
  flows: {loading, flows},
  auth: {userDetails},
}) {
  const navigate = useNavigate();
  const [openFlowModal, setOpenFlowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(initialData);
  const [limit, setLimit] = useState(10);

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  useEffect(() => {
    const apiParams = parseUrlParams();
    apiParams.limit = limit;

    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(parseInt(pageParam));
      apiParams.page = parseInt(pageParam);
    } else {
      apiParams.page = currentPage;
    }

    getFlows(apiParams);
    getAllApps({});
  }, [getAllApps, getFlows, currentPage, limit]);

  const APP_DATA = useMemo(
    () =>
      allApps &&
      allApps?.apps?.map(app => ({
        label: app.name,
        value: app._id,
        name: app.name,
      })),
    [allApps],
  );

  const handleCreateFLow = useCallback(() => {
    const check = {
      ...data,
    };
    delete check.description;
    if (!isObjectValueEmpty(check)) {
      toast.error('Oops! some required fields are still empty');
    } else {
      createFlow(data, () => {
        setOpenFlowModal(false);
        setData(initialData);
      });
    }
  }, [data, createFlow]);

  const {editProcess, manageFlows} =
    useMemo(() => userDetails?.permissions?.detectPermissions, [userDetails]) ||
    {};

  return (
    <DashboardLayout
      bg="bg-white"
      topPadding="pt-6"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={fraudFlowBreadcrumb} alt="" />
          <p className="ml-2 -mb-1">Fraud Flows</p>
        </div>
      }
    >
      <CreateFlowModal
        apps={APP_DATA}
        open={openFlowModal}
        setOpen={setOpenFlowModal}
        setData={setData}
        flowData={data}
        onCreate={handleCreateFLow}
        loading={loading}
      />

      {loading ? (
        <Loader />
      ) : !loading && flows && flows?.flows?.length === 0 ? (
        <EmptyState
          buttonText="Create a flow"
          onClick={() => setOpenFlowModal(true)}
        />
      ) : (
        <section className="flex flex-col">
          <div className="flex items-center justify-between w-full mb-6">
            <h2 className="text-grey text-base sm:text-lg font-medium">
              Flows
            </h2>
            <div className="flex items-center gap-4">
              {/*<SearchInput />*/}
              <PrimaryButton
                buttonText="Create a flow"
                onClick={() => setOpenFlowModal(true)}
                disabled={!manageFlows}
              />
            </div>
          </div>

          <TableLayout
            negativeMargins
            negativeRightMargin="-mr-4 xl:-mr-[76px]"
          >
            <thead className="text-xs font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>Name of flow</span>
                    <img src={dropdownBlue} alt="" width={16} height={16} />
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>app name</span>
                    <img src={dropdownGrey} alt="" width={16} height={16} />
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>USe cases</span>
                    <img src={dropdownGrey} alt="" width={16} height={16} />
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>date created</span>
                    <img src={dropdownGrey} alt="" width={16} height={16} />
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>STATUS</span>
                    <img src={dropdownGrey} alt="" width={16} height={16} />
                  </div>
                </th>
                <th className="min-w-[175px]"></th>
              </tr>
            </thead>
            <tbody className="text-sm bg-white">
              {flows &&
                flows?.flows?.map(flow => (
                  <tr
                    className="border-b border-grey60 cursor-pointer "
                    key={flow._id}
                    onClick={() => navigate(`/flows/analysis/${flow._id}`)}
                    style={{
                      pointerEvents: manageFlows ? 'auto' : 'none',
                    }}
                  >
                    <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                      {flow?.name || '-'}
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {getAppName(allApps && allApps?.apps, flow.app) || '-'}
                    </td>
                    <td className="capitalize p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {flow.use_case || '-'}
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {moment(flow.createdAt).format('Do MMM YYYY hh:mm')}
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      <div
                        className={`uppercase py-1 px-2 text-xs font-medium ${
                          flow.connected
                            ? 'text-status-200 bg-statusBg-200'
                            : 'text-status-500 bg-statusBg-500'
                        }  rounded-full flex items-center w-fit`}
                      >
                        <span className="-mb-[2px]">
                          {flow.connected ? 'connected' : 'not connected'}
                        </span>
                      </div>
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap relative">
                      <div className="flex items-center gap-10 z-2 p-5 absolute top-0">
                        {/*<SettingsToggle />*/}
                        <Link
                          to={`/flows/analysis/${flow._id}`}
                          title="View"
                          className="h-4 cursor-pointer aspect-square"
                        >
                          <img src={eye} alt="" />
                        </Link>

                        <Link
                          to={`/flows/edit-process/${flow._id}`}
                          title="Edit process"
                          className="h-4 cursor-pointer aspect-square"
                          style={{
                            pointerEvents:
                              manageFlows && editProcess ? 'auto' : 'none',
                          }}
                        >
                          <img src={edit} alt="" />
                        </Link>
                        {/* <p onClick={() => deleteFlow(flow._id)}>delete</p> */}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </TableLayout>

          {flows && flows?.flows?.length !== 0 && (
            <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
              <div className="flex items-center text-body text-tiny">
                <PageLimit
                  onLimitChange={setLimit}
                  // className="mt-3 mt-sm-0"
                  flows
                  length={flows && flows?.subscriptions?.length}
                  limit={limit}
                />
              </div>

              <div className="mb-8 sm:mb-0">
                <Pagination
                  total={flows && Math.ceil(flows?.totalRecords / limit)}
                  current={+currentPage}
                  onPageChange={activePage => {
                    pageClick(activePage);
                    const searchParams = new URLSearchParams(
                      document.location.search,
                    );
                    searchParams.set('page', activePage);
                    const newSearchParams = searchParams.toString();
                    navigate(`?${newSearchParams}`);
                  }}
                />
              </div>
            </div>
          )}
        </section>
      )}
    </DashboardLayout>
  );
}
export default connect(state => state, Actions)(FraudDetectionFlows);
