import React, {useState} from 'react';
import Password from './Passwords';

function ResetPassword() {
  const [inputs, setInputs] = useState({});

  return (
    <>
      <Password
        headerText="Create a new password"
        buttonText="Reset Password"
        subHeaderText="Create a new password in order to access your account"
        inputs={inputs}
        setInputs={setInputs}
        buttonType={'"Reset Password"'}
      />
    </>
  );
}

export default ResetPassword;
