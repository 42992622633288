/* eslint-disable react-hooks/exhaustive-deps */

import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';

import {connect} from 'react-redux';
import {Accordion, VerificationToggle, Radios} from '../../components';
import {reviewProcess} from '../../helpers/constants/widgets';
import CountryCombobox from './CountryCombobox';

import * as Actions from '../../store/actions';
import {calculatePricing} from '../../helpers/calculatePricing';
import {EasyOnboardContext} from '../../helpers/context/EasyOnboardContext';
import countryList from 'react-select-country-list';
import Application from './Application';

function Configuration({
  widgets: {
    singleWidget,
    widgetCurrentPricing,
    widgetPricing,
    widgetCurrentTotalPricing,
  },
  setWidgetCurrentPricing,
  setWidgetTotalCurrentPricing,
  setApp,
}) {
  const currentProcess = reviewProcess.find(
    process => process?.label === singleWidget?.widget?.review_process,
  );

  const options = useMemo(() => countryList().getData(), []);

  const [selectedWebhook, setSelectedWebhook] = useState(
    singleWidget?.widget?.webhook,
  );
  const [appOpen, setAppOpen] = useState(false);
  const [supportEmail, setSupportEmail] = useState(
    !!singleWidget?.widget?.support_email,
  );

  const [_process, setProcess] = useState(currentProcess?.value);
  const [hideSmartphonePrompt, setHideSmartphonePrompt] = useState(
    singleWidget?.widget?.hide_smartphone_prompt,
  );
  const [country, setCountry] = useState(
    singleWidget?.widget?.country || [...options],
  );

  const [selectedFeedback, setDirectFeedback] = useState(
    singleWidget?.widget?.direct_feedback,
  );
  const [duplicateCheck, setDuplicateCheck] = useState(
    singleWidget?.widget?.duplicate_check,
  );

  const inputRef = useRef(null);

  const {widgetData, setWidgetData, setTrackChanges} =
    useContext(EasyOnboardContext);

  useEffect(() => {
    setCountry(singleWidget?.widget?.country || []);
    setWidgetData({
      ...widgetData,
      country: singleWidget?.widget?.country || options?.map(c => c.value),
      direct_feedback: singleWidget?.widget?.direct_feedback,
      duplicate_check: singleWidget?.widget?.duplicate_check,
      support_email: singleWidget?.widget?.support_email || null,
      review_process: singleWidget?.widget?.review_process || 'Automatic',
      hide_smartphone_prompt: singleWidget?.widget?.hide_smartphone_prompt,
      index_instructions: singleWidget?.widget?.index_instructions,
    });

    setSupportEmail(!!singleWidget?.widget?.support_email);
    setHideSmartphonePrompt(singleWidget?.widget?.hide_smartphone_prompt);
    setSelectedWebhook(singleWidget?.widget?.webhook);
    setDirectFeedback(singleWidget?.widget?.direct_feedback);

    return () => setCountry([...options]);
  }, [singleWidget?.widget?.country?.length > 0, options]);

  useEffect(() => {
    if (widgetPricing) {
      setWidgetCurrentPricing({
        aml: {
          services: {
            aml:
              (widgetPricing && widgetPricing['aml']?.services['aml'] / 100) ||
              0,
          },
        },
      });
    } else {
      setWidgetCurrentPricing({
        aml: {},
      });
      setWidgetTotalCurrentPricing({
        ...widgetCurrentTotalPricing,
        aml: {},
      });
    }

    return () =>
      setWidgetCurrentPricing({
        aml: {},
      });
  }, [widgetPricing]);

  useEffect(() => {
    const priceData = calculatePricing(widgetCurrentPricing?.aml || {}, 'aml');
    setWidgetTotalCurrentPricing({...widgetCurrentTotalPricing, ...priceData});
  }, [widgetCurrentPricing]);

  return (
    <Accordion title="Configuration">
      <p className="text-xs text-body leading-[18px]">
        Configure your verification process to easily identify your users
      </p>
      <div className="my-2">
        <Application
          setApp={setApp}
          widgetData={widgetData}
          setAppOpen={setAppOpen}
        />
        {!appOpen && (
          <p className="text-xs text-body leading-[18px]">
            Customize your widget with your brand logo and color
          </p>
        )}
      </div>
      <label data-smallerlabel="" className="relative mt-4 mb-2">
        <p className="flex items-center text-sm font-medium gap-[2px]">
          Country
        </p>
        <p className="text-xs text-body leading-[18px]">Select country</p>
      </label>
      <CountryCombobox
        countryData={country}
        onChange={country => {
          setCountry(country);
          setWidgetData({...widgetData, country: country?.map(c => c.value)});
        }}
      />
      <label data-smallerlabel="" className="relative mt-6 ">
        <p className="flex items-center text-sm font-medium gap-[2px]">
          Review Process
        </p>
      </label>
      <Radios
        data={reviewProcess}
        flexDirection="flex-row"
        padding="px-0"
        className="pl-2 mb-3"
        onCheck={e => {
          const {value} = e.target;
          setProcess(value);
          setWidgetData({...widgetData, review_process: value});
          setTrackChanges(true);
        }}
        radioValue={_process}
      />
      <div className="flex justify-between mb-4 items-center">
        <label data-smallerlabel="" className="relative mt-4 ">
          <p className="flex items-center text-sm font-medium gap-[2px]">
            Multiple device verification
          </p>
          <p className="text-xs text-body font-thin leading-[18px]">
            Disable multiple device verification screen
          </p>
        </label>
        <VerificationToggle
          isChecked={hideSmartphonePrompt}
          onCheck={value => {
            setWidgetData({
              ...widgetData,
              hide_smartphone_prompt: value,
            });
            setHideSmartphonePrompt(value);
            setTrackChanges(true);
            value && inputRef.current.focus();
          }}
        />
      </div>
      <div className="flex justify-between my-4 items-center">
        <label data-smallerlabel="" className="relative mt-4 ">
          <p className="flex items-center text-sm font-medium gap-[2px]">
            Check for Duplicate
          </p>
        </label>
        <VerificationToggle
          isChecked={duplicateCheck}
          onCheck={value => {
            setWidgetData({...widgetData, duplicate_check: value});
            setDuplicateCheck(value);
            setTrackChanges(true);
            value && inputRef.current.focus();
          }}
        />
      </div>
      <div className="h-[2px] w-full bg-white80 mb-1" />
      <label data-smallerlabel="" className="relative mt-5 ">
        <p className="flex items-center text-sm font-medium gap-[2px]">
          Notifications
        </p>
      </label>
      <div className="flex justify-between mt-3 items-center">
        <label data-smallerlabel="" className="relative  text-sm mb-2">
          Webhook Notification
        </label>
        <VerificationToggle
          isChecked={selectedWebhook}
          onCheck={value => {
            setWidgetData({
              ...widgetData,
              webhook: value,
            });
            setSelectedWebhook(value);
            setTrackChanges(true);
            value && inputRef.current.focus();
          }}
        />
      </div>
      <div className="flex justify-between mt-5 items-center">
        <label data-smallerlabel="" className="relative  text-sm mb-2">
          Send verification status to users
        </label>
        <VerificationToggle
          isChecked={selectedFeedback}
          onCheck={value => {
            setWidgetData({
              ...widgetData,
              direct_feedback: value,
            });
            setDirectFeedback(value);
            setTrackChanges(true);
            value && inputRef.current.focus();
          }}
        />
      </div>
      <div className="flex justify-between mt-5 items-center">
        <label data-smallerlabel="" className="relative  text-sm mb-2">
          Add Support Email
        </label>
        <VerificationToggle
          isChecked={supportEmail}
          onCheck={value => {
            setWidgetData({
              ...widgetData,
              ...(value
                ? {support_email: widgetData?.support_email}
                : {support_email: null}),
            });
            setSupportEmail(value);
            value && inputRef.current.focus();
          }}
        />
      </div>
      <>
        <div className=" justify-between mt-4 w-full">
          <input
            ref={inputRef}
            type="email"
            name="email_support"
            value={
              supportEmail
                ? widgetData?.support_email ||
                  singleWidget?.widget?.support_email
                : ''
            }
            placeholder={'support@domain.com'}
            onChange={e => {
              setWidgetData({...widgetData, support_email: e.target.value});
            }}
            disabled={!supportEmail}
            className={`${
              !supportEmail ? 'cursor-not-allowed' : 'focus:border-danger'
            }`}
          />
          <p
            className={`mt-2 text-xs ${
              !supportEmail ? 'text-grey5' : 'text-body'
            }`}
          >
            Add an email so your users can easily contact support
          </p>
        </div>
      </>
    </Accordion>
  );
}

export default connect(state => state, Actions)(Configuration);
