import React, {useCallback, useMemo} from 'react';
import {DashboardLayout, SecondaryButton} from '../../../components';
import {projectsMobileNav} from '../../../assets/images/images';
import {useMutation, useQuery} from 'react-query';
import {
  createSubscription,
  fetchSubscriptionInfo,
} from '../../../requests/queries/reconciliations';
import {useSelector} from 'react-redux';
import {billingFeatures} from './constants';
import {FeatureList, PlanCard} from './components/BillingComponents';
import {toast} from 'react-toastify';

function Billing() {
  const {userDetails} = useSelector(state => state?.auth);

  const {data: billingInfoData, isLoading: infoLoading} = useQuery(
    ['recon-billing', userDetails?.company?.id],
    () => fetchSubscriptionInfo(userDetails?.company?.id),
  );

  const billingInfo = useMemo(
    () => !infoLoading && billingInfoData && billingInfoData?.entity,
    [billingInfoData, infoLoading],
  );

  const billingMutation = useMutation(createSubscription);

  const handleBillingSubscription = useCallback(
    async plan => {
      if (!plan) return;

      try {
        await billingMutation.mutateAsync(
          {plan},
          {
            onSuccess: data => {
              console.log(data);
            },
          },
        );
      } catch (err) {
        toast.error(err.response.data.error);
      }
    },
    [billingMutation],
  );

  const billingPlans = [
    {
      title: 'Base tier',
      price: '$0 per month/ One account',
      isPrimary: false,
    },
    {
      title: 'Standard tier',
      price: '$0 per month/ One account',
      isPrimary: true,
      onChoose: () => handleBillingSubscription('standard'),
    },
    {
      title: 'Premium tier',
      price: '$0 per month/ One account',
      isPrimary: true,
      onChoose: () => handleBillingSubscription('premium'),
    },
    {
      title: 'Enterprise tier',
      price: '$0 per month/ One account',
      isPrimary: true,
      onChoose: () => handleBillingSubscription('enterprise'),
    },
  ];

  return (
    <DashboardLayout
      bg="bg-white80"
      topPadding="pt-8"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={projectsMobileNav} alt="Projects Mobile Navigation" />
          <p className="ml-2 -mb-1">Settings {'>'} Billing</p>
        </div>
      }
    >
      <p className="text-grey font-medium text-lg mt-10 lg:mt-0">Billing</p>

      <div className="mt-7 bg-white border border-[#EFF2F5] rounded-2xl py-9 px-6 flex flex-col lg:flex-row lg:items-center justify-between">
        <div className="flex flex-col">
          <p className="text-body font-medium text-sm">Current Plan</p>
          <h3 className="text-grey font-semibold text-xxl">
            {billingInfo?.plan === 'Basic'
              ? 'Base'
              : billingInfo?.plan || 'Base'}{' '}
            tier
          </h3>
        </div>

        <div className="flex items-center gap-2">
          {/*<PrimaryButton*/}
          {/*  buttonText="Change Plan"*/}
          {/*  className="text-xs !py-2 !px-8 rounded-lg"*/}
          {/*/>*/}
          {billingInfo?.billing_status && (
            <SecondaryButton
              buttonText="Cancel Subscription"
              className="text-xs !py-2 !px-3 h-fit rounded-lg font-inter text-error !outline-[#d7dde4]"
            />
          )}
        </div>
      </div>

      <div className="mt-6 flex flex-col lg:flex-row lg:items-center">
        <div className="flex flex-col pl-2 mr-9">
          <h3 className="font-semibold text-deepBlue text-[30px]">All Plans</h3>
          <p className="font-medium text-xs text-body">
            For all your reconciliation needs
          </p>
        </div>

        <div className="grid lg:grid-cols-4 grid-cols-1 gap-4 w-full mt-4 lg:mt-0">
          {billingPlans.map(plan => (
            <PlanCard
              key={plan?.title}
              title={plan.title}
              price={plan.price}
              onChoose={() => plan?.onChoose()}
              isPrimary={plan?.isPrimary}
            />
          ))}
        </div>
      </div>

      <div className="mt-2">
        <FeatureList features={billingFeatures} />
      </div>
    </DashboardLayout>
  );
}

export default Billing;
