/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  dropdownGrey,
  pendingCasesBreadcrumb,
} from '../../../assets/images/images';
import {
  DashboardLayout,
  EmptyState,
  Filter,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  // SearchInput,
  TableLayout,
} from '../../../components';
import * as Actions from '../../../store/actions';
import {
  filterOutEmptyValues,
  generateQueryParams,
  parseUrlParams,
} from '../../../helpers';

const defaultFilterOptions = [
  {
    title: 'Flow',
    name: 'flow',
    values: [],
  },
];

function PendingCases({
  getCases,
  getFlows,
  flows: {pending_cases, loading, flows},
}) {
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [load, setLoad] = useState(true);
  const [limit, setLimit] = useState(10);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    const apiParams = parseUrlParams();
    apiParams.limit = limit;
    apiParams.type = 'pending';

    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(parseInt(pageParam));
      apiParams.page = parseInt(pageParam);
    } else {
      apiParams.page = currentPage;
    }

    if (load) {
      getCases(apiParams);
      setLoad(false);
    } else {
      getFlows({
        page: currentPage,
        limit,
      });
    }

    // hack to check if there were filters in the url
    if (
      Object.keys(apiParams).length === 3 &&
      'limit' in apiParams &&
      'page' in apiParams &&
      'type' in apiParams
    ) {
      setFiltersApplied(false);
    } else {
      setFiltersApplied(true);
    }
  }, [currentPage, limit, load, getCases, getFlows]);

  const CASES = useMemo(
    () => pending_cases && pending_cases?.data,
    [pending_cases],
  );

  const flowOptions = flows?.flows?.map(f => ({
    label: f.name,
    value: f._id,
    name: f.name,
  }));

  const filterOptions = useMemo(() => {
    if (!flowOptions) {
      return defaultFilterOptions;
    }

    return [
      {
        title: 'Flow',
        name: 'flow',
        values: flowOptions,
      },
    ];
  }, [flowOptions]);

  const handleFilter = selectedOptions => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);

    if (selectedOptions['flow'] && selectedOptions['flow'].length > 1) {
      toast.error('You can only filter by one flow at a time.');
      return;
    }

    const queryParams = generateQueryParams(filteredOptions);

    setCurrentPage(1);
    navigate(`?${queryParams}&page=1`);

    getCases({
      page: 1,
      limit,
      type: 'pending',
      ...filteredOptions,
    });
    setOpenFilter(false);
    setFiltersApplied(true);
  };

  const handleResetFilter = () => {
    navigate('');
    setCurrentPage(1);
    getCases({
      page: 1,
      limit,
      type: 'pending',
    });
    setOpenFilter(true);
  };

  return (
    <DashboardLayout
      bg="bg-white"
      topPadding="pt-6"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={pendingCasesBreadcrumb} alt="" />
          <p className="ml-2 -mb-1">Pending cases</p>
        </div>
      }
      action={
        <Filter
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          handleFilter={handleFilter}
          resetUrl={handleResetFilter}
          filterOptions={filterOptions}
        />
      }
    >
      <>
        {loading ? (
          <Loader />
        ) : (CASES && CASES.length === 0) || CASES === null ? (
          filtersApplied ? (
            <EmptyState
              body="No results found"
              noBtn
              customBtn={
                <button
                  onClick={handleResetFilter}
                  className="text-brandBlue p-4 text-sm font-medium"
                >
                  Update preferences
                </button>
              }
            />
          ) : (
            <EmptyState body="No case(s) found" noBtn />
          )
        ) : (
          <>
            <div className="flex items-center justify-between w-full mb-6">
              <h2 className="text-grey text-base sm:text-lg font-medium">
                Cases
              </h2>
              <div className="flex items-center gap-4">
                {/*<SearchInput />*/}
                <FilterButton
                  openFilter={openFilter}
                  setOpenFilter={setOpenFilter}
                />
              </div>
            </div>
            <TableLayout negativeMargins>
              <thead className="text-xs font-semibold uppercase">
                <tr className="bg-white80">
                  {/* <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                      <div className="flex items-end gap-1">
                        <span>Name</span>
                        <img src={dropdownBlue} alt="" width={16} height={16} />
                      </div>
                    </th> */}
                  <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <div className="flex items-end gap-1">
                      <span>Use Case</span>
                      <img src={dropdownGrey} alt="" width={16} height={16} />
                    </div>
                  </th>
                  <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <div className="flex items-end gap-1">
                      <span>case id</span>
                      <img src={dropdownGrey} alt="" width={16} height={16} />
                    </div>
                  </th>
                  <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <div className="flex items-end gap-1">
                      <span>STATUS</span>
                      <img src={dropdownGrey} alt="" width={16} height={16} />
                    </div>
                  </th>
                  <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <div className="flex items-end gap-1">
                      <span>date created</span>
                      <img src={dropdownGrey} alt="" width={16} height={16} />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm bg-white">
                {CASES &&
                  CASES?.map((aCase, i) => (
                    <tr
                      className="border-b border-grey60 cursor-pointer "
                      key={i}
                      onClick={() =>
                        navigate(`/pending-cases/details/${aCase.id}`, {
                          state: {
                            status: aCase.status,
                            createdAt: moment(aCase?.created_at).format(
                              'Do MMM YYYY h:m a',
                            ),
                          },
                        })
                      }
                    >
                      {/* <td
                        // onClick={() => navigate(`/flows/analysis/${flow._id}`)}
                        className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap"
                      >
                        Jonathan Doremi
                      </td> */}
                      <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                        {aCase.use_case || '-'}
                      </td>
                      <td className="capitalize p-4 pl-0 text-tiny text-body whitespace-nowrap">
                        {`${aCase.id.toUpperCase()}` || '-'}
                      </td>
                      <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                        <div
                          className={`uppercase py-1 px-2 text-xs font-medium ${
                            aCase?.status?.toLowerCase() === 'pending'
                              ? 'text-white bg-brandBlue50'
                              : aCase?.status?.toLowerCase() === 'allowed'
                              ? 'text-status-200 bg-statusBg-200'
                              : 'text-status-500 bg-statusBg-500'
                          } rounded-full flex items-center w-fit`}
                        >
                          <span className="-mb-[2px]">
                            {aCase.status || '-'}
                          </span>
                        </div>
                      </td>
                      <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                        {moment(aCase?.created_at).format('Do MMM YYYY h:m a')}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </TableLayout>
          </>
        )}
      </>

      {!loading && (CASES !== null || CASES?.data?.length > 0) && (
        <div className="flex items-center justify-between flex-wrap gap-4 sm:gap-0 mt-8">
          <div className="flex items-center text-body text-tiny">
            <PageLimit
              onLimitChange={setLimit}
              total={CASES && CASES?.total_of_items}
              length={CASES?.data?.length}
              limit={limit}
            />
          </div>

          <div className="mb-8 sm:mb-0">
            <Pagination
              total={CASES && Math.ceil(CASES?.total_of_items / limit)}
              current={+currentPage}
              onPageChange={activePage => {
                pageClick(activePage);
                const searchParams = new URLSearchParams(
                  document.location.search,
                );
                searchParams.set('page', activePage);
                const newSearchParams = searchParams.toString();
                navigate(`?${newSearchParams}`);
              }}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(PendingCases);
