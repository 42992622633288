import React, {useMemo} from 'react';
import moment from 'moment';
import {useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';

import {Loader, Modal, PrimaryButton} from '../../../components';
import {closeModal, customerMail} from '../../../assets/images/images';
import {fetchTransaction} from '../../../requests/queries/reconciliations';
import {Constants, getInitials, getStatusColor} from '../../../helpers';
import {getReconColor} from '../../../helpers/getStatusColor';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';

function TransactionDetailsModal({show, onClose, transactionId, isCustomer}) {
  const navigate = useNavigate();

  const {data: transaction, isLoading} = useQuery(
    ['transaction-details', transactionId],
    () => fetchTransaction(transactionId),
    {
      enabled: !!transactionId,
    },
  );

  const transactionData = useMemo(
    () => !isLoading && transaction && transaction?.entity,
    [isLoading, transaction],
  );

  const accountDetails = [
    {
      label: 'Account Name',
      value: transactionData?.account?.account_name || '-',
    },
    {
      label: 'Bank Name',
      value: transactionData?.account?.bank || '-',
    },
    {
      label: 'Account Number',
      value: transactionData?.account?.account_number || '-',
    },
  ];

  const transactionDetails = [
    {
      label: 'Transaction Time',
      value: transactionData
        ? moment(transactionData?.transaction_time).format('DD MMM YYYY h:m')
        : '-',
    },
    {
      label: 'Transaction Amount',
      value: shortenLargeNumbers(
        transactionData?.transaction_amount,
        transactionData?.account?.currency,
        {startAt: 'M'},
      ),
    },
    {
      label: 'Currency',
      value: transactionData?.account?.currency || '-',
    },
    {
      label: 'Type',
      value: (
        <span
          className={`uppercase w-fit ${getStatusColor(
            transactionData?.transaction_type,
          )} h-5 flex items-center justify-center  font-medium text-xs rounded-full px-2 py-1`}
        >
          {transactionData?.transaction_type || '-'}
        </span>
      ),
    },
    {
      label: 'Status',
      value: (
        <span
          className={`uppercase w-fit ${getStatusColor(
            transactionData?.transaction_status,
          )} h-5 flex items-center justify-center  font-medium text-xs rounded-full px-2 py-1`}
        >
          {transactionData?.transaction_status || '-'}
        </span>
      ),
    },
    {
      label: 'Recon status',
      value: (
        <span
          className={`uppercase w-fit ${getReconColor(
            transactionData?.transaction_recon,
          )} h-5 flex items-center justify-center text-white  font-medium text-xs rounded-full px-2 py-1`}
        >
          {transactionData?.transaction_recon || '-'}
        </span>
      ),
    },
  ];

  return (
    <Modal
      show={show}
      onClose={onClose}
      extraModalClasses="max-w-[579px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center justify-between w-full">
          <h3 className="font-medium text-base text-grey">
            Transaction Details
          </h3>

          <button
            onClick={() => {
              onClose(false);
            }}
            className="hidden sm:block"
          >
            <img src={closeModal} alt="" />
          </button>
        </div>
        {isLoading ? (
          <Loader height={45} />
        ) : (
          <>
            {!isLoading && !transactionData?.customer ? null : (
              <div className="mt-6 bg-white80 rounded-xl flex items-center p-6 gap-4">
                <div className="rounded-full bg-white border border-[#ECECEC] w-[68px] h-[68px] flex items-center justify-center">
                  <p className="text-deepBlue text-md font-medium">
                    {getInitials(transactionData?.customer?.name || '-')}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <h1 className="text-deepBlue font-semibold text-lg">
                      {transactionData?.customer?.name || '-'}
                    </h1>
                    {transactionData?.customer_id && !isCustomer && (
                      <PrimaryButton
                        buttonText="View Customer"
                        className="rounded-lg h-[30px] flex items-center justify-center"
                        onClick={() =>
                          navigate(
                            Constants.PAGES_URL.RECONCILIATION_CUSTOMER_DETAILS.replace(
                              ':slug',
                              transactionData?.customer_id,
                            ),
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="flex items-center px-[10px] gap-1 mr-1 py-[6px] bg-white rounded-full text-deepBlue font-medium text-tiny">
                      <img src={customerMail} alt="mail" />
                      <p className="mt-[3px]">
                        {transactionData?.customer?.email || '-'}
                      </p>
                    </div>

                    <div className="flex items-center px-[10px] py-[6px] bg-white rounded-full text-deepBlue font-medium text-tiny">
                      <p>{transactionData?.customer?.phone || '-'}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/*<div className="mt-6">*/}
            {/*  <p className="text-base font-medium text-[#727272] text-left">*/}
            {/*    Receiver*/}
            {/*  </p>*/}
            {/*  <div className="mt-2 p-6 rounded-xl bg-white80">*/}
            {/*    {receiverDetails.map(({label, value}, index) => (*/}
            {/*      <div key={label}>*/}
            {/*        <div className="flex items-center justify-between">*/}
            {/*          <p className="text-tiny text-[#868C98]">{label}</p>*/}
            {/*          <p className="text-tiny text-black font-medium">*/}
            {/*            {value}*/}
            {/*          </p>*/}
            {/*        </div>*/}
            {/*        {index !== receiverDetails.length - 1 && (*/}
            {/*          <hr className="my-4 text-[#E1E4EA]" />*/}
            {/*        )}*/}
            {/*      </div>*/}
            {/*    ))}*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="mt-6">
              <p className="text-base font-medium text-[#727272] text-left">
                Account Details
              </p>
              <div className="mt-2 p-6 rounded-xl bg-white80 mb-10">
                {accountDetails?.map(({label, value}, index) => (
                  <div key={label}>
                    <div className="flex items-center justify-between">
                      <p className="text-tiny text-[#868C98]">{label}</p>
                      <p className="text-tiny text-black font-medium">
                        {value}
                      </p>
                    </div>
                    {index !== accountDetails?.length - 1 && (
                      <hr className="my-4 text-[#E1E4EA]" />
                    )}
                  </div>
                ))}
              </div>{' '}
              <p className="text-base font-medium text-[#727272] text-left">
                Transaction Details
              </p>
              <div className="mt-2 p-6 rounded-xl bg-white80">
                {transactionDetails?.map(({label, value}, index) => (
                  <div key={label}>
                    <div className="flex items-center justify-between">
                      <p className="text-tiny text-[#868C98]">{label}</p>
                      <p className="text-tiny text-black font-medium">
                        {value}
                      </p>
                    </div>
                    {index !== transactionDetails?.length - 1 && (
                      <hr className="my-4 text-[#E1E4EA]" />
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/*<div className="mt-6">*/}
            {/*  <p className="text-base font-medium text-[#727272] text-left">*/}
            {/*    Comments*/}
            {/*  </p>*/}

            {/*  <div className="mt-2 p-6 rounded-xl bg-white80 h-[167px]">*/}
            {/*    <div className="bg-white p-4 h-full">*/}
            {/*      <p className="text-black text-tiny font-medium text-left">*/}
            {/*        This should be the comment about the transaction*/}
            {/*      </p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<PrimaryButton*/}
            {/*  buttonText="Reconcile"*/}
            {/*  className="mt-6 w-full rounded-lg"*/}
            {/*  onClick={openReconcileTransaction}*/}
            {/*/>*/}
          </>
        )}
      </div>
    </Modal>
  );
}

export default TransactionDetailsModal;
