import React, {useCallback, useMemo} from 'react';
import DateFilter from './DateFilter';
import {
  applyDateFilter,
  formatCustomDateRange,
} from '../helpers/customDateFilters';
import OptionAccordion from './OptionAccordion';
import moment from 'moment';

const StyledLabel = ({children}) => (
  <label className="relative flex flex-row gap-2 font-normal transition duration-500 ease-in-out">
    {children}
  </label>
);

const timeOptions = [
  {value: 'today', label: 'Today'},
  {value: 'yesterday', label: 'Yesterday'},
  {value: 'seven_days', label: 'Last 7 days'},
  {value: 'last_month', label: 'Last Month'},
  {value: 'last_three_month', label: 'Last 3 Months'},
  {value: 'last_twelve_month', label: 'Last 12 Months'},
  {value: 'specificDateRange', label: 'Specific Date Range'},
];

const inputClassName =
  'w-[15px] h-[15px] rounded-sm focus-visible:outline-1 focus-visible:outline-brandBlue';

const DateAccordion = ({
  accordions,
  toggleAccordion,
  dateFilter,
  setDateFilter,
  selectedTimeOption,
  setSelectedTimeOption,
  hideDateFilter,
}) => {
  const handleTimeOptionChange = value => {
    setSelectedTimeOption(value);
    applyDateFilter({value, appFilters: dateFilter, setDateFilter});
  };

  const unFormatDate = inputDate =>
    moment(inputDate, 'YYYY-MM-DD HH:mm:ss').format('ddd MMM DD YYYY');

  const handleCustomDateRangeChange = useCallback(
    (start, end) => {
      const formattedDateRange = formatCustomDateRange(start, end);
      setDateFilter(formattedDateRange);
    },
    [setDateFilter],
  );
  const handleDateRangeChange = useCallback(
    (startDate, endDate) => {
      setDateFilter({startDate, endDate});
    },
    [setDateFilter],
  );

  const memoizedDateFilter = useMemo(
    () =>
      selectedTimeOption === 'specificDateRange' && (
        <DateFilter
          initialStartDate={
            dateFilter?.start ? unFormatDate(dateFilter.start) : ''
          }
          initialEndDate={dateFilter?.end ? unFormatDate(dateFilter.end) : ''}
          onDateRangeChange={handleDateRangeChange}
          onCustomDateRangeChange={handleCustomDateRangeChange}
        />
      ),
    [
      selectedTimeOption,
      dateFilter.start,
      dateFilter.end,
      handleDateRangeChange,
      handleCustomDateRangeChange,
    ],
  );

  return (
    <OptionAccordion
      title="Date"
      count={selectedTimeOption ? 1 : 0}
      isOpen={accordions?.date}
      onAccordionClick={() => toggleAccordion('date')}
      hide={hideDateFilter}
    >
      {timeOptions.map(({value, label}) => (
        <StyledLabel key={value}>
          <input
            className={inputClassName}
            type="radio"
            name="timeFilter"
            value={value}
            checked={selectedTimeOption === value}
            onChange={() => handleTimeOptionChange(value)}
          />
          {label}
        </StyledLabel>
      ))}
      {memoizedDateFilter}
    </OptionAccordion>
  );
};

export default DateAccordion;
