import React, {useCallback, useMemo, useState} from 'react';
import {
  DashboardLayout,
  EmptyState,
  Filter,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  SearchInput,
  TableLayout,
} from '../../../components';
import {baasMobile, corruptedFile, eye} from '../../../assets/images/images';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useQuery} from 'react-query';
import {fetchBAASTransactionPayouts} from '../../../requests/queries/baas';
import moment from 'moment/moment';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';
import {getStatusTextAndStyles} from '../../../helpers/getStatusTextAndStyles';
import {filterOutEmptyValues, generateQueryParams} from '../../../helpers';
import BaasPayoutModal from './BaasPayoutModal';
import {useBaasExport} from './hooks/useBAASExport';

const initialFilters = {
  start: '',
  end: '',
  flows: '',
};

function BaasPayouts() {
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);

  const [filters, setFilters] = useState(initialFilters);
  const [searchText, setSearchText] = useState('');
  const [transaction, setTransaction] = useState({});
  const [transactionDetails, setTransactionDetails] = useState(false);

  const {userDetails} = useSelector(state => state?.auth);
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });

  const toggleTransactionsModal = useCallback(transaction => {
    setTransactionDetails(prevState => !prevState);
    setTransaction(transaction);
  }, []);

  const params = useMemo(
    () => ({
      company_id: userDetails && userDetails?.company?.id,
      ...query,
      search: searchText,
      start_date: filters?.start,
      end_date: filters?.end,
    }),
    [filters?.end, filters?.start, query, searchText, userDetails],
  );

  const {handleExport, exportData} = useBaasExport({
    params,
    apiFunc: fetchBAASTransactionPayouts,
  });

  const {data: baas_payouts, isLoading} = useQuery(
    ['baas-payouts', {params}],
    () => fetchBAASTransactionPayouts({params}),
    {enabled: !exportData},
  );

  const payouts = useMemo(
    () => !isLoading && baas_payouts && baas_payouts?.entity,
    [baas_payouts, isLoading],
  );

  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };

  const handleFilter = (selectedOptions, shouldDownload = false) => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    const queryParams = generateQueryParams(filteredOptions);
    const {status} = filteredOptions;
    if (Array.isArray(status)) {
      filteredOptions.status = status.join(',');
    }
    let baseUrl = '';
    if (queryParams) {
      baseUrl += `?${queryParams}`;
    }

    if (shouldDownload) {
      filteredOptions.download = true;
      setFilters({
        ...filters,
        ...filteredOptions,
      });
    } else {
      navigate(baseUrl);
    }

    setFilters(filteredOptions);
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    navigate('');
    setFilters(initialFilters);
    setOpenFilter(true);
  };

  return (
    <DashboardLayout
      bg="bg-white"
      topPadding="pt-8"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={baasMobile} alt="" />
          <p className="ml-2 -mb-1">Payout</p>
        </div>
      }
    >
      <div className="flex items-start lg:items-center lg:flex-row flex-col gap-5 justify-between p-5 lg:p-0">
        <p className="text-grey font-medium text-[18px]">Payout</p>

        <div className="flex items-center gap-2">
          <SearchInput
            onChange={value => setSearchText(value)}
            className="sm:w-[277px] rounded-lg outline-[#eceff3]"
          />{' '}
          <FilterButton
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            className="h-[48px] justify-center w-[130px]"
          />
          <PrimaryButton
            buttonText="Export"
            className="w-[121px]"
            onClick={handleExport}
            loading={exportData}
          />
        </div>
      </div>

      <BaasPayoutModal
        show={transactionDetails}
        onClose={toggleTransactionsModal}
        transaction={transaction}
      />

      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        filterOptions={[]}
      />

      {isLoading ? (
        <Loader height={35} />
      ) : !isLoading && payouts && payouts?.settlements?.length === 0 ? (
        <EmptyState
          noBtn
          className="w-full"
          src={corruptedFile}
          body="Opps No Payouts history found this company"
          maxWidth="max-w-[500px]"
        />
      ) : (
        <div className="mt-8">
          <TableLayout negativeMargins className="mt-6 w-full">
            <thead className="text-small font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap w-[23%]">
                  date
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  recipient
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  amount
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  type
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  status
                </th>{' '}
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap"></th>
              </tr>
            </thead>
            <tbody className="text-sm text-body">
              {!isLoading &&
                payouts &&
                payouts?.settlements?.map((settlement, idx) => (
                  <tr
                    key={idx}
                    className="text-left border-b  bg-white cursor-pointer border-grey60"
                    onClick={() => toggleTransactionsModal(settlement)}
                  >
                    <td className="p-4 pl-12 whitespace-nowrap w-[23%]">
                      {(settlement?.date_updated &&
                        moment(settlement?.date_updated).format(
                          'DD MMM YYYY h:m',
                        )) ||
                        '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {settlement?.account_name || '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {shortenLargeNumbers(
                        Number(settlement?.transaction_amount) || 0,
                        'NGN',
                        {
                          startAt: 'M',
                        },
                      )}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      <span className="uppercase font-inter w-fit rounded-full px-2 py-1 h-5 flex items-center justify-center text-dojahBlue bg-[#EEF3FC] text-xs">
                        manual
                      </span>
                    </td>

                    <td className="p-4 pl-0 whitespace-nowrap">
                      <span
                        className={` ${
                          getStatusTextAndStyles(
                            settlement?.transaction_status?.toLowerCase(),
                          )?.styles
                        } uppercase w-fit rounded-full px-2 py-1 h-5 font-inter flex items-center justify-center  text-xs`}
                      >
                        {
                          getStatusTextAndStyles(
                            settlement?.transaction_status?.toLowerCase(),
                          )?.text
                        }
                      </span>
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      <button>
                        <img src={eye} alt="" />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </TableLayout>
        </div>
      )}

      {!isLoading && payouts?.settlements?.length > 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-12 sm:gap-0 pl-12 pr-6">
          <PageLimit
            onLimitChange={limit => {
              setQuery(prevState => ({
                ...prevState,
                limit,
                page: 1,
              }));
            }}
            limit={query?.limit}
            total={payouts?.current_page}
            length={payouts?.settlements?.length}
          />

          <Pagination
            total={payouts?.total_pages}
            current={Number(payouts?.current_page)}
            onPageChange={activePage => {
              pageClick(activePage);
              const searchParams = new URLSearchParams(
                document.location.search,
              );
              searchParams.set('page', activePage);
              const newSearchParams = searchParams.toString();
              navigate(`?${newSearchParams}`);
            }}
          />
        </div>
      )}
    </DashboardLayout>
  );
}

export default BaasPayouts;
