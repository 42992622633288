import React, {useMemo, useState} from 'react';
import moment from 'moment';
import {useQuery} from 'react-query';
import {
  EmptyState,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
} from '../../../../components';
import {
  corruptedFile,
  edit,
  eye,
  // overviewCustomers,
  projectsMobileNav,
} from '../../../../assets/images/images';
import ReconciliationLayout from '../ReconciliationLayout';
import {Constants} from '../../../../helpers';
import {useNavigate, useParams} from 'react-router-dom';
import AddAccountModal from './AddAccountModal';
import ManuallyAddAccount from './ManuallyAddAccount';
import AutomaticallyAddBankModal from './AutomaticallyAddBankModal';
import AutomaticallyAddAccountModal from './AutomaticallyAddAccountModal';
import {fetchAccounts} from '../../../../requests/queries/reconciliations';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';
import ReconChart from './ReconChart';

function ProjectAccounts() {
  const {slug} = useParams();
  const navigate = useNavigate();
  const [isAddAccount, setIsAddAccount] = useState(false);
  const [selectedBank, setSelectedBank] = useState(false);
  const [accountData, setAccountData] = useState({});
  const [isManuallyAddAccount, setIsManuallyAddAccount] = useState(false);

  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });

  const cleanSlug = slug && slug !== 'add-project' ? slug : '';

  const params = useMemo(
    () => ({
      project_id: cleanSlug,
    }),
    [cleanSlug],
  );

  const {data: accounts, isLoading} = useQuery(
    ['all-accounts', {params}],
    () => fetchAccounts({params}),
    {
      retry: 1,
      enabled: !!cleanSlug,
    },
  );

  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };
  const [automaticallyAddBank, setAutomaticallyAddBank] = useState(false);
  const [automaticallyAddAccount, setAutomaticallyAddAccount] = useState(false);

  const openAddAccountModal = () => {
    setIsManuallyAddAccount(false);
    setAutomaticallyAddBank(false);
    setIsAddAccount(true);
  };

  const closeAddAccountModal = () => {
    setIsAddAccount(false);
  };

  const openManuallyAddAccountModal = () => {
    closeAddAccountModal();
    setIsManuallyAddAccount(true);
  };

  const closeManuallyAddAccountModal = () => {
    setIsManuallyAddAccount(false);
  };

  const openAutomaticallyAddBankModal = () => {
    closeAddAccountModal();
    setAutomaticallyAddBank(true);
  };

  const closeAutomaticallyAddBankModal = () => {
    setAutomaticallyAddBank(false);
  };

  const openAutomaticallyAddAccountModal = () => {
    closeAutomaticallyAddBankModal(true);
    setAutomaticallyAddAccount(true);
  };

  const closeAutomaticallyAddAccountModal = () => {
    setAutomaticallyAddAccount(false);
  };

  // const flows = [
  //   {
  //     icon: overviewCustomers,
  //     alt: 'customers',
  //     title: 'No. of Customers',
  //     value: shortenLargeNumbers(0, null, {decimals: 0}),
  //   },
  // ];

  return (
    <ReconciliationLayout
      bg="bg-white80"
      pageTitle="Project A"
      pageIcon={projectsMobileNav}
      action={
        <div className="items-center hidden gap-4 sm:flex">
          <PrimaryButton
            buttonText="Add Account"
            className="py-[14px] px-[44px] rounded-lg"
            onClick={openAddAccountModal}
            disabled={!cleanSlug}
          />{' '}
        </div>
      }
    >
      <AddAccountModal
        show={isAddAccount}
        onClose={closeAddAccountModal}
        openManuallyAddAccountModal={openManuallyAddAccountModal}
        openAutomaticallyAddBankModal={openAutomaticallyAddBankModal}
      />

      <ManuallyAddAccount
        show={isManuallyAddAccount}
        onClose={closeManuallyAddAccountModal}
        goBack={openAddAccountModal}
        accountData={accountData}
      />

      <AutomaticallyAddBankModal
        show={automaticallyAddBank}
        onClose={closeAutomaticallyAddBankModal}
        goBack={openAddAccountModal}
        openAutomaticallyAddAccountModal={openAutomaticallyAddAccountModal}
        setSelectedBank={setSelectedBank}
      />

      <AutomaticallyAddAccountModal
        show={automaticallyAddAccount}
        onClose={closeAutomaticallyAddAccountModal}
        selectedBank={selectedBank}
        goBack={() => setAutomaticallyAddBank(!automaticallyAddBank)}
      />

      {isLoading ? (
        <Loader height={45} />
      ) : !isLoading && (!accounts || accounts?.rows?.length === 0) ? (
        <EmptyState
          noBtn
          src={corruptedFile}
          className="!h-[calc(100vh-200px)]"
          maxWidth="max-w-[289px]"
          body="Add an account to see more details of the account"
          customBtn={
            <PrimaryButton
              buttonText="Add Account"
              className="py-[14px] px-[47px] rounded-lg mt-6"
              onClick={openAddAccountModal}
              disabled={!cleanSlug}
            />
          }
        />
      ) : (
        <>
          <div className="pl-11 pr-8 py-3">
            {/*<OverviewCards cardData={flows} />*/}

            <ReconChart />
          </div>
          <TableLayout className="w-full mt-6 bg-white">
            <thead className="text-small font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <span className="flex items-center">account name</span>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <span className="flex items-center">bank name</span>
                </th>

                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <span className="flex items-center">account number</span>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <span className="flex items-center">date created</span>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <span className="flex items-center">current balance</span>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <span className="flex items-center">connect mode</span>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <span className="flex items-center">last sync date</span>
                </th>
                <th className="w-[4.31%]" />
                <th className="w-[4.31%]" />
              </tr>
            </thead>
            <tbody className="text-sm text-body">
              {accounts &&
                accounts?.rows?.map(account => (
                  <tr
                    key={account?.id}
                    className="text-left border-b cursor-pointer border-grey60"
                  >
                    <td className="p-4 pl-12 whitespace-nowrap">
                      {account?.account_name || '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {account?.bank || '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {account?.account_number || '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {moment(account?.createdAt).format('Do MMM YYYY h:m a')}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {shortenLargeNumbers(
                        account?.account_balance,
                        account?.currency || null,
                        {startAt: 'M'},
                      )}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {account?.account_connect_mode || '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {(account?.last_sync_date &&
                        moment(account?.last_sync_date).format(
                          'Do MMM YYYY h:m a',
                        )) ||
                        '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      <button
                        onClick={() => {
                          navigate(
                            Constants.PAGES_URL.PROJECT_ACCOUNTS_DETAILS.replace(
                              ':slug',
                              account?.id,
                            ),
                          );
                        }}
                        className="cursor-pointer"
                      >
                        <img src={eye} alt="" width={16} height={16} />
                      </button>
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      <button
                        onClick={() => {
                          openManuallyAddAccountModal();
                          setAccountData(account);
                        }}
                        className="cursor-pointer"
                      >
                        <img src={edit} alt="" width={16} height={16} />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </TableLayout>
        </>
      )}

      {!isLoading && accounts?.rows?.length > 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-12 sm:gap-0 pl-12 pr-6">
          <PageLimit
            onLimitChange={limit => {
              setQuery(prevState => ({
                ...prevState,
                limit,
                page: 1,
              }));
            }}
            limit={query?.limit}
            total={accounts?.count}
            length={accounts?.rows?.length}
          />

          <Pagination
            total={accounts?.pages}
            current={Number(accounts?.page)}
            onPageChange={activePage => {
              pageClick(activePage);
              const searchParams = new URLSearchParams(
                document.location.search,
              );
              searchParams.set('page', activePage);
              const newSearchParams = searchParams.toString();
              navigate(`?${newSearchParams}`);
            }}
          />
        </div>
      )}
    </ReconciliationLayout>
  );
}

export default ProjectAccounts;
