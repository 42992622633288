import React, {useMemo, useState} from 'react';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import ReconciliationLayout from '../ReconciliationLayout';
import {
  projectsMobileNav,
  overviewCashflow,
  feesCharged,
  overviewInflow,
  overviewOutflow,
  corruptedFile,
} from '../../../../assets/images/images';
import {
  EmptyState,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
} from '../../../../components';
import UploadTransactionsModal from './UploadTransactionsModal';
import ManualTransactionModal from './ManualTransactionModal';
import TransactionDetailsModal from '../TransactionDetailsModal';
import ReconcileTransactionModal from '../ReconcileTransactionModal';
import {TransactionTabs} from '../TransactionTabs';
import {getStatusColor} from '../../../../helpers';
import ExternalTransactionModal from './ExternalTransactionModal';
import ExternalTransactionUploadOptions from '../ExternalTransactionUploadOptions';
import {
  useExternalOverview,
  useOverview,
  useTransactions,
} from '../hooks/useReconQueries';
import {getReconColor} from '../../../../helpers/getStatusColor';
import {OverviewCards} from '../components/OverviewCards';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';
import {ReconFilters} from '../components/ReconFilters';
import {initialFilters} from '../constants';
import {useAccounts} from '../hooks/useAccounts';

function ProjectTransactions() {
  const {slug} = useParams();
  const [filters, setFilters] = useState(initialFilters);
  const [fileType, setFileType] = useState('');
  const [tabType, setTabType] = useState('internal');
  const [openFilter, setOpenFilter] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [openExternal, setOpenExternal] = useState(false);
  const [externalTransaction, setExternalTransaction] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(false);
  const [reconcileTransaction, setReconcileTransaction] = useState(false);
  const [isUploadTransactions, setIsUploadTransactions] = useState(false);
  const {accountData, isLoading: accountsLoading} = useAccounts({slug});
  const cleanSlug = slug && slug !== 'add-project' ? slug : '';

  const [createManualTransactions, setCreateManualTransactions] =
    useState(false);
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });

  const {tableData, isLoading, count} = useTransactions({
    entity_id: 'project_id',
    id: cleanSlug,
    tabType,
    ...query,
    date_from: filters?.date_from,
    date_to: filters?.date_to,
    account_id: filters?.account?.toString(),
  });

  const {
    overviewLoading,
    inFlow: internalInFlow,
    outFlow: internalOutFlow,
  } = useOverview({
    entity_id: 'project_id',
    id: cleanSlug,
    date_from: filters?.date_from,
    date_to: filters?.date_to,
    account_id: filters?.account?.toString(),
  });

  const {externalOverviewLoading, externalInFlow, externalOutFlow} =
    useExternalOverview({
      entity_id: 'project_id',
      id: cleanSlug,
      date_from: filters?.date_from,
      date_to: filters?.date_to,
      account_id: filters?.account?.toString(),
      tabType,
    });

  const inFlow = useMemo(
    () =>
      !overviewLoading && !externalOverviewLoading && tabType === 'internal'
        ? internalInFlow
        : externalInFlow,
    [
      externalInFlow,
      externalOverviewLoading,
      internalInFlow,
      overviewLoading,
      tabType,
    ],
  );
  const outFlow = useMemo(
    () =>
      !overviewLoading && !externalOverviewLoading && tabType === 'internal'
        ? internalOutFlow
        : externalOutFlow,
    [
      externalOutFlow,
      externalOverviewLoading,
      internalOutFlow,
      overviewLoading,
      tabType,
    ],
  );

  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };

  const openReconcileTransaction = () => {
    closeTransactionDetails();
    setReconcileTransaction(true);
  };

  const closeReconcileTransaction = () => {
    setReconcileTransaction(false);
  };

  const openTransactionDetails = () => {
    setTransactionDetails(true);
  };

  const closeTransactionDetails = () => {
    setTransactionDetails(false);
  };

  const openUploadTransactionsModal = () => {
    setIsUploadTransactions(true);
  };

  const closeUploadTransactionsModal = () => {
    setIsUploadTransactions(false);
  };

  const openCreateManualTransactionsModal = () => {
    setCreateManualTransactions(true);
    closeUploadTransactionsModal();
  };

  const closeCreateManualTransactionsModal = () => {
    setCreateManualTransactions(false);
  };

  const flows = [
    {
      icon: overviewCashflow,
      alt: 'net-cash flow',
      title: 'Net Cash Flow',
      value: shortenLargeNumbers(inFlow?.amount - outFlow?.amount, true, {
        startAt: 'M',
      }),
      isNegative: inFlow?.amount - outFlow?.amount < 0,
    },
    {
      icon: overviewInflow,
      alt: 'in-flow',
      title: 'Total Inflow',
      count: inFlow?.count || '0',
      value: shortenLargeNumbers(inFlow?.amount, true, {startAt: 'M'}),
    },
    {
      icon: overviewOutflow,
      alt: 'out-flow',
      title: 'Total Outflow',
      count: outFlow?.count || '0',
      value: shortenLargeNumbers(outFlow?.amount, true, {startAt: 'M'}),
    },
    {
      icon: feesCharged,
      alt: 'fees',
      title: 'Total Fees Charged',
      value: shortenLargeNumbers(outFlow?.fee_amount, true, {startAt: 'M'}),
    },
  ];

  return (
    <ReconciliationLayout
      pageIcon={projectsMobileNav}
      action={
        <div className="items-center hidden gap-4 sm:flex">
          <FilterButton openFilter={openFilter} setOpenFilter={setOpenFilter} />
          <PrimaryButton
            buttonText="Add Transactions"
            className="rounded-lg"
            onClick={openUploadTransactionsModal}
            disabled={!cleanSlug}
          />{' '}
        </div>
      }
    >
      <ReconFilters
        filters={filters}
        setFilters={setFilters}
        limit={query?.limit}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        setQuery={setQuery}
        query={query}
        initialFilters={initialFilters}
        slug={cleanSlug}
      />
      <UploadTransactionsModal
        show={isUploadTransactions}
        onClose={closeUploadTransactionsModal}
        openCreateManualTransactionsModal={openCreateManualTransactionsModal}
        openExternal={() => {
          closeUploadTransactionsModal();
          setExternalTransaction(!externalTransaction);
        }}
      />

      <ExternalTransactionUploadOptions
        show={externalTransaction}
        onClose={() => setExternalTransaction(!externalTransaction)}
        openUploadModal={() => {
          setExternalTransaction(!externalTransaction);
          setOpenExternal(!openExternal);
        }}
        setFileType={setFileType}
        goBack={openUploadTransactionsModal}
      />

      <ManualTransactionModal
        show={createManualTransactions}
        onClose={closeCreateManualTransactionsModal}
        goBack={openUploadTransactionsModal}
      />

      <TransactionDetailsModal
        show={transactionDetails}
        onClose={closeTransactionDetails}
        openReconcileTransaction={openReconcileTransaction}
        transactionId={transactionId}
      />

      <ReconcileTransactionModal
        show={reconcileTransaction}
        onClose={closeReconcileTransaction}
      />

      <ExternalTransactionModal
        fileType={fileType}
        show={openExternal}
        onClose={setOpenExternal}
        goBack={() => setExternalTransaction(!externalTransaction)}
      />
      <div className="w-full flex justify-end pr-8">
        <TransactionTabs
          setTabType={setTabType}
          tabType={tabType}
          query={query}
          setQuery={setQuery}
          count={count}
        />
      </div>

      {(!isLoading || !accountsLoading) &&
      accountData?.length === 0 &&
      tableData?.rows?.length === 0 ? (
        <EmptyState
          noBtn
          src={corruptedFile}
          className="!h-[calc(80vh-200px)]"
          maxWidth="max-w-[289px]"
          body={`No ${tabType} transaction(s) was found for this project.`}
          customBtn={
            <PrimaryButton
              buttonText="Add Transactions"
              className="py-[14px] px-[47px] rounded-lg mt-6"
              onClick={openUploadTransactionsModal}
            />
          }
        />
      ) : (
        <div>
          <div className="pl-11 pr-8">
            <OverviewCards
              className="flex items-center lg:justify-center xl:justify-between xl:gap-0 gap-4 my-10 flex-wrap bg-[#F7F7F7] p-3 "
              overviewLoading={overviewLoading || externalOverviewLoading}
              cardData={flows}
            />
          </div>

          {isLoading ? (
            <Loader height={35} />
          ) : (
            <TableLayout className="w-full mt-6">
              <thead className="text-small font-semibold uppercase">
                <tr className="bg-white80">
                  <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <span className="flex items-center">account name</span>
                  </th>
                  <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <span className="flex items-center">transaction date</span>
                  </th>
                  <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <span className="flex items-center">ref id</span>
                  </th>
                  <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <span className="flex items-center">amount</span>
                  </th>
                  <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <span className="flex items-center">type</span>
                  </th>
                  <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <span className="flex items-center">status</span>
                  </th>
                  <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                    <span className="flex items-center">recon status</span>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm text-body bg-white">
                {!isLoading &&
                  tableData &&
                  tableData?.rows?.map(transaction => (
                    <tr
                      key={transaction?.id}
                      className={`text-left border-b ${
                        tabType === 'internal' && 'cursor-pointer'
                      } border-grey60`}
                      onClick={() => {
                        if (tabType !== 'internal') return;
                        setTransactionId(transaction?.id);
                        openTransactionDetails(true);
                      }}
                    >
                      <td className="p-4 pl-12 whitespace-nowrap">
                        {transaction?.account?.account_name || '-'}
                      </td>
                      <td className="p-4 pl-12 whitespace-nowrap">
                        {moment(transaction?.transaction_time).format(
                          'Do MMM YYYY h:m a',
                        ) || '-'}
                      </td>
                      <td className="p-4 pl-0 whitespace-nowrap">
                        {transaction?.transaction_ref || '-'}
                      </td>
                      <td className="p-4 pl-0 whitespace-nowrap">
                        {shortenLargeNumbers(
                          transaction?.transaction_amount,
                          transaction?.account?.currency,
                          {startAt: 'M'},
                        )}
                      </td>
                      <td className="p-4 pl-0 whitespace-nowrap">
                        <span
                          className={`uppercase w-fit ${getStatusColor(
                            transaction?.transaction_type,
                          )} h-5 flex items-center justify-center  font-medium text-xs rounded-full px-2 py-1`}
                        >
                          {transaction?.transaction_type || '-'}
                        </span>
                      </td>
                      <td className="p-4 pl-0 whitespace-nowrap">
                        <span className="uppercase w-fit rounded-full px-2 py-1 h-5 flex items-center justify-center text-white bg-success text-xs">
                          {transaction?.transaction_status || '-'}
                        </span>
                      </td>
                      <td className="p-4 pl-0 whitespace-nowrap">
                        <span
                          className={`${getReconColor(
                            transaction?.transaction_recon,
                          )} uppercase w-fit rounded-full px-2 py-1 h-5 flex items-center justify-center text-white text-xs`}
                        >
                          {transaction?.transaction_recon || '-'}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </TableLayout>
          )}
          {!isLoading && tableData?.rows?.length > 0 && (
            <div className="flex flex-wrap items-center justify-between gap-4 mt-12 sm:gap-0 pl-12 pr-6">
              <PageLimit
                onLimitChange={limit => {
                  setQuery(prevState => ({
                    ...prevState,
                    limit,
                    page: 1,
                  }));
                }}
                limit={query?.limit}
                total={tableData?.page}
                length={tableData?.rows?.length}
              />

              <Pagination
                total={tableData?.pages}
                current={Number(tableData?.page)}
                onPageChange={activePage => {
                  pageClick(activePage);
                  const searchParams = new URLSearchParams(
                    document.location.search,
                  );
                  searchParams.set('page', activePage);
                  // const newSearchParams = searchParams.toString();
                  // navigate(`?${newSearchParams}`);
                }}
              />
            </div>
          )}
        </div>
      )}
    </ReconciliationLayout>
  );
}

export default ProjectTransactions;
