import React from 'react';
import {SkeletonLoader} from '../../../../components';

export const OverviewCards = ({
  cardData,
  overviewLoading,
  className,
  innerClassName,
  loaderWidth,
}) => (
  <div
    className={
      className ||
      'flex items-center justify-center xl:justify-between xl:gap-0 gap-4 mt-4 flex-wrap mb-12'
    }
  >
    {cardData.map(({icon, alt, title, value, count, isNegative}) => (
      <div
        key={title}
        className={
          innerClassName ||
          'bg-white relative py-6 pl-6 pr-2 rounded-lg flex items-center gap-2 min-w-[270px] w-[24%]'
        }
      >
        <img src={icon} alt={alt} />
        <div className="flex flex-col gap-1">
          <p className="text-sm text-body">{title}</p>

          {overviewLoading ? (
            <SkeletonLoader
              style={{width: loaderWidth || 150, height: 25, marginBottom: 10}}
            />
          ) : (
            <h1
              className={`text-md font-semibold ${
                isNegative ? 'text-status-404' : 'text-grey'
              }`}
            >
              {value}
            </h1>
          )}
          {count && (
            <p className="font-medium text-xs absolute right-[16px] bottom-[8px]">
              Count: <span>{count}</span>
            </p>
          )}
        </div>
      </div>
    ))}
  </div>
);
