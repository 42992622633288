import React from 'react';
import {calendar, dropdownGrey} from '../assets/images/images';

export default function OptionAccordion({
  title,
  count,
  isOpen,
  onAccordionClick,
  children,
  hide = false,
}) {
  return (
    <div
      className={`flex flex-col px-6 py-4 bg-white80 -mx-6 ${
        hide ? 'hidden' : ''
      }`}
    >
      <div
        className={`${
          count > 0 ? 'bg-brandBlue text-white' : 'bg-white text-body'
        } flex py-[11px] px-4  items-center  justify-between uppercase text-xs font-medium rounded cursor-pointer transition ease-in-out duration-300`}
        onClick={onAccordionClick}
      >
        <span>
          {title === 'All Time' ? 'All Time' : `by ${title?.replace('_', ' ')}`}
        </span>
        {count > 0 ? (
          <span className="flex items-center justify-center text-xs text-brandBlue w-[18px] h-[18px] bg-white rounded-full">
            {count}
          </span>
        ) : title === 'All Time' ? (
          <img src={calendar} alt="" width={16} height={16} />
        ) : (
          <img
            src={dropdownGrey}
            alt=""
            className={`${
              isOpen ? 'rotate-180 ease-in' : 'rotate-0 ease-out'
            } duration-200 transition`}
            width={16}
            height={16}
          />
        )}
      </div>
      {isOpen && (
        <div className="bg-white relative flex flex-col gap-4 p-4 animate-dropdown max-h-80 overflow-auto ">
          {children}
        </div>
      )}
    </div>
  );
}
