const getStatusTextAndStyles = status =>
  ({
    successful: {
      styles: 'bg-success20 text-success',
      text: 'Successful',
      solid: 'text-white bg-success',
    },
    completed: {
      styles: 'bg-success20 text-success',
      text: 'Successful',
      solid: 'text-white bg-success',
    },
    pending: {
      styles: 'bg-[#FFD9512B] text-[#E4B303]',
      text: 'Pending',
      solid: 'text-white bg-warning',
    },
    expired: {styles: 'bg-[#6507082B] text-[#650708]', text: 'Expired'},
    failed: {
      styles: 'bg-[#A13E3F2B] text-status-404',
      text: 'Failed',
      solid: 'text-white bg-danger',
    },
    started: {styles: 'bg-batchBlue20 text-batchBlue', text: 'Created'},
    abandoned: {styles: 'bg-disabled', text: 'Abandoned'},
    ongoing: {styles: 'bg-[#FF681C2B] text-[#FF681C]', text: 'Ongoing'},
  }[status?.toLowerCase()] || {
    styles: 'bg-grey4',
    text: 'Pending',
    solid: 'bg-grey4 text-black',
  });
const getStatusWithNumbers = number =>
  ({
    1: 'pending',
    2: 'successful',
    3: 'failed',
  }[number] || 'pending');

export {getStatusTextAndStyles, getStatusWithNumbers};
