import React, {useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  EmptyState,
  FilterButton,
  Loader,
  PrimaryButton,
} from '../../../../components';
import {
  corruptedFile,
  failed,
  overviewCashflow,
  overviewCustomers,
  overviewInflow,
  overviewOutflow,
  projectsMobileNav,
  successful,
} from '../../../../assets/images/images';
import ReconciliationLayout from '../ReconciliationLayout';
import {useAnalysisData} from '../hooks/useAnalysisData';
import {convertToPercentage} from '../../../../helpers/convertToPercentage';
import AccountDetailsPieChart from '../Projects/AccountDetailsPieChart';
import {LineChart} from '../components/LineChart';
import {Period} from '../components/Period';
import {OverviewCards} from '../components/OverviewCards';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';
import {useTransactions} from '../hooks/useReconQueries';
import {ReconFilters} from '../components/ReconFilters';
import AddProjectsModal from '../Projects/AddProjectsModal';
import {initialFilters} from '../constants';
import {allObjectValuesFalsy} from '../../../../helpers';
import {useAccounts} from '../hooks/useAccounts';

function ProjectOverview() {
  const {slug} = useParams();
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [date, setDate] = useState('month');
  const [isAddProject, setIsAddProject] = useState(false);
  const {accountData, isLoading: accountsLoading} = useAccounts({slug});
  const [query, setQuery] = useState({
    page: 1,
    limit: 5,
  });
  const {tableData, isLoading} = useTransactions({
    entity_id: 'project_id',
    id: slug,
    tabType: 'internal',
  });

  const projectId = slug !== 'add-project' ? slug : '';
  const {
    overview: {overviewLoading, inFlow, outFlow},
    chartAnalysis: {analysisChart, chartLoading},
    reconAnalysis: {reconLoading, totalRecon, getCount},
  } = useAnalysisData({
    id: projectId,
    date,
    entity_id: 'project_id',
    ...filters,
  });

  const isOverviewDataLoading = useMemo(
    () => overviewLoading && chartLoading && reconLoading && accountsLoading,
    [accountsLoading, chartLoading, overviewLoading, reconLoading],
  );

  const isEmptyOverview = useMemo(
    () =>
      !isOverviewDataLoading &&
      allObjectValuesFalsy({
        inFlow,
        outFlow,
        analysisChart,
        totalRecon,
        accountData,
      }),
    [
      accountData,
      analysisChart,
      inFlow,
      isOverviewDataLoading,
      outFlow,
      totalRecon,
    ],
  );

  const queryParams = new URLSearchParams(window.location.search);
  const flows = [
    {
      icon: overviewCashflow,
      alt: 'net-cash flow',
      title: 'Net CashFlow',
      count: inFlow?.count - outFlow?.count || '0',
      value: shortenLargeNumbers(inFlow?.amount - outFlow?.amount, true, {
        startAt: 'M',
      }),
    },
    {
      icon: overviewInflow,
      alt: 'in-flow',
      title: 'Total Inflow',
      count: inFlow?.count || '0',
      value: shortenLargeNumbers(inFlow?.amount, true, {startAt: 'M'}),
    },
    {
      icon: overviewOutflow,
      alt: 'out-flow',
      title: 'Total Outflow',
      count: outFlow?.count || '0',
      value: shortenLargeNumbers(outFlow?.amount, true, {startAt: 'M'}),
    },
    {
      icon: overviewCustomers,
      alt: 'customers',
      title: 'No. of Customers',
      value: shortenLargeNumbers(0, null, {decimals: 0}),
    },
  ];
  const accountDetails = [
    {
      title: 'Current Balance',
      value: shortenLargeNumbers(0, true),
      textColor: 'text-deepBlue300',
      valueColor: 'text-deepBlue',
    },
    {
      title: 'Average Closing Balance',
      value: shortenLargeNumbers(0, true),
      textColor: 'text-deepBlue300',
      valueColor: 'text-deepBlue',
    },
    {
      title: 'Total No. of Transactions',
      value: isLoading
        ? 0
        : shortenLargeNumbers(tableData?.count, null, {decimals: 0}),
      textColor: 'text-deepBlue300',
      valueColor: 'text-deepBlue',
    },
    {
      title: 'Total Fees Charged',
      value: shortenLargeNumbers(
        outFlow?.fee_amount + inFlow?.fee_amount,
        true,
        {startAt: 'M'},
      ),
      textColor: 'text-error-300',
      valueColor: 'text-error',
    },
  ];

  return (
    <ReconciliationLayout
      bg="bg-white80"
      pageTitle="Project A"
      pageIcon={projectsMobileNav}
      action={
        <div className="items-center hidden gap-4 sm:flex">
          <FilterButton openFilter={openFilter} setOpenFilter={setOpenFilter} />
        </div>
      }
    >
      <ReconFilters
        filters={filters}
        setFilters={setFilters}
        limit={query?.limit}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        setQuery={setQuery}
        query={query}
        initialFilters={initialFilters}
        slug={slug}
      />
      <AddProjectsModal
        show={isAddProject}
        onClose={() => setIsAddProject(prev => !prev)}
      />

      {isOverviewDataLoading ? (
        <Loader height={56} />
      ) : isEmptyOverview ? (
        <EmptyState
          noBtn
          src={corruptedFile}
          className="!h-[calc(100vh-200px)]"
          maxWidth="max-w-[289px]"
          body="Create a project to determine the type of transaction tied to an account"
          customBtn={
            <PrimaryButton
              buttonText="Add Project"
              className="py-[14px] px-[47px] rounded-lg mt-6"
              onClick={() => setIsAddProject(prev => !prev)}
            />
          }
        />
      ) : (
        <div className="mt-10 pl-11 pr-8">
          <OverviewCards overviewLoading={overviewLoading} cardData={flows} />

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-6">
            <div className="p-6 bg-white rounded-xl flex flex-col gap-6 items-start">
              {accountDetails.map(({title, value, textColor, valueColor}) => (
                <div key={title} className="flex flex-col gap-1 items-start">
                  <p className={`text-small ${textColor} font-inter`}>
                    {title}
                  </p>
                  <h1 className={`${valueColor} font-semibold text-xxl`}>
                    {value}
                  </h1>
                </div>
              ))}
            </div>

            <div className="p-6 bg-white rounded-xl">
              <h3 className="text-grey text-lg font-medium mb-6">
                Reconciliation
              </h3>

              {reconLoading ? (
                <Loader height={22} />
              ) : (
                <div className="flex flex-col lg:flex-row items-center gap-6">
                  <AccountDetailsPieChart
                    totalRecon={totalRecon}
                    recon={getCount('reconciled')?.count}
                    unrecon={getCount('unreconciled')?.count}
                  />

                  <div className="flex flex-col gap-8 right-10">
                    <div className="flex items-center gap-2">
                      <img src={successful} alt="" width={40} height={40} />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs font-medium uppercase text-body">
                          Reconciled
                        </p>
                        <p className="text-xl font-medium text-grey">
                          {convertToPercentage(
                            getCount('reconciled')?.count,
                            totalRecon,
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <img src={failed} alt="" width={40} height={40} />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs font-medium uppercase text-body">
                          Unreconciled
                        </p>
                        <p className="text-xl font-medium text-grey">
                          {convertToPercentage(
                            getCount('unreconciled')?.count,
                            totalRecon,
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="bg-white rounded-xl w-full mt-6 h-[444px]">
            <LineChart
              chartData={analysisChart}
              customElements={
                <Period
                  date={date}
                  setDate={setDate}
                  datePeriod={queryParams.get('period')}
                />
              }
              periodType={date}
              loading={chartLoading}
            />
          </div>
        </div>
      )}
    </ReconciliationLayout>
  );
}

export default ProjectOverview;
