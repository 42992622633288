import React, {useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {
  chevronRight,
  failed,
  overviewInflow,
  overviewOutflow,
  projectsMobileNav,
  successful,
  transactionCount,
} from '../../../../assets/images/images';
import {
  DashboardLayout,
  FilterButton,
  Loader,
  PrimaryButton,
  SkeletonLoader,
} from '../../../../components';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Constants, getInitials} from '../../../../helpers';
import AccountDetailsPieChart from './AccountDetailsPieChart';
import UploadTransactionsModal from './UploadTransactionsModal';
import ManualTransactionModal from './ManualTransactionModal';
import {fetchAccount} from '../../../../requests/queries/reconciliations';
import {AccountTransactionsTable} from './AccountTransactionsTable';
import {TransactionTabs} from '../TransactionTabs';
import ExternalTransactionModal from './ExternalTransactionModal';
import ExternalTransactionUploadOptions from '../ExternalTransactionUploadOptions';
import {GoBack} from '../components/GoBack';
import {LineChart} from '../components/LineChart';
import {Period} from '../components/Period';
import {convertToPercentage} from '../../../../helpers/convertToPercentage';
import {useAnalysisData} from '../hooks/useAnalysisData';
import {OverviewCards} from '../components/OverviewCards';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';
import {ReconFilters} from '../components/ReconFilters';

function ProjectAccountDetails() {
  const {slug} = useParams();
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [transactionsCount, setTransactionsCount] = useState({
    internalCount: 0,
    externalCount: 0,
  });
  const [filters, setFilters] = useState(false);
  const [externalTransaction, setExternalTransaction] = useState(false);
  const [fileType, setFileType] = useState('');
  const [isUploadTransactions, setIsUploadTransactions] = useState(false);
  const [tabType, setTabType] = useState('internal');
  const [date, setDate] = useState('month');
  const [openExternal, setOpenExternal] = useState(false);
  const [createManualTransactions, setCreateManualTransactions] =
    useState(false);
  const [query, setQuery] = useState({
    page: 1,
    limit: 5,
  });

  const {
    overview: {overviewLoading, inFlow, outFlow},
    chartAnalysis: {analysisChart, chartLoading},
    reconAnalysis: {reconLoading, totalRecon, getCount},
  } = useAnalysisData({id: slug, date, entity_id: 'account_id', ...filters});

  const {data: accountDetails, isLoading} = useQuery(
    ['account-details', slug],
    () => fetchAccount(slug),
    {
      enabled: !!slug,
    },
  );

  const ACCOUNT_DETAILS =
    !isLoading && accountDetails && accountDetails?.entity;

  const selectedAccount = useMemo(
    () => ({
      label: `${ACCOUNT_DETAILS?.account_name} - ${ACCOUNT_DETAILS?.bank}`,
      value: slug,
      type: ACCOUNT_DETAILS?.account_type,
      currency: ACCOUNT_DETAILS?.currency,
    }),
    [
      ACCOUNT_DETAILS?.account_name,
      ACCOUNT_DETAILS?.account_type,
      ACCOUNT_DETAILS?.bank,
      ACCOUNT_DETAILS?.currency,
      slug,
    ],
  );

  const openUploadTransactionsModal = () => {
    setIsUploadTransactions(true);
  };

  const closeUploadTransactionsModal = () => {
    setIsUploadTransactions(false);
  };

  const openCreateManualTransactionsModal = () => {
    setCreateManualTransactions(true);
    closeUploadTransactionsModal();
  };

  const closeCreateManualTransactionsModal = () => {
    setCreateManualTransactions(false);
  };
  const queryParams = new URLSearchParams(window.location.search);

  const flows = [
    {
      icon: overviewInflow,
      alt: 'in-flow',
      title: 'Total Inflow',
      count: inFlow?.count || '0',
      value: shortenLargeNumbers(inFlow?.amount, ACCOUNT_DETAILS?.currency, {
        startAt: 'M',
      }),
    },
    {
      icon: overviewOutflow,
      alt: 'out-flow',
      title: 'Total Outflow',
      count: outFlow?.count || '0',
      value: shortenLargeNumbers(outFlow?.amount, ACCOUNT_DETAILS?.currency, {
        startAt: 'M',
      }),
    },
    {
      icon: transactionCount,
      alt: 'net-cash flow',
      title: 'Net CashFlow',
      count: inFlow?.count - outFlow?.count || '0',

      value: shortenLargeNumbers(
        inFlow?.amount - outFlow?.amount,
        ACCOUNT_DETAILS?.currency,
        {startAt: 'M'},
      ),
    },
  ];

  return (
    <DashboardLayout
      bg="bg-white"
      overFlow="!pr-0"
      leftMargin="!ml-0"
      breadCrumbs={
        <div className="flex items-center">
          <img src={projectsMobileNav} alt="" width={18} height={18} />
          <Link
            className="ml-2 -mb-1"
            to={Constants.PAGES_URL.PROJECT_ACCOUNTS}
          >
            Projects
          </Link>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1 cursor-pointer">Account Details</p>
        </div>
      }
    >
      <div className="pl-11 pr-8">
        <GoBack goBack={() => navigate(-1)} />

        <ReconFilters
          filters={filters}
          setFilters={setFilters}
          limit={query?.limit}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          setQuery={setQuery}
          query={query}
        />

        <ExternalTransactionUploadOptions
          show={externalTransaction}
          onClose={() => setExternalTransaction(!externalTransaction)}
          openUploadModal={() => {
            setExternalTransaction(!externalTransaction);
            setOpenExternal(!openExternal);
          }}
          setFileType={setFileType}
          goBack={openUploadTransactionsModal}
        />
        <UploadTransactionsModal
          show={isUploadTransactions}
          onClose={closeUploadTransactionsModal}
          openCreateManualTransactionsModal={openCreateManualTransactionsModal}
          openExternal={() => {
            closeUploadTransactionsModal();
            setExternalTransaction(!externalTransaction);
          }}
        />

        <ManualTransactionModal
          accountData={ACCOUNT_DETAILS}
          accountId={slug}
          show={createManualTransactions}
          onClose={closeCreateManualTransactionsModal}
          goBack={openUploadTransactionsModal}
          selectedAccount={selectedAccount}
        />
        <ExternalTransactionModal
          fileType={fileType}
          show={openExternal}
          onClose={setOpenExternal}
          goBack={() => setExternalTransaction(!externalTransaction)}
          selectedAccount={selectedAccount}
        />

        <div className="flex flex-col md:flex-row md:items-center justify-between gap-6 mt-4 mb-8">
          <h2 className=" text-lg font-medium text-grey">Account Details</h2>
          <div className="md:items-center gap-4 flex flex-col md:flex-row">
            <FilterButton
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
            />
            <PrimaryButton
              buttonText="Add Transactions"
              className="rounded-lg"
              onClick={openUploadTransactionsModal}
            />
          </div>
        </div>

        <div className="mt-8 bg-white80 rounded-xl py-6 px-3 lg:pl-6 gap-10 lg:pr-16 flex flex-col lg:flex-row lg:items-center justify-between">
          <div className="flex items-center gap-4">
            {isLoading ? (
              <SkeletonLoader
                style={{
                  width: 80,
                  height: 80,
                  borderRadius: '50%',
                }}
              />
            ) : (
              <div className="bg-white rounded-full mr-4 min-w-[68px] min-h-[68px] border border-[#ECECEC] flex items-center justify-center">
                <p className="text-deepBlue font-medium text-xl">
                  {getInitials(ACCOUNT_DETAILS?.bank)}
                </p>
              </div>
            )}

            {isLoading ? (
              <div className="flex flex-col gap-1">
                {[1, 2].map(_ => (
                  <SkeletonLoader
                    key={_}
                    style={{
                      width: _ === 1 ? 150 : 100,
                      height: 20,
                      marginBottom: 10,
                      borderRadius: 5,
                    }}
                  />
                ))}
              </div>
            ) : (
              <div className="flex flex-col gap-1">
                <h3 className="font-semibold text-md text-deepBlue">
                  {ACCOUNT_DETAILS?.bank || '-'}
                </h3>
                <p className="text-[#4E4E4E] text-tiny font-inter">
                  {ACCOUNT_DETAILS?.account_number || '-'}
                </p>
              </div>
            )}
          </div>

          <OverviewCards
            className={'grid grid-cols-1 md:grid-cols-3 gap-2 lg:gap-10 flex-1'}
            innerClassName={
              'bg-white py-6 pl-6 pr-2 relative rounded-lg flex items-center gap-2 min-w-[270px]'
            }
            overviewLoading={overviewLoading}
            cardData={flows}
          />
        </div>

        <div className="mt-4 flex w-full  flex-wrap md:flex-nowrap gap-6">
          <div className="bg-white80 rounded-xl w-full md:w-[60%] p-6">
            <LineChart
              chartData={analysisChart}
              customElements={
                <Period
                  date={date}
                  setDate={setDate}
                  datePeriod={queryParams.get('period')}
                />
              }
              periodType={date}
              loading={chartLoading}
              height={300}
              pageHeight={350}
              loaderHeight={20.2}
            />
          </div>
          <div className="flex items-center justify-center bg-white80 relative rounded-xl w-full md:w-[40%]">
            {reconLoading ? (
              <Loader height={22} />
            ) : (
              <div className="bg-white80 relative rounded-xl flex flex-col w-full md:flex-row md:items-center ">
                <AccountDetailsPieChart
                  totalRecon={totalRecon}
                  recon={getCount('reconciled')?.count}
                  unrecon={getCount('unreconciled')?.count}
                />
                <div className="flex flex-col gap-8 right-10">
                  <div className="flex items-center gap-2">
                    <img src={successful} alt="" width={40} height={40} />
                    <div className="flex flex-col gap-1">
                      <p className="text-xs font-medium uppercase text-body">
                        Reconciled
                      </p>
                      <p className="text-xl font-medium text-grey">
                        {convertToPercentage(
                          getCount('reconciled')?.count,
                          totalRecon,
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={failed} alt="" width={40} height={40} />
                    <div className="flex flex-col gap-1">
                      <p className="text-xs font-medium uppercase text-body">
                        Unreconciled
                      </p>
                      <p className="text-xl font-medium text-grey">
                        {convertToPercentage(
                          getCount('unreconciled')?.count,
                          totalRecon,
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:items-center justify-between mt-6">
          <h4 className="text-[#1D1D1D] font-semibold text-base">
            Transactions
          </h4>
          <TransactionTabs
            query={query}
            setQuery={setQuery}
            tabType={tabType}
            setTabType={setTabType}
            count={transactionsCount}
          />
        </div>
      </div>

      <AccountTransactionsTable
        openUploadTransactionsModal={openUploadTransactionsModal}
        tabType={tabType}
        query={query}
        setQuery={setQuery}
        setTransactionsCount={setTransactionsCount}
      />
    </DashboardLayout>
  );
}

export default ProjectAccountDetails;
