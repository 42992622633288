import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generateAndDownloadSignaturePDF = (text, name) => {
  const docDefinition = {
    content: [
      {
        text: 'Agreement',
        fontSize: 16,
        bold: true,
        alignment: 'center',
        margin: [0, 0, 0, 20],
      },
      {
        text: 'AGREEMENT',
        fontSize: 10,
        bold: true,
        color: '#080917',
        margin: [0, 0, 0, 10],
      },
      {
        text: text,
        fontSize: 10,
        margin: [0, 0, 0, 20],
        color: '#4A4A68',
        lineHeight: 1.5,
      },
      {
        text: 'SIGNATURE',
        fontSize: 10,
        bold: true,
        color: '#080917',
        margin: [0, 0, 0, 10],
      },
      {
        text: name,
        fontSize: 10,
        margin: [0, 0, 0, 20],
        color: '#4A4A68',
      },
    ],
    watermark: {
      text: 'CONFIDENTIAL REPORT',
      color: '#CECED2',
      fontSize: 48,
      bold: true,
      angle: -45,
      margin: [0, 0],
      alignment: 'center',
      verticalAlignment: 'middle',
    },
  };

  pdfMake.createPdf(docDefinition).download('signature.pdf');
};
