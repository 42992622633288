import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {useMutation, useQueryClient} from 'react-query';
import useDragAndDrop from '../../hooks/useDragAndDrop';
import {handleFileInput} from '../../helpers';
import {uploadCsv} from '../../assets/images/images';
import {Modal, PrimaryButton} from '../../components';
import {performBatchVerification} from '../../requests/queries/address-verification';

export default function BatchUploadModal({open, setOpen}) {
  const queryClient = useQueryClient();

  const [file, setFileData] = useState(null);
  const {dragOver, setDragOver, onDragOver, onDragLeave} = useDragAndDrop();

  const onDrop = e => {
    e.preventDefault();
    setDragOver(false);
    const selectedFile = e?.dataTransfer?.files[0];
    if (selectedFile.type.split('/')[0] !== 'application') {
      return toast.error('Please provide a valid file type (.xlsx, .csv)');
    }
    handleFileInput(selectedFile, setFileData);
  };

  const {isLoading, mutate} = useMutation(data =>
    performBatchVerification(data),
  );

  const handleSubmit = e => {
    e.preventDefault();
    if (file) {
      mutate(
        {file: file.file, file_name: file.filename},
        {
          onSuccess: () => {
            toast.success('Verification successful');
            setOpen(false);
            setFileData(null);
            queryClient.invalidateQueries(['batch-address-verifications']);
          },
          onError: error => {
            toast.error(error.response.data.error);
          },
        },
      );
    }
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Batch verification"
    >
      <div className="mt-6 sm:bg-white80 sm:p-6 rounded">
        <div
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          style={{
            border: `${dragOver ? '3px dashed #33333322' : ''}`,
          }}
          className="py-[22px] flex flex-col items-center gap-[2px] text-center border border-brandBlue border-dashed rounded"
        >
          <div className="flex-col items-center hidden sm:flex">
            {!file && <img src={uploadCsv} alt="" width={48} height={48} />}
            <p className="text-body">
              {dragOver ? (
                'Please drop file here'
              ) : (
                <span style={{color: file ? '#00000032' : ''}}>
                  Drag and drop CSV file here
                </span>
              )}
            </p>
          </div>
          {file ? (
            <div className="text-body text-sm flex my-2 gap-[3px] items-baseline">
              <input
                type="file"
                id="file"
                onChange={e => {
                  handleFileInput(e.target.files[0], setFileData);
                }}
                accept=".xlsx, .csv"
              />
              <label htmlFor="file">Change file</label>
            </div>
          ) : (
            <div className="text-body text-sm flex gap-[3px] items-baseline">
              <span className="hidden sm:block">or</span>
              <input
                type="file"
                id="file"
                onChange={e => {
                  handleFileInput(e.target.files[0], setFileData);
                }}
                accept=".xlsx, .csv"
              />
              <label htmlFor="file">click here to select</label>
              from your device
            </div>
          )}
          <p className="text-body text-sm flex gap-[3px] items-baseline">
            {file ? (
              <>
                File:{' '}
                <span className="font-bold text-black ">{file.filename}</span>
              </>
            ) : (
              ''
            )}
          </p>
        </div>

        <div className="mt-2 text-xs text-right">
          <a
            href="https://docs.google.com/spreadsheets/d/1jH4fg84pcZEJudy2f4VjuEr9AVPI4oS8oYgp5Q6VBRQ/edit#gid=0"
            target="_blank"
            rel="noreferrer"
            download
            className="font-medium text-body sm:text-brandBlue"
          >
            Click here{' '}
            <span className="font-medium text-body">
              to download template for batch upload
            </span>
          </a>
        </div>
      </div>

      <div className="flex items-center mt-6">
        <PrimaryButton
          buttonText="Perform verification"
          className="w-full"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={!file || isLoading}
        />
      </div>
    </Modal>
  );
}
