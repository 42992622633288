import React from 'react';
import Layout from './Layout';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {fetchChecksByEntity} from '../../requests/queries/creditChecks';

export default function EasyCreditAmlScreening() {
  const {slug} = useParams();

  const {data} = useQuery(
    [
      'watchlist_check-info',
      {customer_id: slug, entity_type: 'watchlist_check'},
    ],
    () =>
      fetchChecksByEntity({
        customer_id: slug,
        entity_type: 'watchlist_check',
      }),
    {
      retry: false,
    },
  );

  console.log(data);
  return <Layout>aml</Layout>;
}
