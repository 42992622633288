import React, {useEffect, useRef, useState} from 'react';
import {PaystackConsumer} from 'react-paystack';
import {toast} from 'react-toastify';
import {loadStripe} from '@stripe/stripe-js';
import {useMutation} from 'react-query';
import {closeModal, paystack, transfer} from '../assets/images/images';
import Modal from './Modal';
import PrimaryButton from './PrimaryButton';
import {connect} from 'react-redux';
import * as Actions from '../store/actions';
import StripeCheckoutForm from './StripeCheckoutForm';
import {Elements} from '@stripe/react-stripe-js';
import {api} from '../helpers';
import {formatNumberToCurrency} from '../helpers/formatNumberToCurrency';

const stripeApiKey =
  process.env.REACT_APP_APP_ENV_MODE !== 'production'
    ? process.env.REACT_APP_STRIPE_TEST_KEY
    : process.env.REACT_APP_STRIPE_LIVE_KEY;

const stripePromise = loadStripe(stripeApiKey);

export function makePayment(data) {
  return api.post('/payments/get_details/stripe', data).then(res => res.data);
}

function TopupModal({
  auth: {paymentDetails, wallet, loading, userDetails},
  verifyPayment,
  startPayment,
  getAccountNumber,
  ...otherProps
}) {
  const {open, setOpen} = otherProps;
  const [showPayment, setShowPayment] = useState('');
  const [amount, setAmount] = useState('');
  const [reference, setReference] = useState(null);
  const payButton = useRef(null);
  const [stripeReference, setStripeReference] = useState('');

  // stripe
  const [clientSecret, setClientSecret] = useState('');

  const {mutate: makePaymentMutation, isLoading: isPaymentLoading} =
    useMutation(makePayment, {
      onSuccess: data => {
        setClientSecret(data.clientSecret);
        setStripeReference(data.reference);
        setAmount('');
      },
      onError: error => {
        console.error('Error making payment:', error);
        toast.error('An error occurred while processing your payment.');
      },
    });

  const appearance = {
    theme: 'stripe',
    variables: {
      fontFamily: '"Inter", sans-serif',
      colorText: '#4A4A68',
      colorDanger: '#F15D5F',
      colorPrimary: '#3f7cdb',
    },
    rules: {
      '.Input': {
        border: 'none',
        backgroundColor: '#f9f9f9',
        outline: '1px solid #CECED2',
        padding: '10px 16px',
        boxShadow: 'none',
        height: '48px',
        borderColor: 'transparent',
      },
      '.Input:focus': {
        height: '48px',
        padding: '10px 16px',
        border: 'none',
        borderColor: 'transparent',
        boxShadow: 'none',
        outline: '1px solid var(--colorPrimary)',
      },
      '.Input, .Label, .Input--invalid, .Label--invalid': {
        fontFamily: 'Atakk',
        fontSize: '13px',
      },
      '.Input::placeholder': {
        color: '#8E90A9',
      },
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  const onSuccess = ref => {
    setReference(ref.reference);
  };

  const onClose = () => {
    toast.error('Payment gateway Closed');
  };

  const handlePayment = () => {
    if (amount < 1000) {
      toast.error(
        `Amount must not be lower than ${formatNumberToCurrency(
          1,
          userDetails && userDetails?.company && userDetails?.company?.currency,
        )}`,
      );
      return;
    }
    startPayment({amount});
  };

  const componentProps = {
    email: paymentDetails && paymentDetails?.email,
    reference: paymentDetails && paymentDetails?.ref,
    amount: paymentDetails && paymentDetails?.amount,
    publicKey: paymentDetails && paymentDetails?.p_key,
    text: 'Pay Online',
    onSuccess,
    onClose,
  };

  useEffect(() => {
    if (reference) {
      verifyPayment({reference}).then(() => {
        setAmount('');
        setOpen(false);
      });
    }
  }, [reference, verifyPayment, setOpen]);

  useEffect(() => {
    const pay = async () => {
      if (payButton.current !== null) payButton.current.click();
    };

    pay();
  }, [paymentDetails]);

  if (showPayment === 'paystack') {
    return (
      <TopupModalWrapper
        headerText="Continue with paystack"
        open={open}
        setOpen={setOpen}
        setShowPayment={setShowPayment}
      >
        <label htmlFor="topup_amount">
          <span className="text-sm ">Enter amount</span>
          <input
            placeholder={formatNumberToCurrency(
              userDetails?.company?.wallet_balance / 100 ?? 0,
              userDetails &&
                userDetails.company &&
                userDetails.company.currency,
            )}
            className="mt-2"
            id="amount"
            value={amount}
            type="number"
            onChange={e => {
              setAmount(e.target.value);
            }}
          />
        </label>

        <PrimaryButton
          loading={loading}
          onClick={handlePayment}
          buttonText="Continue"
        />
        <PaystackConsumer {...componentProps}>
          {({initializePayment}) => (
            <button
              ref={payButton}
              style={{display: 'none'}}
              disabled={amount < 1000}
              onClick={() => initializePayment()}
            >
              Pay Button
            </button>
          )}
        </PaystackConsumer>
      </TopupModalWrapper>
    );
  } else if (showPayment === 'transfer') {
    return (
      <TopupModalWrapper
        headerText="Pay via transfer"
        open={open}
        setOpen={setOpen}
        setShowPayment={setShowPayment}
      >
        {wallet && (
          <div className="flex flex-col gap-1 mx-auto max-w-[206px] font-medium">
            <span className="text-sm text-body">Make payments to</span>
            <span className="text-xl text-grey">
              {wallet?.entity?.account_number || '0000000000'}
            </span>
            <span className="text-xl text-grey">
              {wallet?.entity?.bank_name || 'Bank Name'}
            </span>
          </div>
        )}

        {!wallet && !loading && (
          <p className="my-0 text-center fw-medium">
            {userDetails?.company.verified ? (
              <>
                Unable to fetch account number.{' '}
                <span
                  className="small btn-light pointer text-underline"
                  onClick={() => getAccountNumber()}
                >
                  Try again
                </span>
              </>
            ) : (
              'Company must be verified to create a wallet'
            )}
          </p>
        )}
        <PrimaryButton
          buttonText="I have made the transfer"
          className="w-full"
          loading={loading}
          onClick={() => window.location.reload()}
          disabled={!userDetails?.company?.verified}
        />
      </TopupModalWrapper>
    );
  } else if (showPayment === 'stripe') {
    return (
      <TopupModalWrapper
        headerText="Pay with Stripe"
        open={open}
        setOpen={setOpen}
        setShowPayment={setShowPayment}
        setClientSecret={setClientSecret}
      >
        {clientSecret ? (
          <Elements options={options} stripe={stripePromise}>
            <StripeCheckoutForm setOpen={setOpen} reference={stripeReference} />
          </Elements>
        ) : (
          <>
            <label htmlFor="topup_amount">
              <span className="text-sm ">Enter amount</span>
              <input
                placeholder="$0.00"
                className="mt-2"
                id="amount"
                value={amount}
                type="number"
                onChange={e => {
                  setAmount(e.target.value);
                }}
              />
            </label>
            <PrimaryButton
              loading={isPaymentLoading}
              buttonText="Continue"
              onClick={() => {
                makePaymentMutation({
                  amount,
                  currency: userDetails?.company?.currency,
                });
              }}
            />
          </>
        )}
      </TopupModalWrapper>
    );
  } else
    return (
      <TopupModalWrapper
        setShowPayment={setShowPayment}
        open={open}
        setOpen={setOpen}
        headerText="Top up"
      >
        {userDetails?.company?.currency === 'USD' ? (
          <button
            className="flex items-center gap-4 p-6 rounded bg-white80"
            onClick={() => {
              setShowPayment('stripe');
            }}
          >
            <img src={transfer} alt="" width={24} height={24} />
            <span className="text-base font-medium text-grey">
              Pay with Stripe
            </span>
          </button>
        ) : (
          <>
            <button
              className="flex items-center gap-4 p-6 rounded bg-white80"
              onClick={() => {
                wallet ? null : getAccountNumber();
                setShowPayment('transfer');
              }}
            >
              <img src={transfer} alt="" width={24} height={24} />
              <span className="text-base font-medium text-grey">
                Pay via transfer
              </span>
            </button>
            <button
              className="flex items-center gap-4 p-6 rounded bg-white80"
              onClick={() => setShowPayment('paystack')}
            >
              <img src={paystack} alt="" width={24} height={24} />
              <span className="text-base font-medium text-grey">
                Continue with Paystack
              </span>
            </button>
          </>
        )}
      </TopupModalWrapper>
    );
}

function TopupModalWrapper({
  headerText,
  open,
  setOpen,
  children,
  setShowPayment,
  setClientSecret,
}) {
  return (
    <Modal
      show={open}
      onClose={() => {
        setOpen(!open);
        setTimeout(() => {
          setShowPayment('');
        }, 1000);
        setClientSecret('');
      }}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-base font-medium text-grey">{headerText}</h3>

          <button
            onClick={() => {
              setOpen(false);
              setTimeout(() => {
                setShowPayment('');
              }, 1000);
              setClientSecret('');
            }}
          >
            <img src={closeModal} alt="" />
          </button>
        </header>
        <div className="flex flex-col gap-4 mt-6">{children}</div>
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(TopupModal);
