import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {
  allowedEvents,
  blockedEvents,
  calendar,
  pendingEvents,
  profileIc,
  totalEvents as totalIcon,
} from '../../../../assets/images/images';
import {Card, CustomDateRadio, Dropdown, Radios} from '../../../../components';
import {Constants} from '../../../../helpers';
import {CheckboxContextProvider} from '../../../../helpers/context/CheckboxContext';
import {customDateFilters} from '../../../../helpers/customDateFilters';
import {
  DashboardPieChartLeft,
  DashboardPieChartRight,
} from '../../Overview/DonutCharts';
import Layout from '../Layout';
import * as Actions from '../../../../store/actions';
import {useParams} from 'react-router-dom';
import {convertToPercentage} from '../../../../helpers/convertToPercentage';
import {PageContext} from '../../../../helpers/context';

function CustomDate({...otherProps}) {
  const {setFilters, filters: currentFilters} = otherProps;
  const [date, setDate] = useState('');
  const [startDate, setStartDate] = useState(new Date('01/08/2021'));
  const [endDate, setEndDate] = useState(new Date());
  const [customDate, setCustomDate] = useState(false);
  const [dateOpen] = useState(false);
  const maxDate = new Date();

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  const onCustomDateCheck = e => {
    const {checked} = e.target;
    if (checked) {
      setDate(1);
      setCustomDate(true);
    }
  };

  const onDateCheck = useCallback(
    e => {
      const {value} = e.target;
      customDateFilters({
        value,
        appFilters: currentFilters,
        onCheck: setFilters,
      });

      setDate(value);

      setCustomDate(false);
    },
    [currentFilters, setFilters],
  );
  return (
    <CheckboxContextProvider>
      <div className="hidden w-fit ml-auto mt-8 rounded-lg md:block bg-white80">
        <div className="flex items-center justify-end gap-4">
          <div className="relative">
            <Dropdown
              dropdownBtn={
                <div className="flex items-end gap-12 bg-white80 py-[11px] px-4 rounded cursor-pointer">
                  <span className="text-xs font-medium uppercase text-body">
                    Date
                  </span>
                  <img src={calendar} alt="" />
                </div>
              }
            >
              <Radios
                className="absolute z-10 w-64 mt-2 shadow-sm"
                bg="bg-white"
                padding="px-0"
                data={Constants.lookups.dates}
                filterName="date"
                onCheck={onDateCheck}
                selectedCount={date === '' ? 0 : 1}
                onClick={onDateCheck}
                activeIndex={dateOpen}
              >
                <label
                  htmlFor="custom_date"
                  className="relative flex flex-row items-center gap-2 pl-4 font-normal transition duration-500 ease-in-out"
                >
                  <input
                    type="radio"
                    value="custom_date"
                    id="custom_date"
                    name="date"
                    className="w-[15px] h-[15px] rounded-sm focus-visible:outline-1 focus-visible:outline-brandBlue"
                    onClick={onCustomDateCheck}
                  />
                  <span className="text-sm text-grey">Specific date range</span>
                </label>
                {customDate && (
                  <CustomDateRadio
                    selectedStart={startDate}
                    selectedEnd={endDate}
                    onChangeStart={date => {
                      const start = moment(date).format('YYYY-MM-DD HH:mm:ss');
                      setFilters({...currentFilters, start});
                      setStartDate(date);
                    }}
                    onChangeEnd={date => {
                      const end = moment(date).format('YYYY-MM-DD HH:mm:ss');
                      setFilters({...currentFilters, end});
                      setEndDate(date);
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={maxDate}
                    minDate={startDate}
                  />
                )}
              </Radios>
            </Dropdown>
          </div>
        </div>
      </div>
    </CheckboxContextProvider>
  );
}

function FlowsAnalysis({
  getTotalEvents,
  getTotalAllowed,
  getTotalPending,
  getTotalBlocked,
  flows: {totalEvents, totalAllowed, totalPending, totalBlocked, loading},
}) {
  const {slug} = useParams();
  useEffect(() => {
    getTotalEvents(slug);
    getTotalAllowed(slug);
    getTotalPending(slug);
    getTotalBlocked(slug);
  }, [getTotalEvents, getTotalAllowed, getTotalPending, getTotalBlocked, slug]);

  const TOTAL_EVENTS = useMemo(() => totalEvents?.data?.result, [totalEvents]);
  const TOTAL_ALLOWED = useMemo(
    () => totalAllowed?.data?.result,
    [totalAllowed],
  );
  const TOTAL_PENDING = useMemo(
    () => totalPending?.data?.result,
    [totalPending],
  );
  const TOTAL_BLOCKED = useMemo(
    () => totalBlocked?.data?.result,
    [totalBlocked],
  );

  const TOTAL_RESOLVED = useMemo(
    () =>
      !isNaN(TOTAL_ALLOWED?.content + TOTAL_BLOCKED?.content)
        ? TOTAL_ALLOWED?.content + TOTAL_BLOCKED?.content
        : 0,
    [TOTAL_BLOCKED, TOTAL_ALLOWED],
  );

  const TOTAL_FLAGGED = useMemo(
    () =>
      !isNaN(
        TOTAL_ALLOWED?.content +
          TOTAL_BLOCKED?.content +
          TOTAL_PENDING?.content,
      )
        ? TOTAL_ALLOWED?.content +
          TOTAL_BLOCKED?.content +
          TOTAL_PENDING?.content
        : 0,
    [TOTAL_BLOCKED, TOTAL_ALLOWED, TOTAL_PENDING],
  );

  const TOTAL_NON_FLAGGED = useMemo(
    () => TOTAL_EVENTS?.content - TOTAL_FLAGGED,
    [TOTAL_EVENTS, TOTAL_FLAGGED],
  );

  return (
    <Layout bg="bg-white" pageTitle="Analysis" pageIcon={profileIc}>
      <CustomDate />
      <div className="flex flex-col lg:flex-row items-center gap-4 mt-6 mb-12">
        <Card
          className="w-full"
          img={totalIcon}
          title="Total Events"
          body={TOTAL_EVENTS?.content || 0}
          loading={loading}
        />
        <Card
          className="w-full"
          img={pendingEvents}
          title="Pending Cases"
          body={TOTAL_PENDING?.content || 0}
          loading={loading}
        />
        <Card
          className="w-full"
          img={allowedEvents}
          title="Allowed Cases"
          body={TOTAL_ALLOWED?.content || 0}
          loading={loading}
        />
        <Card
          className="w-full"
          img={blockedEvents}
          title="Blocked Cases"
          body={TOTAL_BLOCKED?.content || 0}
          loading={loading}
        />
      </div>

      <div className="flex gap-6 mt-8 basis-full">
        <div className="bg-white80 rounded-lg pt-[26px] px-3 sm:px-[22px] pb-5 basis-full sm:basis-1/2">
          <h3 className="mb-4 text-lg font-medium text-center sm:text-left text-grey">
            Resolved cases
          </h3>
          <div className="flex flex-col items-center gap-3 sm:flex-row">
            <DashboardPieChartLeft
              allowedPercentage={
                +convertToPercentage(
                  TOTAL_ALLOWED?.content,
                  TOTAL_RESOLVED,
                ).replace('%', '')
              }
              blockedPercentage={
                +convertToPercentage(
                  TOTAL_BLOCKED?.content,
                  TOTAL_RESOLVED,
                ).replace('%', '')
              }
              total={TOTAL_RESOLVED}
              loading={loading}
            />{' '}
            <div className="flex gap-2 sm:gap-8 sm:ml-6 sm:flex-col">
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-status-400" />
                <div className="flex flex-col gap-[5px]">
                  <p className="text-xs font-medium uppercase text-body">
                    Allowed
                  </p>
                  <p className="text-xl font-medium text-grey">
                    {' '}
                    {loading
                      ? '0%'
                      : convertToPercentage(
                          TOTAL_ALLOWED?.content,
                          TOTAL_RESOLVED,
                        )}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-brandBlue" />
                <div className="flex flex-col gap-[5px]">
                  <p className="text-xs font-medium uppercase text-body">
                    Blocked
                  </p>
                  <p className="text-xl font-medium text-grey">
                    {loading
                      ? '0%'
                      : convertToPercentage(
                          TOTAL_BLOCKED?.content,
                          TOTAL_RESOLVED,
                        )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white80 rounded-lg pt-[26px] px-[22px] pb-5 basis-1/2 hidden md:block">
          <h3 className="mb-4 text-lg font-medium text-grey">Flagged events</h3>
          <div className="flex items-center gap-12">
            <DashboardPieChartRight
              flaggedPercentage={
                +convertToPercentage(
                  TOTAL_FLAGGED,
                  TOTAL_EVENTS?.content,
                ).replace('%', '')
              }
              nonFlaggedPercentage={
                +convertToPercentage(
                  TOTAL_NON_FLAGGED,
                  TOTAL_EVENTS?.content,
                ).replace('%', '')
              }
              flagged={TOTAL_FLAGGED}
              loading={loading}
            />
            <div className="flex gap-2 sm:gap-8 sm:ml-6 sm:flex-col">
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-status-404" />
                <div className="flex flex-col gap-[5px]">
                  <p className="text-xs font-medium uppercase text-body">
                    FLAGGED EVENTS
                  </p>
                  <p className="text-xl font-medium text-grey">
                    {convertToPercentage(TOTAL_FLAGGED, TOTAL_EVENTS?.content)}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-red" />
                <div className="flex flex-col gap-[5px]">
                  <p className="text-xs font-medium uppercase text-body">
                    NON FLAGGED EVENTS
                  </p>
                  <p className="text-xl font-medium text-grey">
                    {convertToPercentage(
                      TOTAL_NON_FLAGGED,
                      TOTAL_EVENTS?.content,
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default connect(state => state, Actions)(FlowsAnalysis);
