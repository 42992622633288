import React from 'react';
import {ReactComponent as Nigeria} from '../../assets/images/nigeria.svg';
import {ReactComponent as Uganda} from '../../assets/images/uganda.svg';
import {ReactComponent as Kenya} from '../../assets/images/kenya.svg';
import {ReactComponent as Ghana} from '../../assets/images/ghana.svg';
import {ReactComponent as Zimbabwe} from '../../assets/images/zimbabwe.svg';
import {ReactComponent as Angola} from '../../assets/images/angola.svg';
import {ReactComponent as SouthAfrica} from '../../assets/images/south-africa.svg';

// https://hampusborgos.github.io/country-flags/

const GENDER = [
  {value: 'Male', label: 'Male'},
  {value: 'Female', label: 'Female'},
];
const MARITAL_STATUS = [
  {value: 'M', label: 'Married'},
  {value: 'S', label: 'Single'},
];

const CAC_ADVANCE_TYPES = [
  {label: 'BN - Business Name', value: 'bn', name: 'Business Name'},
  {label: 'RC - Company', value: 'co', name: 'Company'},
  {
    label: 'IT - Incorporated Trustee',
    value: 'it',
    name: 'Incorporated Trustee',
  },
];

const countries = [
  {value: 'nigeria', label: 'Nigeria', icon: <Nigeria />, name: 'countries'},
  {value: 'uganda', label: 'Uganda', icon: <Uganda />, name: 'countries'},
  {value: 'kenya', label: 'Kenya', icon: <Kenya />, name: 'countries'},
  {value: 'ghana', label: 'Ghana', icon: <Ghana />, name: 'countries'},
  {value: 'zimbabwe', label: 'Zimbabwe', icon: <Zimbabwe />, name: 'countries'},
  {value: 'angola', label: 'Angola', icon: <Angola />, name: 'countries'},
  {
    value: 'south-africa',
    label: 'South Africa',
    icon: <SouthAfrica />,
    name: 'countries',
  },
];

const countries2 = [
  {value: 'nigeria', label: 'Nigeria', icon: <Nigeria />, name: 'countries'},
  {value: 'kenya', label: 'Kenya', icon: <Kenya />, name: 'countries'},
  {value: 'ghana', label: 'Ghana', icon: <Ghana />, name: 'countries'},
  {value: 'angola', label: 'Angola', icon: <Angola />, name: 'countries'},
  {
    value: 'southafrica',
    label: 'South Africa',
    icon: <SouthAfrica />,
    name: 'countries',
  },
];

const companyID = localStorage.getItem('company_id');
const LAPO_COMPANY_ID = '63da7dcf146e720034b8a994';

const nigeriaId = [
  {value: 'vnin', label: 'Virtual NIN', name: 'nigeria_id'},
  {value: 'nin', label: 'NIN', name: 'nigeria_id'},
  {value: 'bvn', label: 'BVN Basic', name: 'nigeria_id'},
  ...(companyID === LAPO_COMPANY_ID
    ? []
    : [{value: 'advance', label: 'BVN Advance', name: 'nigeria_id'}]),
  // {value: 'nuban', label: 'NUBAN', name: 'nigeria_id'},
  {value: 'account_number', label: 'NUBAN', name: 'nigeria_id'},
  {value: 'nuban', label: 'NUBAN KYC', name: 'nigeria_id'},
  // {value: 'tin', label: 'TIN', name: 'nigeria_id'},
  // {value: 'vin', label: 'VIN', name: 'nigeria_id'},
  {value: 'basic', label: 'Basic Phone Number', name: 'nigeria_id'},
  {value: 'phone_number', label: 'Advance Phone Number', name: 'nigeria_id'},
  {value: 'dl', label: "Driver's License", name: 'nigeria_id'},
  {value: 'cac', label: 'CAC', name: 'nigeria_id'},
  {value: 'cac_advance', label: 'Advance CAC', name: 'nigeria_id'},
  {value: 'global_search', label: 'Global Check', name: 'nigeria_id'},
  // Enable Passport lookup
  {value: 'passport', label: 'International Passport', name: 'nigeria_id'},
];
const ugandaId = [
  {value: 'voter', label: "Voter's ID", name: 'uganda_id'},
  {
    value: 'telco',
    label: 'Telco Subscriber',
    name: 'uganda_id',
  },
];
const kenyaId = [
  {value: 'kra', label: 'KRA PIN', name: 'kenya_id'},
  {value: 'id', label: 'National ID', name: 'kenya_id'},
  {value: 'dl', label: "Driver's License", name: 'kenya_id'},
];
const ghanaId = [
  {value: 'voter', label: "Voter's ID", name: 'ghana_id'},
  {value: 'passport', label: 'Passport', name: 'ghana_id'},
];
const zimbabweId = [
  {value: 'id', label: 'National ID', name: 'zw_id'},
  // {value: 'fcb', label: 'Credit check', name: 'zw_id'},
];
const southAfricaId = [{value: 'id', label: 'National ID', name: 'sa_id'}];
const angolaId = [{value: 'id', label: 'National ID', name: 'ao_id'}];

const nigeriaId_Onboard = [
  {value: 'vnin', label: 'Virtual NIN', name: 'nigeria_id'},
  {value: 'nin', label: 'NIN', name: 'nigeria_id'},
  // {value: 'mobile', label: 'Phone Number', name: 'nigeria_id'},
  {value: 'dl', label: "Driver's License", name: 'nigeria_id'},
  {value: 'bvn', label: 'BVN Basic', name: 'nigeria_id'},
  {value: 'bvnAdvance', label: 'BVN Advance', name: 'nigeria_id'},
];

const nigeriaIssuedId = [
  {value: 'passport', label: 'International Passport'},
  {value: 'dl', label: `Driver's License`},
  {value: 'voter', label: `Voter's Card`},
  // {value: 'custom', label: 'Custom'},
  {value: 'national', label: 'National ID'},
  {value: 'nin', label: 'NIN Slip'},
  {value: 'permit', label: 'Residence permit'},
  {value: 'others', label: 'Other IDs'},
];
const businessData = [
  {value: 'cac', label: 'CAC', name: 'nigeria_id'},
  // {value: 'tin', label: 'TIN', name: 'nigeria_id'},
];

const ugandaId_Onboard = [
  {value: 'ug-voter', label: "Voter's ID", name: 'uganda_id'},
  {
    value: 'ug-telco',
    label: 'Telco Subscriber',
    name: 'uganda_id',
  },
];
const ghanaId_Onboard = [
  {value: 'gh-voter', label: "Voter's ID", name: 'ghana_id'},
  {value: 'gh-dl', label: "Driver's License", name: 'ghana_id'},
];

const zimbabweId_Onboard = [
  {value: 'zw-id', label: 'National ID', name: 'zw_id'},
];

const kenyaId_Onboard = [
  {value: 'ke-kra', label: 'KRA PIN', name: 'kenya_id'},
  // {value: 'ke-id', label: 'National ID', name: 'kenya_id'},
  {value: 'ke-passport', label: 'Passport', name: 'kenya_id'},
  {value: 'ke-dl', label: "Driver's License", name: 'kenya_id'},
];

const angolaId_Onboard = [
  {value: 'ao-nin', label: 'National ID', name: 'ao_id'},
];
const southAfricaId_Onboard = [
  {value: 'za-id', label: 'National ID', name: 'za_id'},
];

const dates = [
  {value: 'today', label: 'Today', name: 'date'},
  {value: 'yesterday', label: 'Yesterday', name: 'date'},
  {value: 'seven_days', label: 'Last 7 days', name: 'date'},
  {value: 'fifteen_days', label: 'Last 15 days', name: 'date'},
  {value: 'thirty_days', label: 'Last 30 days', name: 'date'},
];

const apps = [
  {value: 'app_one', label: 'App 1', name: 'apps'},
  {value: 'app_two', label: 'App 2', name: 'apps'},
  {value: 'app_three', label: 'App 3', name: 'apps'},
];

const status = [
  {value: 'complete', label: 'Successful', name: 'status'},
  // {value: 'processing', label: 'Processing', name: 'status'},
  {value: 'failed', label: 'Failed', name: 'status'},
  {value: 'pending', label: 'Pending', name: 'status'},
];

const getIDType = country => {
  switch (country?.toLowerCase()) {
    case 'nigeria':
      return nigeriaId;
    case 'uganda':
      return ugandaId;
    case 'kenya':
      return kenyaId;
    case 'ghana':
      return ghanaId;
    case 'south-africa':
      return southAfricaId;
    case 'zimbabwe':
      return zimbabweId;
    case 'angola':
      return angolaId;
    default:
      return nigeriaId;
  }
};

const getMultipleIDType = (countries = []) => {
  const allCountriesIDType = {
    nigeria: nigeriaId,
    uganda: ugandaId,
    kenya: kenyaId,
    ghana: ghanaId,
  };
  return (
    countries &&
    countries?.map(country => [...(allCountriesIDType[country] || [])])
  );
};

const getCountryAcronym = country => {
  switch (country?.toLowerCase()) {
    case 'nigeria':
      return 'NG';
    case 'uganda':
      return 'UG';
    case 'kenya':
      return 'KE';
    case 'ghana':
      return 'GH';
    case 'zimbabwe':
      return 'ZW';
    case 'southafrica':
      return 'SA';
    default:
      return 'NG';
  }
};

const pathUrlTypeCheck = type =>
  ({
    basic: 'phone_number/basic',
    cac_advance: 'cac/advance',
    global_search: 'business/search',
    account_number: 'nuban',
    advance: 'bvn/advance',
    dob: 'date_of_birth',
    nuban: 'nuban/status',
  }[type] || type);

const checkLookupType = (country, type, idValue) => {
  const countryLowerCase = country?.toLowerCase();
  const countryIDTypeValues = {
    nigeria: {
      tin: 'tin',
      bvn: 'bvn',
      dl: 'license_number',
      vnin: 'vnin',
      nin: 'nin',
      vin: 'vin',
      voters_id: 'voters_id',
      phone_number: 'phone_number',
      basic: 'phone_number',
      advance: 'bvn',
      cac: 'rc_number',
      cac_advance: 'rc_number',
      global_search: 'country_code',
      nuban: 'account_number',
      account_number: 'account_number',
      passport: 'passport_number',
    },
    kenya: {kra: 'id', dl: 'id', id: 'id'},
    ghana: {dl: 'id', voter: 'id', passport: 'id'},
    uganda: {voter: 'id', telco: 'id'},
    zimbabwe: {id: 'id', fcb: 'id'},
    angola: {id: 'id'},
    'south-africa': {id: 'id'},
  };

  const countryValue = countryIDTypeValues[countryLowerCase];

  if (!countryValue) return;

  if (countryLowerCase === 'nigeria') {
    const typeCheck = pathUrlTypeCheck(type);
    return `/${typeCheck}?${countryValue[type]}=${idValue}`;
  } else if (countryLowerCase === 'zimbabwe') {
    const typeCheck = pathUrlTypeCheck(type);
    return `/zw/${typeCheck}?${countryValue[type]}=${idValue}`;
  } else if (countryLowerCase === 'south-africa') {
    return `/za/${type}?${countryValue[type]}=${idValue}`;
  } else if (countryLowerCase === 'angola') {
    return `/ao/nin?${countryValue[type]}=${idValue}`;
  } else {
    return `/${countryLowerCase?.substring(0, 2)}/${type}?${
      countryValue[type]
    }=${idValue}`;
  }
};

const initialLookUpState = {
  dob: '',
  ghanaDlDob: '',
  state: '',
  firstname: '',
  lastname: '',
  middlename: '',
  bank_code: '',
  mode: '',
  type: null,
  lookupValue: '',
  app: '',
  company_name: '',
};

const lookups = {
  initialLookUpState,
  nigeriaId_Onboard,
  businessData,
  countries,
  countries2,
  nigeriaId,
  ugandaId,
  ugandaId_Onboard,
  kenyaId,
  kenyaId_Onboard,
  zimbabweId,
  zimbabweId_Onboard,
  ghanaId,
  ghanaId_Onboard,
  apps,
  status,
  dates,
  checkLookupType,
  pathUrlTypeCheck,
  getIDType,
  getCountryAcronym,
  getMultipleIDType,
  nigeriaIssuedId,
  GENDER,
  MARITAL_STATUS,
  CAC_ADVANCE_TYPES,
  angolaId_Onboard,
  southAfricaId_Onboard,
};

export {lookups};
