import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {useQuery} from 'react-query';
import moment from 'moment';
import * as Actions from '../../store/actions';
import {
  DashboardLayout,
  PrimaryButton,
  TableLayout,
  Pagination,
  FilterButton,
  PageLimit,
  Filter,
  Loader,
  EmptyState,
  Card,
  // SecondaryButton,
} from '../../components';
import {
  addressVerificationBreadcrumb,
  apiCalls,
  corruptedFile,
  eye,
  failed,
  lookupEmpty,
  pending,
  successful,
} from '../../assets/images/images';
import AddressLayout from './AddressLayout';
import VerificationFormModal from './VerificationFormModal';
import ViewVerificationModal from './ViewVerificationModal';
import {
  filterOutEmptyValues,
  generateQueryParams,
  getStatusColor,
  parseUrlParams,
} from '../../helpers';
import {
  fetchAddressVerifications,
  fetchAddressVerificationSummary,
} from '../../requests/queries/address-verification';
import {toast} from 'react-toastify';
import uppercase from 'lodash/upperCase';

const filterOptions = [
  {
    title: 'Status',
    name: 'status',
    values: [
      {
        label: 'Pending',
        value: 'pending',
        name: 'pending',
      },
      {
        label: 'Verified',
        value: 'verified',
        name: 'verified',
      },
      {
        label: 'Not Verified',
        value: 'not Verified',
        name: 'not Verified',
      },
      {
        label: 'Failed',
        value: 'failed',
        name: 'failed',
      },
    ],
  },
];

const tableHeader = [
  {
    label: 'Name',
    key: 'first_name',
  },
  {
    label: 'Phone number',
    key: 'phone_number',
  },
  {
    label: 'Verification type',
    key: 'model_type',
  },
  {
    label: 'status',
    key: 'status',
  },
  {
    label: 'Date created',
    key: 'date_created',
  },
];

const initialFilters = {
  start: '',
  end: '',
  status: '',
};

function AddressVerification({auth: {userDetails}}) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openVerificationModal, setOpenVerificationModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedData, setSelectedData] = useState(null);
  const [filters, setFilters] = useState(initialFilters);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const {data: addressVerifications, isLoading} = useQuery(
    ['address-verifications', {page: currentPage, limit, ...filters}],
    () =>
      fetchAddressVerifications({
        page: currentPage,
        limit,
        ...filters,
      }),
    {
      onSuccess: data => {
        toast.success(data.message);
        setFilters({
          ...filters,
          download: undefined,
        });
      },
      onError: error => {
        toast.error(error.response.data.error);
      },
    },
  );

  const {
    data: addressVerificationSummary,
    isLoading: summaryLoading,
    refetch: refetchSummary,
  } = useQuery('address-verification-summary', fetchAddressVerificationSummary);

  const refreshSummary = () => {
    refetchSummary();
  };

  const completedCount = parseInt(
    (
      addressVerificationSummary?.data.find(
        item => item.status === 'completed',
      ) || {}
    ).count || 0,
  );

  const failedCount = parseInt(
    (
      addressVerificationSummary?.data.find(item => item.status === 'failed') ||
      {}
    ).count || 0,
  );

  const pendingCount = parseInt(
    (
      addressVerificationSummary?.data.find(
        item => item.status === 'pending',
      ) || {}
    ).count || 0,
  );
  const totalCount = completedCount + failedCount + pendingCount;

  const openModalWithSelectedData = data => {
    setSelectedData(data);
    setOpenVerificationModal(true);
  };

  const closeModal = () => {
    setOpenVerificationModal(false);
  };

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const data = !isLoading && addressVerifications?.data;

  useEffect(() => {
    const apiParams = parseUrlParams();
    apiParams.limit = limit;

    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(pageParam);
    }

    const statusParam = searchParams.get('status');
    if (statusParam) {
      apiParams.status = statusParam;
    }

    if (
      !apiParams.start &&
      !apiParams.end &&
      (!apiParams.status || apiParams.status === '')
    ) {
      setFiltersApplied(false);
    } else {
      setFiltersApplied(true);
    }

    if (Object.keys(apiParams).length > 1) {
      setFilters(apiParams);
    }
  }, [limit]);

  const handleFilter = (selectedOptions, shouldDownload = false) => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    const queryParams = generateQueryParams(filteredOptions);

    const {status} = filteredOptions;

    if (Array.isArray(status)) {
      filteredOptions.status = status.join(',');
    }

    setCurrentPage(1);
    let baseUrl = '';

    if (queryParams) {
      baseUrl += `?${queryParams}`;
    }

    if (shouldDownload) {
      filteredOptions.download = true;
      setFilters({
        ...filters,
        ...filteredOptions,
      });
    } else {
      navigate(baseUrl);
    }

    setFilters(filteredOptions);
    setFiltersApplied(true);
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    navigate('');
    setCurrentPage(1);
    setFilters(initialFilters);
    setFiltersApplied(false);
    setOpenFilter(true);
  };

  const {performIndividualLookupAndViewHistory, downloadIndividualHistory} =
    useMemo(
      () => userDetails?.permissions?.addressVerificationPermissions,
      [userDetails],
    ) || {};

  return (
    <DashboardLayout
      bg="bg-white"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img
            src={addressVerificationBreadcrumb}
            alt=""
            width={18}
            height={18}
          />
          <p className="ml-2 -mb-1">Address Verification</p>
        </div>
      }
    >
      <ViewVerificationModal
        open={openVerificationModal}
        setOpen={closeModal}
        selectedData={selectedData}
      />

      <VerificationFormModal
        open={open}
        setOpen={setOpen}
        onVerificationSuccess={refreshSummary}
      />

      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        filterOptions={filterOptions}
      />
      <div className="flex pt-5 flex-col items-start justify-between gap-6 mt-4 mb-6 md:flex-row md:items-center md:gap-0 sm:mt-0">
        <h2 className="text-base font-medium text-grey sm:text-lg">
          Address Verification
        </h2>

        <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
          <FilterButton openFilter={openFilter} setOpenFilter={setOpenFilter} />
          <div className="flex flex-wrap items-center gap-4 ">
            <PrimaryButton
              buttonText="Export"
              className="w-[100px]"
              onClick={() => handleFilter(filters, true)}
              disabled={!downloadIndividualHistory}
            />
          </div>

          <PrimaryButton
            buttonText="Submit Address"
            xPadding="px-[35px]"
            onClick={() => setOpen(!open)}
            className="hidden h-12 sm:flex min-w-[180px]"
            disabled={!performIndividualLookupAndViewHistory}
          />

          <div
            className="fixed bottom-0 left-0 right-0 z-10 px-4 py-2 overflow-x-hidden sm:hidden bg-white80"
            style={{
              boxShadow: '0px -1px 8px rgba(51, 52, 66, 0.25)',
            }}
          >
            <PrimaryButton
              buttonText="Submit Address"
              xPadding="p-[35px]"
              onClick={() => setOpen(!open)}
              className="w-full bottom-2"
              disabled={!performIndividualLookupAndViewHistory}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-9 overflow-x-auto whitespace-nowrap">
        <Card
          img={apiCalls}
          title="Total"
          body={totalCount}
          loading={summaryLoading}
        />
        <Card
          img={pending}
          title="Pending"
          body={pendingCount}
          loading={summaryLoading}
        />
        <Card
          img={successful}
          title="Completed"
          body={completedCount}
          loading={summaryLoading}
        />
        <Card
          img={failed}
          title="Failed"
          body={failedCount}
          loading={summaryLoading}
        />
      </div>

      <AddressLayout>
        <>
          {!performIndividualLookupAndViewHistory ? (
            <EmptyState
              height={45}
              body="Opps!! sorry, you do not have access to this resource"
              src={lookupEmpty}
              noBtn
            />
          ) : data?.length === 0 && !isLoading ? (
            <EmptyState
              height={45}
              body="Perform multiple address verification with ease"
              src={lookupEmpty}
              noBtn
              customBtn={
                <PrimaryButton
                  buttonText="Submit Address"
                  className="w-fit"
                  onClick={() => setOpen(!open)}
                  disabled={!performIndividualLookupAndViewHistory}
                />
              }
            />
          ) : data?.length === 0 && !isLoading && filtersApplied ? (
            <EmptyState
              body={
                'This filter did not return any data, try using different values.'
              }
              src={corruptedFile}
              noBtn
              customBtn={
                <button
                  onClick={handleResetFilter}
                  className="text-brandBlue p-4 text-sm font-medium"
                >
                  Update preferences
                </button>
              }
            />
          ) : (
            <>
              {isLoading ? (
                <Loader height={50} />
              ) : (
                performIndividualLookupAndViewHistory && (
                  <TableLayout negativeMargins className="mt-8">
                    <thead className="text-xs font-semibold uppercase">
                      <tr className="bg-white80">
                        {tableHeader.map(({label, key}) => (
                          <th
                            key={key}
                            className={`${
                              label === 'Name'
                                ? 'p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap'
                                : 'p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap'
                            }`}
                          >
                            <div className="flex items-end gap-1 cursor-pointer">
                              <span>{label}</span>
                            </div>
                          </th>
                        ))}

                        <th></th>
                      </tr>
                    </thead>

                    <tbody className="text-sm">
                      {data?.map(verification => (
                        <tr
                          className="border-b border-grey60"
                          key={verification.id}
                        >
                          <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                            {verification.first_name} {verification.last_name}
                          </td>
                          <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                            {verification.mobile ?? '-'}
                          </td>
                          <td className="p-4 pl-0 whitespace-nowrap">
                            <div className="px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full text-success bg-success20 w-fit">
                              individual
                            </div>
                          </td>
                          <td className="p-4 pl-0 whitespace-nowrap">
                            <div
                              className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit ${getStatusColor(
                                verification.status,
                              )}`}
                            >
                              {uppercase(verification.status)}
                            </div>
                          </td>
                          <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                            {moment(verification?.date_created).format(
                              'Do MMM, YYYY h:mm A',
                            )}
                          </td>
                          <td className="p-4 pl-0 whitespace-nowrap">
                            <div className="flex items-center gap-4">
                              {verification.status === 'pending' ? null : (
                                <button
                                  title="View"
                                  onClick={() =>
                                    openModalWithSelectedData(verification)
                                  }
                                  className="h-4 aspect-square"
                                >
                                  <img src={eye} alt="" />
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </TableLayout>
                )
              )}

              {data?.length > 0 && (
                <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
                  <div className="flex items-center text-body text-tiny">
                    <PageLimit
                      onLimitChange={setLimit}
                      total={
                        addressVerifications &&
                        addressVerifications?.totalRecords
                      }
                      length={data?.length}
                      limit={limit}
                    />
                  </div>

                  <div className="mb-8 sm:mb-0">
                    <Pagination
                      total={
                        data &&
                        Math.ceil(addressVerifications?.totalRecords / limit)
                      }
                      current={+currentPage}
                      onPageChange={activePage => {
                        pageClick(activePage);
                        const searchParams = new URLSearchParams(
                          document.location.search,
                        );
                        searchParams.set('page', activePage);
                        const newSearchParams = searchParams.toString();
                        navigate(`?${newSearchParams}`);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      </AddressLayout>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(AddressVerification);
