import React, {useContext, useEffect, useState} from 'react';
import Select from 'react-select';
import Layout from './Layout';
import {eye} from '../../assets/images/images';
import {
  RowData,
  DropdownIndicator,
  customStyles,
  Accordion,
  Loader,
} from '../../components';
import CreditPerformanceModal from './CreditPerformanceModal';
import CreditHistoryModal from './CreditHistoryModal';
import {PageContext} from '../../helpers/context';
import {useQuery} from 'react-query';
import {fetchChecksByEntity} from '../../requests/queries/creditChecks';
import {useParams} from 'react-router-dom';
import {CreditCheckEmptyState} from '../../components/CreditCheckEmptyState';

// import DATA from '../../credit_bureau.json';
import {formatNumberToCurrency} from '../../helpers/formatNumberToCurrency';

const processScoreData = (source, type, data, keys) =>
  data &&
  Object.entries(data?.entity.score || {})
    .map(([key, value]) => {
      if (Array.isArray(value) && value !== undefined && keys.includes(key)) {
        const foundValue = value.find(v => v?.source === source)?.value;
        return type === 'summary'
          ? {[key]: foundValue}
          : {[source]: {[key]: foundValue}}[source]?.[type];
      }
    })
    .filter(Boolean);

const keysForData = [
  'loanPerformance',
  'loanHistory',
  'employmentHistory',
  'creditEnquiries',
];

const keysForCreditSummary = [
  'totalBorrowed',
  'totalOutstanding',
  'totalOverdue',
  'totalNoOfInstitutions',
  'totalNoOfActiveLoans',
  'totalNoOfClosedLoans',
  'totalNoOfDelinquentFacilities',
  'totalNoOfPerformingLoans',
];

const getData = (source, type, data) =>
  processScoreData(source, type, data, keysForData);
const getCreditSummary = (source, data) =>
  processScoreData(source, 'summary', data, keysForCreditSummary);

export default function EasyCreditSummary() {
  const source = [
    {value: 'CRC', label: 'CRC'},
    {value: 'FIRST_CENTRAL', label: 'FIRST_CENTRAL'},
    {value: 'CREDIT_REGISTRY', label: 'CREDIT_REGISTRY'},
  ];
  const [openCreditPerformanceModal, setOpenCPM] = useState(false);
  const [openCreditHistoryModal, setOpenCHM] = useState(false);
  const [selectedSource, setSelectedSource] = useState(source[0]);
  const [details, setDetails] = useState({});
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('creditcheck');
  }, [setPageName]);

  const {slug} = useParams();

  const {data: creditCheck, isLoading: creditCheckLoading} = useQuery(
    [
      'credit_check_check',
      {customer_id: slug, entity_type: 'credit_check_check'},
    ],
    () =>
      fetchChecksByEntity({
        customer_id: slug,
        entity_type: 'credit_check_check',
      }),
    {
      retry: 1,
    },
  );

  const LOAN_PERFORMANCE = getData(
    selectedSource?.value,
    'loanPerformance',
    creditCheck,
  );
  const EMPLOYMENT_HISTORY = getData(
    selectedSource?.value,
    'employmentHistory',
    creditCheck,
  );

  const LOAN_HISTORY = getData(
    selectedSource?.value,
    'loanHistory',
    creditCheck,
  );

  function separateCamelCaseWithCaps(text) {
    return text
      .replace(/([A-Z])/g, ' $1')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const CREDIT_SUM_DATA = getCreditSummary(selectedSource?.value, creditCheck);

  const CreditBreakdown = () =>
    CREDIT_SUM_DATA &&
    CREDIT_SUM_DATA?.map(data =>
      Object.entries(data || {})?.map(([key, value], i) => {
        if (key && value) {
          return (
            <RowData
              key={i}
              leftText={separateCamelCaseWithCaps(key)}
              rightText={
                separateCamelCaseWithCaps(key)?.includes('No')
                  ? value.toLocaleString()
                  : formatNumberToCurrency(value)
              }
              boldFont
            />
          );
        }
      }),
    );

  const CREDIT_PERFORMANCE =
    (LOAN_PERFORMANCE?.length > 0 &&
      LOAN_PERFORMANCE[0]?.map(loan => ({
        leftText: loan?.loanProvider,
        rightText: loan?.loanAmount,
      }))) ||
    [];
  const CREDIT_HISTORY =
    (LOAN_HISTORY?.length > 0 &&
      LOAN_HISTORY[0]?.map(loan => ({
        leftText: loan?.loanProvider,
        rightText: loan?.performanceStatus,
      }))) ||
    [];
  const EMPLOYMENT_HISTORY_DATA =
    (EMPLOYMENT_HISTORY?.length > 0 &&
      EMPLOYMENT_HISTORY[0]?.map(history => ({
        leftText: {
          employerName: history?.employerName || '-',
          address: history?.address || '-',
        },
        rightText: {
          dateReported: history?.dateReported || '-',
          position: history?.position || history?.occupation || '-',
        },
      }))) ||
    [];

  const fullDetails = Object.entries(details || {})
    .map(
      ([key, value]) =>
        value &&
        typeof value !== 'object' && {
          title: separateCamelCaseWithCaps(key),
          value,
        },
    )
    .filter(Boolean);
  return (
    <Layout>
      <CreditPerformanceModal
        open={openCreditPerformanceModal}
        setOpen={setOpenCPM}
        details={fullDetails}
        title={details?.loanProvider}
      />
      <CreditHistoryModal
        details={fullDetails}
        open={openCreditHistoryModal}
        setOpen={setOpenCHM}
        title={details?.loanProvider}
      />
      {creditCheckLoading ? (
        <Loader height={35} />
      ) : !creditCheckLoading && !creditCheck ? (
        <CreditCheckEmptyState
          bodyText={`We did not find any credit summary on this person`}
        />
      ) : (
        <>
          <div className="creditcheck-select">
            <Select
              defaultValue={selectedSource}
              value={selectedSource}
              onChange={setSelectedSource}
              options={source}
              placeholder="Select mode of assessment"
              components={{DropdownIndicator}}
              classNamePrefix="react-select"
              className="ml-auto"
              styles={customStyles}
              menuPlacement="auto"
              id="assessment"
            />
          </div>
          <div className="mt-6 flex flex-col gap-3">
            <Accordion title="Credit Breakdown" border>
              <div className="flex flex-col mt-4">
                {CREDIT_SUM_DATA.length === 0 ? (
                  <CreditCheckEmptyState
                    bodyText={
                      'We did not find any credit information on this person'
                    }
                  />
                ) : (
                  <CreditBreakdown />
                )}
              </div>
            </Accordion>
            <Accordion title="Credit Performance" border>
              {CREDIT_PERFORMANCE?.length === 0 ? (
                <CreditCheckEmptyState
                  bodyText={`We did not find any credit performance - ${selectedSource?.value
                    ?.toLowerCase()
                    ?.replaceAll('_', ' ')} on this person`}
                />
              ) : (
                <div className="flex flex-col mt-4">
                  {CREDIT_PERFORMANCE?.map(({leftText, rightText}, i) => (
                    <RowData
                      key={i}
                      leftText={leftText}
                      boldFont
                      customUI={
                        <div className="flex items-center gap-6">
                          <span className="text-sm text-body whitespace-pre-line text-end font-bold -mb-1">
                            {rightText}
                          </span>
                          <button
                            onClick={() => {
                              setOpenCHM(true);
                              setDetails(LOAN_PERFORMANCE[0][i]);
                            }}
                          >
                            <img src={eye} alt="view" width={12} height={12} />
                          </button>
                        </div>
                      }
                    />
                  ))}
                </div>
              )}
            </Accordion>
            <Accordion title="Credit History" border>
              <div className="flex flex-col mt-4">
                {CREDIT_HISTORY?.map(({leftText, rightText}, i) => (
                  <RowData
                    key={i}
                    leftText={leftText}
                    customUI={
                      <div className="flex items-center gap-6">
                        <div
                          className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit ${
                            rightText?.toLowerCase() === 'performing'
                              ? 'text-success bg-success20'
                              : rightText?.toLowerCase() === 'lost'
                              ? 'text-danger bg-statusBg-404'
                              : 'text-grey80 bg-grey60'
                          } `}
                        >
                          {rightText}
                        </div>
                        <button
                          onClick={() => {
                            setOpenCHM(true);
                            setDetails(LOAN_HISTORY[0][i]);
                          }}
                        >
                          <img src={eye} alt="view" width={12} height={12} />
                        </button>
                      </div>
                    }
                  />
                ))}
              </div>
            </Accordion>
            <Accordion title="Employment History" border>
              {EMPLOYMENT_HISTORY_DATA.length === 0 ? (
                <CreditCheckEmptyState
                  bodyText={`We did not find any Employment History - ${selectedSource?.value
                    ?.toLowerCase()
                    ?.replaceAll('_', ' ')} on this person`}
                />
              ) : (
                <div className="flex flex-col mt-4">
                  {EMPLOYMENT_HISTORY_DATA?.map(({leftText, rightText}, i) => (
                    <RowData
                      paddingY="py-2"
                      key={i}
                      leftCustomUI={
                        <div className="flex flex-col gap-3">
                          <p className="text-xs font-medium uppercase text-grey">
                            {leftText.employerName}
                          </p>
                          <p className="text-xs   text-grey">
                            {leftText.address}
                          </p>
                        </div>
                      }
                      customUI={
                        <div className="flex flex-col gap-3">
                          <p className="text-xs   text-grey">
                            {rightText.position}
                          </p>
                          <p className="text-xs   text-grey">
                            {rightText.dateReported}
                          </p>
                        </div>
                      }
                      boldFont
                    />
                  ))}
                </div>
              )}
            </Accordion>
          </div>
        </>
      )}
    </Layout>
  );
}
