import {useQuery} from 'react-query';
import {fetchBanksCodes} from '../requests/queries/lookup';
import {useMemo} from 'react';

export const useBankCodes = () => {
  const {data, isLoading} = useQuery('bank-codes', () => fetchBanksCodes());

  const banks = useMemo(
    () => !isLoading && data && data?.entity,
    [data, isLoading],
  );

  const formattedBanks = useMemo(
    () =>
      !isLoading &&
      banks &&
      banks?.map(bank => ({
        label: bank?.name,
        value: bank?.code,
      })),
    [banks, isLoading],
  );

  return {banks: formattedBanks, isLoading};
};
