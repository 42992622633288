/* eslint-disable  react-hooks/exhaustive-deps */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {
  allowedEvents,
  blockedEvents,
  dashboardIc,
  easyOnboardIc,
  pendingEvents,
  totalEvents as totalIcon,
} from '../../../assets/images/images';
import {Card, DashboardLayout} from '../../../components';
import {DashboardPieChartLeft, DashboardPieChartRight} from './DonutCharts';
import LineChart from './LineChart';
import * as Actions from '../../../store/actions';
import {convertToPercentage} from '../../../helpers/convertToPercentage';
import {PageContext} from '../../../helpers/context';

function Overview({
  getTotalEvents,
  getTotalAllowed,
  getTotalPending,
  getTotalBlocked,
  getOverviewGraph,
  getFlows,
  flows: {
    totalEvents,
    overviewGraph,
    totalAllowed,
    totalPending,
    totalBlocked,
    loading,
    flows,
    graphLoading,
  },
}) {
  const [filters, setFilters] = useState({
    start: null,
    end: null,
  });

  const [load, setLoad] = useState(false);

  const TOTAL_EVENTS = useMemo(() => totalEvents?.data?.result, [totalEvents]);

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  const TOTAL_ALLOWED = useMemo(
    () => totalAllowed?.data?.result,
    [totalAllowed],
  );
  const TOTAL_PENDING = useMemo(
    () => totalPending?.data?.result,
    [totalPending],
  );
  const TOTAL_BLOCKED = useMemo(
    () => totalBlocked?.data?.result,
    [totalBlocked],
  );

  const TOTAL_RESOLVED = useMemo(
    () =>
      !isNaN(TOTAL_ALLOWED?.content + TOTAL_BLOCKED?.content)
        ? TOTAL_ALLOWED?.content + TOTAL_BLOCKED?.content
        : 0,
    [TOTAL_BLOCKED, TOTAL_ALLOWED],
  );

  const TOTAL_FLAGGED = useMemo(
    () =>
      !isNaN(
        TOTAL_ALLOWED?.content +
          TOTAL_BLOCKED?.content +
          TOTAL_PENDING?.content,
      )
        ? TOTAL_ALLOWED?.content +
          TOTAL_BLOCKED?.content +
          TOTAL_PENDING?.content
        : 0,
    [TOTAL_BLOCKED, TOTAL_ALLOWED, TOTAL_PENDING],
  );

  const TOTAL_NON_FLAGGED = useMemo(
    () => TOTAL_EVENTS?.content - TOTAL_FLAGGED,
    [TOTAL_EVENTS, TOTAL_FLAGGED],
  );

  useEffect(() => {
    getFlows({page: 1, limit: 1000});
    setLoad(flows?.flows?.length > 0);
  }, [getFlows]);

  useEffect(() => {
    if (load) {
      getTotalEvents();
      getTotalAllowed();
      getTotalPending();
      getTotalBlocked();
      setLoad(false);
    }
    setLoad(flows?.flows?.length > 0);
  }, [
    getTotalEvents,
    getTotalAllowed,
    getTotalPending,
    getTotalBlocked,
    load,
    !!flows,
  ]);

  const filterStructure = useCallback(
    (key, alias) =>
      filters[key]
        ?.split(',')
        ?.map(value => `&${alias || key}=${value}`)
        ?.join(''),
    [filters],
  );

  useEffect(() => {
    if (!loading && flows?.flows?.length > 0) {
      getOverviewGraph({...filters, flow: filterStructure('flow')});
    }
  }, [filters, getOverviewGraph, filterStructure]);

  const graphContent = useMemo(
    () => overviewGraph?.data?.result?.content,
    [overviewGraph],
  );

  return (
    <DashboardLayout
      bg="bg-white"
      topPadding="pt-16 sm:pt-6"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={dashboardIc} alt="" />
          <p className="ml-2 -mb-1">Overview</p>
        </div>
      }
    >
      <>
        <div className="flex items-center justify-between">
          <h1 className="text-grey text-xl font-medium">Overview</h1>
        </div>

        <div className="flex flex-col lg:flex-row items-center gap-4 my-6">
          <Card
            className="w-full"
            img={totalIcon}
            title="Total Events"
            body={TOTAL_EVENTS?.content || 0}
            loading={loading}
          />
          <Card
            className="w-full"
            img={pendingEvents}
            title="Pending Cases"
            body={TOTAL_PENDING?.content || 0}
            loading={loading}
          />
          <Card
            className="w-full"
            img={allowedEvents}
            title="Allowed Cases"
            body={TOTAL_ALLOWED?.content || 0}
            loading={loading}
          />
          <Card
            className="w-full"
            img={blockedEvents}
            title="Blocked Cases"
            body={TOTAL_BLOCKED?.content || 0}
            loading={loading}
          />
        </div>

        <div className="mb-4">
          <p className="text-grey text-lg font-medium">Cases</p>
        </div>

        <LineChart
          loading={graphLoading}
          setFilters={setFilters}
          graphContent={graphContent}
          flows={flows}
        />

        <div className="flex gap-6 mt-8 basis-full">
          <div className="bg-white80 rounded-lg pt-[26px] px-3 sm:px-[22px] pb-5 basis-full sm:basis-1/2">
            <h3 className="mb-4 text-lg font-medium text-center sm:text-left text-grey">
              Resolved cases
            </h3>
            <div className="flex flex-col items-center gap-3 sm:flex-row">
              <DashboardPieChartLeft
                allowedPercentage={TOTAL_ALLOWED?.content}
                blockedPercentage={TOTAL_BLOCKED?.content}
                total={TOTAL_RESOLVED}
                loading={loading}
              />
              <div className="flex gap-2 sm:gap-8 sm:ml-6 sm:flex-col">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 rounded-full bg-status-400" />
                  <div className="flex flex-col gap-[5px]">
                    <p className="text-xs font-medium uppercase text-body">
                      Allowed
                    </p>
                    <p className="text-xl font-medium text-grey">
                      {convertToPercentage(
                        TOTAL_ALLOWED?.content,
                        TOTAL_RESOLVED,
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 rounded-full bg-brandBlue" />
                  <div className="flex flex-col gap-[5px]">
                    <p className="text-xs font-medium uppercase text-body">
                      Blocked
                    </p>
                    <p className="text-xl font-medium text-grey">
                      {convertToPercentage(
                        TOTAL_BLOCKED?.content,
                        TOTAL_RESOLVED,
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white80 rounded-lg pt-[26px] px-[22px] pb-5 basis-1/2 hidden md:block">
            <h3 className="mb-4 text-lg font-medium text-grey">Total events</h3>
            <div className="flex items-center gap-12">
              <DashboardPieChartRight
                flaggedPercentage={TOTAL_EVENTS?.content}
                nonFlaggedPercentage={TOTAL_NON_FLAGGED}
                flagged={TOTAL_FLAGGED}
                loading={loading}
              />
              <div className="flex gap-2 sm:gap-8 sm:ml-6 sm:flex-col">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 rounded-full bg-status-404" />
                  <div className="flex flex-col gap-[5px]">
                    <p className="text-xs font-medium uppercase text-body">
                      FLAGGED events
                    </p>
                    <p className="text-xl font-medium text-grey">
                      {convertToPercentage(
                        TOTAL_FLAGGED,
                        TOTAL_EVENTS?.content,
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 rounded-full bg-red" />
                  <div className="flex flex-col gap-[5px]">
                    <p className="text-xs font-medium uppercase text-body">
                      NON FLAGGED events
                    </p>
                    <p className="text-xl font-medium text-grey">
                      {convertToPercentage(
                        TOTAL_NON_FLAGGED,
                        TOTAL_EVENTS?.content,
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(Overview);
