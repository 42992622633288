import React, {useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {
  DashboardLayout,
  EmptyState,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  SkeletonLoader,
  TableLayout,
} from '../../../components';
import {
  baasMobile,
  backNofill,
  corruptedFile,
  netCashflowBaas,
  totalInflowBaas,
  totalOutflowBaas,
} from '../../../assets/images/images';
import {Link, useNavigate, useParams} from 'react-router-dom';
import BaasTransactionModal from './BaasTransactionModal';
import {
  fetchBAASCompanyWallet,
  fetchBAASTransactionAnalysis,
  fetchBAASTransactions,
} from '../../../requests/queries/baas';
import {getInitials} from '../../../helpers';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';
import {useAllApps} from '../../../hooks/useApps';
import moment from 'moment/moment';
import {getStatusTextAndStyles} from '../../../helpers/getStatusTextAndStyles';

function BaasAccountDetails() {
  const {slug} = useParams();
  const [openFilter, setOpenFilter] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(false);
  const {userApps, isLoading: appsLoading} = useAllApps();
  const navigate = useNavigate();

  function shortenText(text, maxLength = 0) {
    if (text.length <= maxLength) return text;
    return `${text.slice(0, maxLength - 5)}......${text.slice(-3)}`;
  }

  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });

  const appId = useMemo(
    () => (!appsLoading && userApps.length > 0 ? userApps[0]?.value : ''),
    [appsLoading, userApps],
  );
  const toggleTransactionsModal = () =>
    setTransactionDetails(prevState => !prevState);

  const params = useMemo(
    () => ({
      wallet_id: slug,
      ...(appId && {app_id: appId}),
    }),
    [slug, appId],
  );

  const {data: wallets_details, isLoading} = useQuery(
    ['baas-wallet-details', {params}],
    () => fetchBAASCompanyWallet({params}),
  );

  const {data: transaction_analysis, isLoading: analysisLoading} = useQuery(
    ['baas-transaction-analysis', {params}],
    () => fetchBAASTransactionAnalysis({params}),
  );

  const {data: wallet_transactions, isLoading: transactionsLoading} = useQuery(
    ['wallet-transactions', {params}],
    () => fetchBAASTransactions({params}),
    {enabled: appId?.length > 0},
  );

  const wallet_details = useMemo(
    () => !isLoading && wallets_details && wallets_details?.entity,
    [wallets_details, isLoading],
  );
  const walletTransactions = useMemo(
    () =>
      !transactionsLoading &&
      wallet_transactions &&
      wallet_transactions?.entity,
    [wallet_transactions, transactionsLoading],
  );

  const transactionAnalysis = useMemo(
    () =>
      !analysisLoading &&
      transaction_analysis &&
      transaction_analysis?.entity?.[0],
    [analysisLoading, transaction_analysis],
  );

  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };

  const flows = [
    {
      icon: totalInflowBaas,
      alt: 'in-flow',
      title: 'Total Inflow',
      value: transactionAnalysis?.sum_of_deposits,
    },
    {
      icon: totalOutflowBaas,
      alt: 'out-flow',
      title: 'Total Outflow',
      value: transactionAnalysis?.sum_of_transfers,
    },
    {
      icon: netCashflowBaas,
      alt: 'transaction-count',
      title: 'Total Transaction Count',
      value:
        (+transactionAnalysis?.deposit_count || 0) +
        (+transactionAnalysis?.transfer_count || 0),
      count: true,
    },
  ];

  const accountDetails = [
    {
      label: 'Current Balance',
      value: +transactionAnalysis?.opening_balance || 0,
    },
    {
      label: 'Average Closing Balance',
      value: +transactionAnalysis?.closing_balance || 0,
    },
    {
      label: 'Total Number of Transactions',
      value: walletTransactions?.transactions?.length || 0,
      count: true,
    },
    {
      label: 'Total Fees Charged',
      value: '₦14,260,000',
    },
  ];

  // const transactions = [
  //   {
  //     dateCreated: '9th, June 2024, 4:09am',
  //     refId: 'TNX53726535626828..2',
  //     bankName: 'GTBank',
  //     amount: '₦95,678.00',
  //     type: 'debit',
  //     balance: '₦95,678.00',
  //     status: 'successful',
  //   },
  // ];

  return (
    <DashboardLayout
      bg="bg-white"
      topPadding="pt-8"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={baasMobile} alt="" />
          <p className="ml-2 -mb-1">Wallets {'>'} Wallet Detail</p>
        </div>
      }
    >
      <div className="flex items-center justify-between mb-4 mt-10 lg:mt-0">
        <Link
          to={''}
          onClick={() => navigate(-1)}
          className="flex items-center gap-2"
        >
          <img src={backNofill} alt="" />
          <span className="text-sm text-grey80 font-medium -mb-1">Back</span>
        </Link>
      </div>
      <div className="flex items-start lg:items-center lg:flex-row flex-col gap-5 justify-between p-5 lg:p-0">
        <p className="text-grey font-medium text-[18px]">Account details</p>

        <div className="flex items-center gap-2">
          <FilterButton
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            className="h-[48px] justify-center w-[130px]"
          />
        </div>
      </div>

      <BaasTransactionModal
        show={transactionDetails}
        onClose={toggleTransactionsModal}
      />
      <div className="bg-white80 lg:mt-11 rounded-xl lg:py-5 lg:px-6 flex flex-col lg:flex-row lg:items-center gap-10">
        <div className="flex flex-col lg:flex-row lg:items-center gap-4 lg:gap-0">
          {isLoading ? (
            <SkeletonLoader
              style={{
                width: 75,
                height: 75,
                marginRight: 10,
                marginTop: 10,
                borderRadius: '100%',
              }}
            />
          ) : (
            <div className="bg-white rounded-full mr-4 w-[68px] h-[68px] border border-[#ECECEC] flex items-center justify-center">
              <p className="text-deepBlue font-medium text-xl">
                {getInitials(wallet_details?.account_name) || '-'}
              </p>
            </div>
          )}

          {isLoading ? (
            <SkeletonLoader
              style={{
                width: 175,
                height: 35,
                marginTop: 20,
              }}
            />
          ) : (
            <div className="flex flex-col">
              <h3 className="text-deepBlue font-semibold text-xl">
                {wallet_details?.account_name || '-'}
              </h3>

              <p className="text-[#4E4E4E] text-sm">
                {wallet_details?.account_number || '-'}
              </p>
            </div>
          )}
        </div>

        <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 flex-1 mt-4">
          {flows.map(({icon, alt, title, value, count}, index) => (
            <div
              key={title}
              className="bg-white py-6 pl-6 pr-2 rounded-lg flex items-center gap-2 w-full"
            >
              <img src={icon} alt={alt} />
              <div className="flex flex-col gap-1 relative">
                <p className="text-small text-body">{title}</p>
                {analysisLoading ? (
                  <SkeletonLoader
                    style={{
                      width: 150 * Math.random(index + 10),
                      height: 20,
                      position: 'absolute',
                      top: 20,
                      left: 0,
                    }}
                  />
                ) : (
                  <h1 className="text-lg font-semibold text-grey">
                    {!count
                      ? shortenLargeNumbers(+value || 0, 'NGN', {startAt: 'M'})
                      : value}
                  </h1>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-4 p-6 rounded-xl bg-white80">
        {accountDetails.map(({label, value, count}, index) => (
          <div key={label}>
            <div className="flex items-center justify-between">
              <p className="text-tiny text-[#868C98]">{label}</p>
              <p className="text-tiny text-black font-medium">
                {!count
                  ? shortenLargeNumbers(+value || 0, 'NGN', {startAt: 'M'})
                  : value}
              </p>
            </div>
            {index !== accountDetails.length - 1 && (
              <hr className="my-4 text-[#E1E4EA]" />
            )}
          </div>
        ))}
      </div>

      {transactionsLoading ? (
        <Loader height={35} />
      ) : walletTransactions?.total === 0 ? (
        <EmptyState
          height={45}
          src={corruptedFile}
          noBtn
          body="No data found"
        />
      ) : (
        <div className="mt-4">
          <TableLayout negativeMargins className="mt-6 w-full">
            <thead className="text-small font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                  date created
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  ref id
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  bank name
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  amount
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  type
                </th>{' '}
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  balance
                </th>{' '}
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  status
                </th>
              </tr>
            </thead>
            <tbody className="text-sm text-body">
              {!isLoading &&
                walletTransactions?.transactions.map((transaction, index) => (
                  <tr
                    key={index}
                    className="text-left border-b bg-white  border-grey60"
                    // onClick={toggleTransactionsModal}
                  >
                    <td className="p-4 pl-12 whitespace-nowrap">
                      {(transaction?.date_created &&
                        moment(transaction?.date_created)?.format(
                          'DD MMM YYYY h:m',
                        )) ||
                        '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {shortenText(transaction?.transaction_id || '--', 21) ||
                        '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {transaction?.recipient_bank_name || '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {shortenLargeNumbers(
                        transaction?.transaction_amount || 0,
                        'NGN',
                        {startAt: 'M'},
                      )}{' '}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      <span className="uppercase font-inter w-fit rounded-full px-2 py-1 h-5 flex items-center justify-center text-[#B42318] bg-[#F8EBEA] text-xs">
                        {transaction?.type || 'manual'}
                      </span>
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {shortenLargeNumbers(
                        transaction?.transaction_balance || 0,
                        'NGN',
                        {startAt: 'M'},
                      )}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      <span
                        className={` ${
                          getStatusTextAndStyles(
                            transaction?.transaction_status?.toLowerCase(),
                          )?.styles
                        } uppercase w-fit rounded-full px-2 py-1 h-5 font-inter flex items-center justify-center  text-xs`}
                      >
                        {
                          getStatusTextAndStyles(
                            transaction?.transaction_status?.toLowerCase(),
                          )?.text
                        }
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </TableLayout>
        </div>
      )}

      {!isLoading && walletTransactions?.transactions?.length > 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-12 sm:gap-0 pl-12 pr-6">
          <PageLimit
            onLimitChange={limit => {
              setQuery(prevState => ({
                ...prevState,
                limit,
                page: 1,
              }));
            }}
            limit={query?.limit}
            total={walletTransactions?.current_page}
            length={walletTransactions?.transactions?.length}
          />

          <Pagination
            total={walletTransactions?.total_pages}
            current={Number(walletTransactions?.current_page)}
            onPageChange={activePage => {
              pageClick(activePage);
              const searchParams = new URLSearchParams(
                document.location.search,
              );
              searchParams.set('page', activePage);
              const newSearchParams = searchParams.toString();
              navigate(`?${newSearchParams}`);
            }}
          />
        </div>
      )}
    </DashboardLayout>
  );
}

export default BaasAccountDetails;
