import React from 'react';
import {
  reconciliationAuthBg,
  reconciliationLogo,
} from '../../../../assets/images/images';
import {PrimaryButton} from '../../../../components';
import {Constants} from '../../../../helpers';

function ForgotPassword() {
  return (
    <div
      className="w-full h-full bg-cover bg-center bg-no-repeat min-h-screen"
      style={{backgroundImage: `url(${reconciliationAuthBg})`}}
    >
      <div className="py-[70px] px-5 lg:px-0">
        <div className="bg-white border m-auto border-batchBlue20 py-12 px-8 rounded-lg w-full md:w-[586px]">
          <div className="flex flex-col items-center gap-2 w-full">
            <img
              src={reconciliationLogo}
              alt="Dojah logo"
              className="w-[110px] h-[21px]"
            />
          </div>

          <div className="mt-8">
            <h2 className="text-deepBlue font-medium text-lg mb-[6px]">
              Reset your password
            </h2>
            <p className="text-sm text-body">
              Provide the email attached to your account
            </p>

            <form className="mt-6">
              <label htmlFor="email" className="block mt-4">
                Email address
                <input type="email" name="email" id="email" className="mt-2" />
              </label>

              <PrimaryButton
                buttonText="Reset Password"
                className="w-full mt-6"
              />
            </form>

            <a
              href={Constants.PAGES_URL.SIGNIN}
              className="mt-4 h-12 font-medium w-full flex items-center justify-center gap-2 p-4 border border-body rounded text-center"
            >
              <span className="text-sm text-grey font-inter font-medium">
                Return to log in
              </span>
            </a>

            <p className="mt-6 text-sm text-body">
              By clicking the “Sign Up” button, you are creating a Dojah
              account, and you agree to Dojah’s{' '}
              <span className="text-brandBlue">
                <a
                  href="https://dojah.io/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
              </span>{' '}
              and{' '}
              <span className="text-brandBlue">
                <a
                  href="https://dojah.io/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>
              </span>
            </p>

            <p className="text-sm font-medium text-body mt-6">
              New to this space?{' '}
              <a
                href={Constants.PAGES_URL.RECONCILIATION_SIGNUP}
                className="text-brandBlue"
              >
                Sign up
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
