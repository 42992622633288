// src/hooks/usePermissions.js
import {useMemo} from 'react';
import {useSelector} from 'react-redux';

export const usePermissions = () => {
  const {userDetails} = useSelector(state => state?.auth);
  const projectId =
    useMemo(() => sessionStorage.getItem('project_id'), []) || 'add-project';

  const USER_PERMISSIONS = useMemo(
    () => userDetails?.permissions,
    [userDetails],
  );
  const USER_COMPANY = useMemo(
    () => userDetails && userDetails?.company,
    [userDetails],
  );

  const permittedLinks = useMemo(
    () =>
      (permissions = {}) => {
        if (USER_PERMISSIONS?.role !== 'Owner') {
          return Object.values(permissions).some(value => value);
        }
        return true;
      },
    [USER_PERMISSIONS],
  );

  const permissions = useMemo(
    () => ({
      canViewReconciliation:
        USER_PERMISSIONS?.reconciliationPermissions?.reconciliation,
      canViewIdVerification:
        USER_PERMISSIONS?.onboardPermissions?.viewVerifications,
      canManageFlows: USER_PERMISSIONS?.detectPermissions?.manageFlows,
      manageDetectFlows: USER_PERMISSIONS?.detectPermissions?.manageFlows,
      reviewCases: USER_PERMISSIONS?.detectPermissions?.reviewCases,
      canReviewCases: USER_PERMISSIONS?.detectPermissions?.reviewCases,
      canViewHistory: USER_PERMISSIONS?.billingPermissions?.viewHistory,
      canViewApiCalls: USER_PERMISSIONS?.billingPermissions?.viewLogs,
      managePermissions:
        USER_PERMISSIONS?.settingsPermissions?.managePermissions,
      manageAuditLogs:
        USER_PERMISSIONS?.settingsPermissions?.viewAndDownloadAudit,
      canViewApiLogs: USER_PERMISSIONS?.developerPermissions?.viewAndExportLogs,
      canManageTokens: USER_PERMISSIONS?.developerPermissions?.manageTokens,
      canManageWebhooks: USER_PERMISSIONS?.developerPermissions?.manageWebhooks,
    }),
    [USER_PERMISSIONS],
  );

  const webhookPermission = useMemo(
    () =>
      USER_PERMISSIONS?.role !== 'Owner'
        ? USER_PERMISSIONS?.webhookPermissions?.viewHistory &&
          permissions?.canManageWebhooks
        : true,
    [USER_PERMISSIONS, permissions.canManageWebhooks],
  );

  const modularisation = useMemo(
    () => USER_COMPANY?.modularisation || {},
    [USER_COMPANY],
  );

  const is_partner_company = USER_COMPANY?.is_partner_company;

  return {
    USER_PERMISSIONS,
    permittedLinks,
    ...permissions,
    webhookPermission,
    projectId,

    modularisation: {...modularisation, is_partner_company},
  };
};
