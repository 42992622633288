import React from 'react';
import {Modal} from '../../../components';
import {closeModal} from '../../../assets/images/images';
// import Select from 'react-select';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';

function BaasTopUpModal({show, onClose, accountDetails}) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center justify-between w-full">
          <h3 className="font-medium text-base text-grey">Wallet Info</h3>

          <button
            onClick={() => {
              onClose(false);
            }}
            className="hidden sm:block"
          >
            <img src={closeModal} alt="" />
          </button>
        </div>

        <form className="mt-6">
          <label htmlFor="amount">
            <span className="text-sm text-body font-medium">Amount</span>
            <input
              type="text"
              name="amount"
              className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
              id="amount"
              placeholder="Input amount to top-up"
              value={shortenLargeNumbers(accountDetails?.amount || 0, 'NGN', {
                startAt: 'M',
              })}
              disabled
            />
          </label>

          <label htmlFor="account_number">
            <span className="text-sm text-body font-medium mt-6">
              Account number
            </span>
            <input
              type="text"
              name="account_number"
              className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
              id="account_number"
              placeholder="Input Account number"
              value={accountDetails?.account_number}
              disabled
            />
          </label>
          <p className="font-medium text-sm text-dojahBlue mt-2 text-start">
            {accountDetails?.account_name}
          </p>

          {/*<label*/}
          {/*  htmlFor="bank_name"*/}
          {/*  className="relative mt-6 text-body font-medium"*/}
          {/*>*/}
          {/*  Bank name*/}
          {/*</label>*/}
          {/*<Select*/}
          {/*  placeholder="Select bank"*/}
          {/*  classNamePrefix="react-select"*/}
          {/*  components={{DropdownIndicator}}*/}
          {/*  styles={customStyles}*/}
          {/*  className="text-start mt-2 add_project_select"*/}
          {/*  menuPlacement="auto"*/}
          {/*/>*/}

          {/*<div className="flex items-center mt-6">*/}
          {/*  <PrimaryButton*/}
          {/*    buttonText="Submit"*/}
          {/*    className="w-full rounded-lg"*/}
          {/*    type="submit"*/}
          {/*  />*/}
          {/*</div>*/}
        </form>
      </div>
    </Modal>
  );
}

export default BaasTopUpModal;
