import React, {useState} from 'react';
import {
  EmptyState,
  Loader,
  // PageLimit,
  // Pagination,
  PrimaryButton,
  SearchInput,
  // TableLayout,
} from '../../../../components';
import {
  // dropdownBlue,
  // dropdownGrey,
  profileIc,
} from '../../../../assets/images/images';
import Layout from '../Layout';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
// import moment from 'moment';
// import {formatNumberToCurrency} from '../../../../helpers/formatNumberToCurrency';
import accountDataArray from './generateDummyData';
import {Constants} from '../../../../helpers';

function Accounts() {
  const [loading] = useState(false);
  // const [currentPage, setCurrentPage] = useState('1');
  // const [limit, setLimit] = useState(10);
  const {state} = useLocation();

  const navigate = useNavigate();
  const {slug} = useParams();

  // const pageClick = selected => {
  //   setCurrentPage(selected);
  // };

  const ACCOUNTS_TRANSACTIONS =
    (state && state.transactions) || accountDataArray[0].transactions;

  return (
    <Layout pageTitle="Account - transactions" pageIcon={profileIc}>
      {loading ? (
        <Loader height={65} />
      ) : !loading && ACCOUNTS_TRANSACTIONS?.length === 0 ? (
        <EmptyState body="No event(s) found" noBtn height={65} />
      ) : (
        <section>
          <div className="flex items-center justify-between py-7 bg-white -ml-12 pl-12 -mr-4 xl:mr-[-76px] pr-7">
            <h2 className="hidden capitalize text-lg font-medium text-grey40 sm:block">
              Transactions <span className="lowercase">for</span>{' '}
              <span className="text-grey">
                {(state && state.name) || accountDataArray[0].name}
              </span>
            </h2>
            <div className="flex items-center gap-4">
              <SearchInput onChange={() => []} className="outline-white80" />
              <PrimaryButton
                buttonText="View Cases"
                onClick={() =>
                  navigate(
                    `${Constants.PAGES_URL.FLOWS_CASES.replace(
                      '/:slug',
                      '',
                    )}/${slug}`,
                  )
                }
              />
            </div>
          </div>
          <EmptyState body="No account(s) found" noBtn height={65} />
          {/*<TableLayout*/}
          {/*  negativeMargins*/}
          {/*  negativeRightMargin="-mr-4 xl:-mr-[76px]"*/}
          {/*>*/}
          {/*  <thead className="text-xs font-semibold uppercase">*/}
          {/*    <tr className="bg-white80">*/}
          {/*      <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">*/}
          {/*        <div className="flex items-end gap-1">*/}
          {/*          <span>Transaction ID</span>*/}
          {/*          <img src={dropdownBlue} alt="" width={16} height={16} />*/}
          {/*        </div>*/}
          {/*      </th>*/}
          {/*      <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">*/}
          {/*        <div className="flex items-end gap-1">*/}
          {/*          <span>Amount</span>*/}
          {/*          <img src={dropdownGrey} alt="" width={16} height={16} />*/}
          {/*        </div>*/}
          {/*      </th>*/}
          {/*      <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">*/}
          {/*        <div className="flex items-end gap-1">*/}
          {/*          <span>Gateway</span>*/}
          {/*          <img src={dropdownGrey} alt="" width={16} height={16} />*/}
          {/*        </div>*/}
          {/*      </th>*/}
          {/*      <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">*/}
          {/*        <div className="flex items-end gap-1">*/}
          {/*          <span>Date created</span>*/}
          {/*          <img src={dropdownGrey} alt="" width={16} height={16} />*/}
          {/*        </div>*/}
          {/*      </th>*/}
          {/*    </tr>*/}
          {/*  </thead>*/}
          {/*  <tbody className="text-sm bg-white">*/}
          {/*    {ACCOUNTS_TRANSACTIONS.map((transaction, i) => (*/}
          {/*      <tr className="border-b border-grey60" key={i}>*/}
          {/*        <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">*/}
          {/*          {transaction?.transactionId || '-'}*/}
          {/*        </td>*/}
          {/*        <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">*/}
          {/*          {formatNumberToCurrency(transaction?.amount) || '-'}*/}
          {/*        </td>*/}
          {/*        <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">*/}
          {/*          {transaction?.gateway || '-'}*/}
          {/*        </td>{' '}*/}
          {/*        <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">*/}
          {/*          {moment(transaction?.dateCreated).format(*/}
          {/*            'Do MMM YYYY hh:mm',*/}
          {/*          ) || '-'}*/}
          {/*        </td>*/}
          {/*      </tr>*/}
          {/*    ))}*/}
          {/*  </tbody>*/}
          {/*</TableLayout>*/}

          {/*<div className="flex items-center justify-between flex-wrap gap-4 sm:gap-0 mt-8">*/}
          {/*  <div className="flex items-center text-body text-tiny">*/}
          {/*    <PageLimit*/}
          {/*      onLimitChange={setLimit}*/}
          {/*      total={ACCOUNTS_TRANSACTIONS.length}*/}
          {/*      length={ACCOUNTS_TRANSACTIONS.length}*/}
          {/*      limit={limit}*/}
          {/*    />*/}
          {/*  </div>*/}

          {/*  <div className="mb-8 sm:mb-0">*/}
          {/*    <Pagination*/}
          {/*      total={Math.ceil(ACCOUNTS_TRANSACTIONS.length / limit)}*/}
          {/*      current={+currentPage}*/}
          {/*      onPageChange={activePage => {*/}
          {/*        localStorage.setItem('page', String(activePage));*/}
          {/*        pageClick(activePage);*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </section>
      )}
    </Layout>
  );
}

export default Accounts;
