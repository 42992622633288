import React, {useState} from 'react';
import {
  DashboardLayout,
  FilterButton,
  PageLimit,
  Pagination,
  PrimaryButton,
  SearchInput,
  SecondaryButton,
  TableLayout,
} from '../../../components';
import {projectsMobileNav} from '../../../assets/images/images';
import PaymentInformationModal from './PaymentInformationModal';

function BillingPaymentHistory() {
  const [openFilter, setOpenFilter] = useState(false);
  const [isPaymentInfoModal, setIsPaymentInfoModal] = useState(false);

  const togglePaymentInfoModal = () =>
    setIsPaymentInfoModal(prevState => !prevState);

  return (
    <DashboardLayout
      bg="bg-white80"
      topPadding="pt-8"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={projectsMobileNav} alt="Projects Mobile Navigation" />
          <p className="ml-2 -mb-1">Settings {'>'} Billing History</p>
        </div>
      }
    >
      <p className="text-grey font-medium text-lg mt-10 lg:mt-0">Billing</p>

      <div className="mt-7 bg-white border border-[#EFF2F5] rounded-2xl py-9 px-6 flex flex-col lg:flex-row lg:items-center justify-between">
        <div className="flex flex-col">
          <p className="text-body font-medium text-sm">Current Plan</p>
          <h3 className="text-grey font-semibold text-xxl">Base tier</h3>
        </div>

        <div className="flex items-center gap-2">
          <PrimaryButton
            buttonText="Change Plan"
            className="text-xs !py-2 !px-8 rounded-lg"
          />
          <SecondaryButton
            buttonText="Cancel Subscription"
            className="text-xs !py-2 !px-3 h-fit rounded-lg font-inter text-error !outline-[#d7dde4]"
          />

          <PaymentInformationModal
            show={isPaymentInfoModal}
            onClose={togglePaymentInfoModal}
          />
        </div>
      </div>

      <div className="flex items-start lg:items-center lg:flex-row flex-col gap-5 justify-between p-5 lg:p-0 mt-14">
        <p className="text-grey font-medium text-[18px]">Payment history</p>
        <div className="flex items-center gap-2">
          <SearchInput className="sm:w-[277px] rounded-lg outline-[#eceff3]" />
          <FilterButton
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            className="h-[48px] justify-center w-[130px]"
          />
          <PrimaryButton
            onClick={togglePaymentInfoModal}
            buttonText="Export"
            className="w-[121px]"
          />
        </div>
      </div>

      <div className="mt-9">
        <TableLayout negativeMargins className="mt-6 w-full">
          <thead className="text-small font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                amount
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                payment date
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                subscription type
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                ref number
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                status
              </th>{' '}
            </tr>
          </thead>
          <tbody className="text-sm text-body">
            <tr className="text-left border-b  bg-white cursor-pointer border-grey60">
              <td className="p-4 pl-12 whitespace-nowrap">$120,000</td>
              <td className="p-4 pl-0 whitespace-nowrap">
                4th June 2024, 4:09am
              </td>
              <td className="p-4 pl-0 whitespace-nowrap">Standard Tier</td>
              <td className="p-4 pl-0 whitespace-nowrap">
                TNX53726535626828..2
              </td>
              <td className="p-4 pl-0 whitespace-nowrap">
                <span className="uppercase w-fit rounded-full px-2 py-1 h-5 font-inter flex items-center justify-center text-white bg-success text-xs">
                  successful
                </span>
              </td>
            </tr>
          </tbody>
        </TableLayout>
      </div>

      <div className="flex flex-wrap items-center justify-between gap-4 mt-6 sm:gap-0">
        <div className="flex items-center text-body text-tiny">
          <PageLimit total={100} length={10} limit={100} />
        </div>
        <div className="mb-8 sm:mb-0">
          <Pagination total={5} current={1} />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default BillingPaymentHistory;
