import React, {useState} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import {
  Modal,
  DropdownIndicator,
  customStyles,
  PrimaryButton,
  CreateAppForm,
} from '../../../components';
import * as Actions from '../../../store/actions';
import {plus, times} from '../../../assets/images/images';
import {useAllApps} from '../../../hooks/useApps';

function MessagingReportModal({
  open,
  setOpen,
  selectedApp,
  setSelectedApp,
  onGetReportClick,
}) {
  const [newAppForm, setNewAppForm] = useState(false);
  const {userApps, isLoading} = useAllApps();

  return (
    <Modal
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[528px] rounded-t-none sm:rounded-lg absolute bottom-0 sm:relative h-fit max-h-full overflow-y-auto "
      modalPosition="justify-center sm:justify-end"
      modalTitle="Messaging Logs"
    >
      <form className="mt-6 text-left">
        <label htmlFor="app" className="relative mt-6 mb-2">
          Select app
        </label>
        <Select
          defaultValue={!isLoading && userApps[0]}
          value={selectedApp}
          onChange={app => {
            setSelectedApp(app);
          }}
          options={userApps}
          placeholder="Select app"
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          isSearchable={false}
          menuPlacement="bottom"
          maxMenuHeight={112}
        />
        <button
          className="flex items-center gap-[2px] ml-auto mt-2"
          onClick={() => setNewAppForm(prev => !prev)}
          type="button"
        >
          <img src={newAppForm ? times : plus} alt="" width={12} height={12} />
          <span className="text-xs font-medium text-body">
            {newAppForm ? 'Close' : 'Create a new app'}
          </span>
        </button>
        {newAppForm && (
          <div className="p-6 mt-2 rounded bg-white80 animate-dropdown">
            <h3 className="text-base font-medium text-grey">Create App</h3>
            <CreateAppForm setOpen={() => setNewAppForm(prev => !prev)} />
          </div>
        )}

        <PrimaryButton
          onClick={onGetReportClick}
          buttonText="Get Logs"
          className="w-full mt-4"
          disabled={!selectedApp}
        />
      </form>
    </Modal>
  );
}

export default connect(state => state, Actions)(MessagingReportModal);
