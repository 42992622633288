import React, {useMemo, memo} from 'react';
import {NavLink} from 'react-router-dom';
import {
  developersNav,
  configurationNav,
  profileNav,
  auditLogsNav,
  billingNav,
  settingsNav,
  developersMobileNav,
  configurationMobileNav,
  webhookMobileNav,
  webhookNav,
  billingMobileNav,
  settingsMobileNav,
} from '../../assets/images/images';

import {Constants} from '../../helpers';
import {reconciloNavLinks} from './constants';
import {NavigationItem, SubNavigationDropdown} from './components';

const ReconciloSideNavigations = memo(
  ({media, splitLocation, openDropdown, toggleSubRoute}) => {
    const projectId =
      useMemo(() => sessionStorage.getItem('project_id'), []) || 'add-project';

    return (
      <>
        <div className="flex flex-col">
          <div className="mt-2 flex flex-col gap-2 rounded lg:text-white text-grey">
            <div className="flex flex-col">
              {reconciloNavLinks?.map((link, idx) => (
                <NavigationItem
                  key={link?.label + idx}
                  media={media}
                  title={link?.label}
                  active={splitLocation[1] === ''}
                  to={link?.url.replace(':slug', projectId)}
                  webIcon={link?.imgSrc}
                  mobileIcon={link?.imgSrc}
                />
              ))}

              {/*<NavigationItem*/}
              {/*  media={media}*/}
              {/*  title="Overview"*/}
              {/*  active={splitLocation[1] === ''}*/}
              {/*  to={Constants.PAGES_URL.RECONCILIATION_OVERVIEW.replace(*/}
              {/*    ':slug',*/}
              {/*    projectId,*/}
              {/*  )}*/}
              {/*  webIcon={overviewReconciliationNav}*/}
              {/*  mobileIcon={overviewReconciliationNav}*/}
              {/*/>*/}
            </div>
          </div>
        </div>

        <div className="mt-2 flex flex-col gap-2 rounded lg:text-white text-grey">
          <NavigationItem
            media={media}
            title="Billing"
            active={splitLocation[1] === ''}
            to={Constants.PAGES_URL.RECONCILIATION_BILLING}
            webIcon={billingNav}
            mobileIcon={billingMobileNav}
          />
        </div>

        <div className="mt-2 flex flex-col gap-2 rounded lg:text-white text-grey">
          <SubNavigationDropdown
            media={media}
            toggleSubRoute={() => toggleSubRoute('developers')}
            src={media ? developersNav : developersMobileNav}
            open={openDropdown === 'developers'}
            label="Developers"
          />

          {openDropdown === 'developers' && (
            <div className="flex flex-col">
              <NavigationItem
                media={media}
                title="Configuration"
                active={splitLocation[1] === ''}
                to={Constants.PAGES_URL.DEVELOPERS_CONFIGURATION}
                webIcon={configurationNav}
                mobileIcon={configurationMobileNav}
                inner
              />

              <NavigationItem
                media={media}
                title="Webhooks"
                active={splitLocation[1] === ''}
                to={Constants.PAGES_URL.DEVELOPERS_WEBHOOK}
                webIcon={webhookNav}
                mobileIcon={webhookMobileNav}
                inner
              />
            </div>
          )}
        </div>

        <div className="flex flex-col mt-2">
          <SubNavigationDropdown
            media={media}
            toggleSubRoute={() => toggleSubRoute('settings')}
            src={media ? settingsNav : settingsMobileNav}
            open={openDropdown === 'settings'}
            label="Settings"
          />

          {openDropdown === 'settings' && (
            <>
              <NavLink
                to={Constants.PAGES_URL.SETTINGS_PROFILE}
                className={`nav-item rounded flex lg:hidden items-center gap-2 pl-8 py-2 relative ${splitLocation[1]} === "" ? "active" : ""`}
                aria-current="page"
              >
                <img src={settingsMobileNav} alt="" width={18} height={18} />
                <span className="-mb-1 text-sm text-body">All settings</span>
              </NavLink>
              <div className="flex-col hidden lg:flex">
                <NavigationItem
                  media={media}
                  title="Profile"
                  active={splitLocation[1] === ''}
                  to={Constants.PAGES_URL.SETTINGS_PROFILE}
                  webIcon={profileNav}
                  mobileIcon={settingsMobileNav}
                  inner
                />

                <NavigationItem
                  media={media}
                  title="Audit Logs"
                  active={splitLocation[1] === ''}
                  to={Constants.PAGES_URL.SETTINGS_AUDIT_LOGS}
                  webIcon={auditLogsNav}
                  mobileIcon={auditLogsNav}
                  inner
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  },
);

export {ReconciloSideNavigations};
