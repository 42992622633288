import React, {useCallback, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {
  DashboardLayout,
  EmptyState,
  Filter,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  SearchInput,
  TableLayout,
} from '../../../components';
import {baasMobile, corruptedFile, eye} from '../../../assets/images/images';
import {fetchBAASTransactions} from '../../../requests/queries/baas';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';
import {getStatusTextAndStyles} from '../../../helpers/getStatusTextAndStyles';
import BaasTransactionModal from './BaasTransactionModal';
import {filterOutEmptyValues, generateQueryParams} from '../../../helpers';
import {useBaasExport} from './hooks/useBAASExport';

const initialFilters = {
  start: '',
  end: '',
  flows: '',
};

function BaasTransactions() {
  const navigate = useNavigate();
  const [filters, setFilters] = useState(initialFilters);

  const [openFilter, setOpenFilter] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [transactionDetails, setTransactionDetails] = useState(false);
  const [searchText, setSearchText] = useState('');

  const {userDetails} = useSelector(state => state?.auth);

  const toggleTransactionsModal = useCallback(transaction => {
    setTransactionDetails(prevState => !prevState);
    setTransaction(transaction);
  }, []);
  function shortenText(text, maxLength = 0) {
    if (text.length <= maxLength) return text;
    return `${text.slice(0, maxLength - 5)}......${text.slice(-3)}`;
  }

  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });
  const params = useMemo(
    () => ({
      company_id: userDetails && userDetails?.company?.id,
      ...query,
      search: searchText,
      start_date: filters?.start,
      end_date: filters?.end,
    }),
    [filters?.end, filters?.start, query, searchText, userDetails],
  );
  const {handleExport, exportData} = useBaasExport({
    params,
    apiFunc: fetchBAASTransactions,
  });
  const {data: baas_transactions, isLoading} = useQuery(
    ['baas-transactions', {params}],
    () => fetchBAASTransactions({params}),
    {
      retry: false,
      meta: {preventGlobalError: true},
      enabled: !exportData,
    },
  );

  const transactions = useMemo(
    () => !isLoading && baas_transactions && baas_transactions?.entity,
    [baas_transactions, isLoading],
  );

  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };

  const handleFilter = (selectedOptions, shouldDownload = false) => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    const queryParams = generateQueryParams(filteredOptions);
    const {status} = filteredOptions;
    if (Array.isArray(status)) {
      filteredOptions.status = status.join(',');
    }
    let baseUrl = '';
    if (queryParams) {
      baseUrl += `?${queryParams}`;
    }

    if (shouldDownload) {
      filteredOptions.download = true;
      setFilters({
        ...filters,
        ...filteredOptions,
      });
    } else {
      navigate(baseUrl);
    }

    setFilters(filteredOptions);
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    navigate('');
    setFilters(initialFilters);
    setOpenFilter(true);
  };

  return (
    <DashboardLayout
      bg="bg-white"
      topPadding="pt-8"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={baasMobile} alt="" />
          <p className="ml-2 -mb-1">Transactions</p>
        </div>
      }
    >
      <div className="flex items-start lg:items-center lg:flex-row flex-col gap-5 justify-between p-5 lg:p-0">
        <p className="text-grey font-medium text-[18px]">Transactions</p>

        <div className="flex items-center gap-2">
          <SearchInput
            onChange={value => setSearchText(value)}
            className="sm:w-[277px] rounded-lg outline-[#eceff3]"
          />
          <FilterButton
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            className="h-[48px] justify-center w-[130px]"
          />
          <PrimaryButton
            buttonText="Export"
            className="w-[121px]"
            loading={exportData}
            onClick={handleExport}
          />
        </div>
      </div>

      <BaasTransactionModal
        show={transactionDetails}
        onClose={toggleTransactionsModal}
        transaction={transaction}
      />

      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        filterOptions={[]}
      />

      {isLoading ? (
        <Loader height={35} />
      ) : !isLoading && transactions?.transactions?.length === 0 ? (
        <EmptyState
          noBtn
          className="w-full"
          src={corruptedFile}
          body="Opps No wallet history found this company"
          maxWidth="max-w-[500px]"
        />
      ) : (
        <div className="mt-8">
          <TableLayout negativeMargins className="mt-6 w-full">
            <thead className="text-small font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                  name
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  date created
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  amount
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  channel
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  type
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  ref id
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  status
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap"></th>
              </tr>
            </thead>
            {!isLoading &&
              transactions?.transactions?.map((transaction, idx) => (
                <tbody className="text-sm text-body" key={idx}>
                  <tr
                    className="text-left border-b  bg-white cursor-pointer border-grey60"
                    onClick={() => toggleTransactionsModal(transaction)}
                  >
                    <td className="p-4 pl-12 whitespace-nowrap">
                      {transaction?.recipient_account_name || '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {(transaction?.date_created &&
                        moment(transaction?.date_created)?.format(
                          'DD MMM YYYY h:m',
                        )) ||
                        '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {shortenLargeNumbers(
                        transaction?.transaction_amount || 0,
                        'NGN',
                        {startAt: 'M'},
                      )}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      <span className="uppercase font-inter w-fit rounded-full px-2 py-1 h-5 flex items-center justify-center text-dojahBlue bg-[#EEF3FC] text-xs">
                        {transaction?.source || '-'}
                      </span>
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      <span className="uppercase font-inter w-fit rounded-full px-2 py-1 h-5 flex items-center justify-center text-[#B42318] bg-[#F8EBEA] text-xs">
                        {transaction?.transaction_type === 'transfer'
                          ? 'Debit'
                          : 'Credit' || '-'}
                      </span>
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {shortenText(transaction?.transaction_id || '--', 21) ||
                        '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      <span
                        className={` ${
                          getStatusTextAndStyles(
                            transaction?.transaction_status?.toLowerCase(),
                          )?.styles
                        } uppercase w-fit rounded-full px-2 py-1 h-5 font-inter flex items-center justify-center  text-xs`}
                      >
                        {
                          getStatusTextAndStyles(
                            transaction?.transaction_status?.toLowerCase(),
                          )?.text
                        }
                      </span>
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      <button>
                        <img src={eye} alt="" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              ))}
          </TableLayout>
        </div>
      )}

      {!isLoading && transactions?.transactions?.length > 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-12 sm:gap-0 pl-12 pr-6">
          <PageLimit
            onLimitChange={limit => {
              setQuery(prevState => ({
                ...prevState,
                limit,
                page: 1,
              }));
            }}
            limit={query?.limit}
            total={transactions?.current_page}
            length={transactions?.transactions?.length}
          />

          <Pagination
            total={transactions?.total_pages}
            current={Number(transactions?.current_page)}
            onPageChange={activePage => {
              pageClick(activePage);
              const searchParams = new URLSearchParams(
                document.location.search,
              );
              searchParams.set('page', activePage);
              const newSearchParams = searchParams.toString();
              navigate(`?${newSearchParams}`);
            }}
          />
        </div>
      )}
    </DashboardLayout>
  );
}

export default BaasTransactions;
