import React, {useEffect, useMemo, useState} from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import {getCurrencyList} from 'country-currency-map';
import {
  DropdownIndicator,
  Modal,
  PrimaryButton,
  customStyles,
} from '../../../../components';
import {closeModal} from '../../../../assets/images/images';
import {accountType} from '../Recon_Constants';
import {useParams} from 'react-router-dom';
import {useMutation, useQueryClient} from 'react-query';
import {addAccount} from '../../../../requests/queries/reconciliations';
import {toast} from 'react-toastify';
import {GoBack} from '../components/GoBack';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

const initialAccount = {
  account_type: '',
  country: '',
  currency: '',
  account_number: '',
  account_name: '',
  bank: '',
};
function ManuallyAddAccount({show, onClose, goBack, accountData}) {
  const options = useMemo(
    () =>
      countryList()
        .getData()
        .map(c => ({
          ...c,
          label: (
            <span>
              <span className="mr-2">{getUnicodeFlagIcon(c?.value)}</span>
              {c?.label}
            </span>
          ),
        })),
    [],
  );
  const currencyInfo = useMemo(
    () => getCurrencyList().map(c => ({value: c.abbr, label: c.name})),
    [],
  );

  const [inputs, setInputs] = useState(initialAccount);
  const [selectedCountry, setSelectedCountry] = useState(options[161]);
  const [currency, setCurrency] = useState({});
  const [account_type, setAccountType] = useState(accountType[0]);
  const {slug} = useParams();
  const queryClient = useQueryClient();

  const dataToFind = useMemo(
    () => [
      {
        key: 'currency',
        data: currencyInfo,
        setter: setCurrency,
        condition: info =>
          info?.value?.startsWith(
            accountData?.currency || selectedCountry?.value,
          ),
      },
      {
        key: 'country',
        data: options,
        setter: setSelectedCountry,
        condition: info => info?.value?.startsWith(accountData?.country),
      },
      {
        key: 'type',
        data: accountType,
        setter: setAccountType,
        condition: info => info?.value?.startsWith(accountData?.account_type),
      },
    ],
    [
      accountData?.account_type,
      accountData?.country,
      accountData?.currency,
      currencyInfo,
      options,
      selectedCountry?.value,
    ],
  );

  const foundItems = useMemo(
    () =>
      dataToFind.reduce((acc, {key, data, condition}) => {
        acc[key] = data.find(condition);
        return acc;
      }, {}),
    [dataToFind],
  );

  useEffect(() => {
    dataToFind.forEach(({key, setter}) => {
      if (foundItems[key]) {
        setter(foundItems[key]);
      }
    });
    setInputs({...accountData});
  }, [accountData, dataToFind, foundItems]);

  const {mutate, isLoading} = useMutation('add-account', addAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      toast.success('Account added successfully');
      setInputs(initialAccount);
      onClose(false);
    },
  });

  const handleChange = e => {
    const {name, value} = e.target;
    setInputs(prev => ({...prev, [name]: value}));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const data = {
        ...inputs,
        project_id: slug,
        account_type: account_type?.value,
        country: selectedCountry?.value,
        currency: currency?.value,
      };

      const allFilled = Object.values(data || {}).every(e => e);
      if (allFilled) {
        delete data.routing_number;

        await mutate(data);
      } else {
        toast.error('Please fill out all the fields!');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center mb-5 justify-between w-full">
          <GoBack
            goBack={() => {
              goBack();
              onClose(false);
            }}
          />

          <button
            onClick={() => {
              onClose(false);
            }}
            className="hidden sm:block"
          >
            <img src={closeModal} alt="" />
          </button>
        </div>

        <h3 className="font-medium  text-left mt-6 text-grey">
          Manually add account
        </h3>

        <form className="mt-6" onSubmit={handleSubmit}>
          <label htmlFor="country" className=" mt-6 text-body font-medium">
            <span className="relative text-sm text-body  font-medium required">
              Country
            </span>
            <Select
              defaultValue={selectedCountry}
              placeholder="Select your country"
              classNamePrefix="react-select"
              components={{DropdownIndicator}}
              styles={customStyles}
              className="text-start mt-2 add_project_select w-full"
              menuPlacement="auto"
              options={options}
              onChange={data => {
                setSelectedCountry(data);
              }}
            />
          </label>

          <label
            htmlFor="currency"
            className="mt-6 text-body font-medium w-full"
          >
            <span className="relative text-sm text-body font-medium required">
              Currency
            </span>
            <Select
              defaultValue={currency}
              value={currency}
              placeholder="Select account currency"
              classNamePrefix="react-select"
              components={{DropdownIndicator}}
              styles={customStyles}
              className="text-start mt-2 add_project_select w-full"
              menuPlacement="auto"
              options={currencyInfo}
              onChange={setCurrency}
            />
          </label>
          <label
            htmlFor="account_type"
            className=" mt-6 text-body font-medium "
          >
            <span className="relative text-sm text-body font-medium required">
              Account Type
            </span>
            <Select
              defaultValue={account_type}
              placeholder="Select account type"
              classNamePrefix="react-select"
              components={{DropdownIndicator}}
              styles={customStyles}
              className="text-start mt-2 add_project_select w-full"
              menuPlacement="auto"
              options={accountType}
              onChange={setAccountType}
            />
          </label>

          <label htmlFor="bank">
            <span className="relative text-sm text-body mt-6 font-medium required">
              Bank name
            </span>
            <input
              type="text"
              name="bank"
              className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
              id="bank"
              placeholder="Input bank name"
              onChange={handleChange}
              value={inputs?.bank}
            />
          </label>

          <label htmlFor="account_name" className="mt-6">
            <span className="relative text-sm text-body font-medium required">
              Account name
            </span>
            <input
              type="text"
              name="account_name"
              className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
              id="account_name"
              placeholder="Input account name"
              onChange={handleChange}
              value={inputs?.account_name}
            />
          </label>

          <label htmlFor="account_number" className="mt-6">
            <span className="relative required text-sm text-body font-medium">
              Account number
            </span>
            <input
              type="text"
              name="account_number"
              className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
              id="account_number"
              placeholder="Input account number"
              onChange={handleChange}
              value={inputs?.account_number}
            />
          </label>
          <label htmlFor="account_number" className="mt-6">
            <span className="text-sm text-body font-medium">
              Routing Number (optional)
            </span>
            <input
              type="text"
              name="routing_number"
              className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
              id="routing_number"
              placeholder="Input a Routing Number"
              onChange={handleChange}
              value={inputs?.routing_number}
            />
          </label>

          <div className="flex flex-col gap-6 items-center mt-6">
            <PrimaryButton
              buttonText="Submit"
              className="w-full rounded-lg"
              type="submit"
              loading={isLoading}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ManuallyAddAccount;
