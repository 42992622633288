import {isObjectEmpty} from '../../helpers/isObjectEmpty';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';
import {pushObjectsIntoArray} from '../../helpers/pushOnlyFourItemsArrayOfArrays';
import {
  generateFooter,
  horizontalLine,
  // watermark,
  styles,
  generatePDFStructure,
} from '../../helpers/pdfUtils';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const generateHeader = ({lookupType, lookupImage, companyName}) => [
  {text: lookupType || 'Easy Lookup', style: 'type'},
  {
    text: `Requested by ${companyName}`,
    style: {alignment: 'center', fontSize: 11, color: '#4A4A68'},
    margin: [0, 0, 0, 30],
  },
  {
    text: 'Profile'.toUpperCase(),
    margin: [0, 10, 0, 7],
    style: {bold: true, fontSize: 10},
  },
  horizontalLine(),
  ...[
    lookupImage
      ? {
          image: lookupImage,
          width: 150,
          alignment: 'left',
          margin: [0, 0, 0, 20],
        }
      : {},
  ],
];

const generatePDFStructureForEasyLookup = ({
  lookupData,
  logo,
  lookupType,
  lookupImage,
  companyName,
}) => {
  const generateDocInfo = lookupData
    .filter(doc => !isObjectEmpty(doc))
    .map(doc => doc);

  const headerRow = generateHeader({
    logo,
    lookupType,
    lookupImage,
    companyName,
  });
  const content = [...headerRow];
  const finalContentStructure = generatePDFStructure(generateDocInfo);
  const dataColumns = pushObjectsIntoArray(finalContentStructure);
  content.push(dataColumns);
  return content;
};

const generatePDF = (contents, footerData, lookupType) => {
  const documentDefinition = {
    // watermark,
    content: [...contents],
    styles,
    footer: generateFooter(footerData),
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  pdfDocGenerator.download(`${lookupType}.pdf`.toLowerCase());
};

export {generatePDFStructureForEasyLookup, generatePDF};
